import BasicLayout from "components/Layouts/BasicLayout";
import MediaGallery from "components/MediaGallery";

const MediaGalleryPage = () => {

  return (
    <BasicLayout>
      <MediaGallery />
    </BasicLayout>
  );
};

export default MediaGalleryPage;