import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import authorsService from "../../services/authors";
import { IAuthor } from "types/authors";
interface AuthorSelectProps {
  value?: IAuthor | string | number;
  onChange: (author: IAuthor) => void;
}

const AuthorSelect: React.FC<AuthorSelectProps> = ({ onChange, value }) => {
  const [authors, setAuthors] = useState<IAuthor[]>([]);

  useEffect(() => {
    const fetchAuthors = async () => {
      const authors = await authorsService.fetchAll({ pageSize: 1000 });
      setAuthors(authors.data);
    };
    fetchAuthors();
  }, []);

  const handleSelectChange = (value: any) => {
    const selectedAuthor = authors.find((author) => author.id === value);
    if (selectedAuthor) {
      onChange(selectedAuthor);
    }
  };

  const getCurrentValue = () => {
    if (!value) return null;
    if (typeof value === 'object') return value.id;
    return value;
  };

  return (
    <Autocomplete
      value={getCurrentValue()}
      onChange={(e, value) => handleSelectChange(value)}
      options={authors?.map((author: IAuthor) => author.id)}
      getOptionLabel={(option) =>
        authors.find((author: IAuthor) => author.id === option)?.name || String(option)
      }
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default AuthorSelect;
