import { useState, useRef } from "react";
import stripeService from "services/stripe";
import html2pdf from 'html2pdf.js';
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Divider,
} from '@mui/material';
import logoBlue from "../../assets/images/logos/logo_blue.png";

interface InvoiceDialogProps {
  id: string;
  open: boolean;
  onClose: () => void;
  organizationName: string;
}

interface FormattedInvoice {
  number: string;
  period_end: string;
  period_start: string;
  status: string;
  amount_paid: string;
  currency: string;
  payment_date: string;
  parsedLine?: {
    quantity: number;
    description: string;
    unitPrice: number;
    totalAmount: string;
    currency: string;
    period: string;
  };
}

const InvoiceDialog: React.FC<InvoiceDialogProps> = ({ id, open, onClose, organizationName }) => {

  const invoiceRef = useRef<HTMLDivElement>(null);
  const [invoice, setInvoice] = useState<FormattedInvoice | null>(null);

  const fetchInvoice = async () => {
    try {
      const _invoice = await stripeService.fetchInvoice(id);
      const formattedInvoice = {
        ..._invoice,
        payment_date: new Date(_invoice.payment_date).toLocaleDateString(),
        period_end: new Date(_invoice.period_end).toLocaleDateString(),
        period_start: new Date(_invoice.period_start).toLocaleDateString(),
        status: _invoice.status,
        amount_paid: (_invoice.amount_paid / 100).toFixed(2),
        currency: _invoice.currency.toUpperCase(),
      };

      const line = formattedInvoice?.lines[0];
      const parsedLine = parseLineDescription(line);

      if (parsedLine) {
        setInvoice({
          ...formattedInvoice,
          parsedLine,
        });
      } else {
        setInvoice(formattedInvoice);
      }
    } catch (error) {
      console.error("Error fetching invoice:", error);
    }
  };

  const parseLineDescription = (line: any) => {
    // Adjust regex to match "×" instead of "x"
    const match = line.description.match(/(\d+) × (.+?) \(at \$(.+?) \/ (.+?)\)/);
    if (!match) {
      console.error("Description format does not match expected pattern");
      return null;
    }

    console.log("Match:", match);

    const [, quantity, description, unitPrice, period] = match;

    return {
      quantity: parseInt(quantity),
      description: description.trim(),
      unitPrice: parseFloat(unitPrice),
      totalAmount: (line.amount / 100).toFixed(2), // Convert cents to dollars
      currency: line.currency,
      period,
    };
  };

  if (open && !invoice) {
    fetchInvoice();
  }

  const onDownloadClick = async () => {
    const element = invoiceRef.current;
    if (element) {
      const options = {
        margin: 10,
        filename: 'invoice.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      html2pdf().from(element).set(options).save();
    }
  };

  console.log("Parsed Line:", invoice?.parsedLine);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      {invoice ? (
        <>
          <DialogTitle sx={{ paddingLeft: 5, paddingTop: 5 }}>
            Invoice
          </DialogTitle>
          <DialogContent sx={{ padding: 5 }}>
            <DialogContentText component="div" ref={invoiceRef} sx={{ mb: 3 }}>
              {/* Header Section */}
              <Box display="flex" justifyContent="space-between" mb={3}>
                <Box>
                  <Typography variant="subtitle1" style={{ color: 'black' }}>
                    <strong>Invoice Number:</strong> {invoice?.number}
                  </Typography>
                  <Typography variant="subtitle1" style={{ color: 'black' }}>
                    <strong>Date of Issue:</strong> {invoice?.period_end}
                  </Typography>
                </Box>
                <Box>
                  <img src={logoBlue} alt="logo" style={{ width: 250 }} />
                </Box>
              </Box>

              {/* Bill To Section */}
              <Box mb={3} display="flex" flexDirection="row" alignItems="center" gap={8}>
                <Box>
                  <Typography variant="subtitle1" style={{ color: 'black' }}>
                    <strong>CityView CMS</strong>
                  </Typography>
                  <Typography style={{ color: 'black', fontWeight: 'lighter' }}>
                    United States
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="subtitle1" style={{ color: 'black' }}>
                    <strong>Bill to</strong>
                  </Typography>
                  <Typography style={{ color: 'black', fontWeight: 'lighter' }}>{organizationName}</Typography>
                </Box>
              </Box>

              {/* Amount Section */}
              <Typography variant="h5" fontWeight="bold" mb={2} style={{ color: 'black' }}>
                ${invoice?.amount_paid} {invoice?.currency} paid on {invoice?.payment_date}
              </Typography>

              {/* Description Table */}
              <Typography mb={2}>Thank you for your business!</Typography>

              <Box>
                <Box>
                  {/* Description Header */}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    px={2}
                    py={1}
                    borderBottom="1px solid #e0e0e0"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1" flex={2} style={{ color: 'black' }}>
                      Description
                    </Typography>
                    <Typography variant="subtitle1" flex={1} textAlign="center" style={{ color: 'black' }}>
                      Qty
                    </Typography>
                    <Typography variant="subtitle1" flex={1} textAlign="right" style={{ color: 'black' }}>
                      Unit Price
                    </Typography>
                    <Typography variant="subtitle1" flex={1} textAlign="right" style={{ color: 'black' }}>
                      Amount
                    </Typography>
                  </Box>

                  {/* Description Row */}
                  <Box
                    component="div"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    px={2}
                    py={1}
                    borderBottom="1px solid #e0e0e0"
                  >
                    <Box flex={2}>
                      <Typography variant="body2">{invoice.parsedLine?.description}</Typography>
                      <Typography variant="body2">
                        {invoice.period_start} - {invoice.period_end}
                      </Typography>
                    </Box>
                    <Typography flex={1} textAlign="center" variant="body2">
                      {invoice.parsedLine?.quantity}
                    </Typography>
                    <Typography flex={1} textAlign="right" variant="body2">
                      ${invoice.parsedLine?.unitPrice.toFixed(2)}
                    </Typography>
                    <Typography flex={1} textAlign="right" variant="body2">
                      ${invoice.parsedLine?.totalAmount}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Summary Section */}
              <Box mt={3} display="flex" justifyContent="flex-end">
                <Box width="300px">
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Subtotal</TableCell>
                        <TableCell align="right">${invoice?.amount_paid}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Total</TableCell>
                        <TableCell align="right">${invoice?.amount_paid}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </DialogContentText>

            {/* Action Buttons */}
            <Box display="flex" justifyContent="flex-end" gap={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={onClose}
              >
                Close
              </Button>
              <Button
                variant="contained"
                onClick={onDownloadClick}
              >
                Download
              </Button>
            </Box>
          </DialogContent>
        </>
      ) : (
        <DialogContent>
          <Typography>Loading...</Typography>
        </DialogContent>
      )}
    </Dialog >
  );
};

export default InvoiceDialog;