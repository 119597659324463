import { IOrganization } from "../types/organizations.d";

class TokenHandler {
  constructor() {
    this.getToken = this.getToken.bind(this)
    this.setToken = this.setToken.bind(this)
    this.getCurrentOrganization = this.getCurrentOrganization.bind(this)
    this.setCurrentOrganization = this.setCurrentOrganization.bind(this)
    this.removeToken = this.removeToken.bind(this)
  }
  getToken() {
    return localStorage.getItem('token')
  }
  setToken(token: string) {
    localStorage.setItem('token', token)
  }
  removeToken() {
    localStorage.removeItem('token')
  }

  getCurrentOrganization() {
    return localStorage.getItem('currentOrganization')
  }

  setCurrentOrganization(organization: IOrganization) {
    localStorage.setItem('currentOrganization', JSON.stringify(organization))
  }

  getHeaders() {
    const token = this.getToken();
    const currentOrganizationItem = localStorage.getItem('currentOrganization');
    let currentOrganization: IOrganization | null = null;

    if (currentOrganizationItem && currentOrganizationItem !== 'undefined') {
      try {
        currentOrganization = JSON.parse(currentOrganizationItem);
      } catch (e) {
        console.warn('Failed to parse organization data:', e);
      }
    }

    return {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      ...(currentOrganization?.id ? { 'X-Organization': currentOrganization.id } : {})
    };
  }
}

const theTokenHandler = new TokenHandler();
export default theTokenHandler;
