import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import formsService from '../../services/forms';
import { IForm, IFormField } from 'types/forms.d';
import FormField from '../../components/FormField';
import FormPreview from '../../components/FormPreview';
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Typography,
  TextField,
  Snackbar,
  Alert,
  IconButton,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { ChevronLeft } from "@mui/icons-material";
import BasicLayout from "components/Layouts/BasicLayout";
import LoadingOverlay from "components/LoadingOverlay";
import EventSelect from '../../components/EventSelect';
import NewsletterSelect from 'components/NewsletterSelect';
import CommunityMultiSelect from 'components/CommunityMultiSelect';
import InvitationSelect from 'components/InvitationSelect';
import AITextField from "components/AITextField";

const FormEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [form, setForm] = useState<IForm | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState<any>({ open: false, message: "", severity: "success" });

  const isNewForm = id === 'create';

  const fetchForm = useCallback(async () => {
    if (isNewForm) {
      setForm({
        id: '',
        name: '',
        description: '',
        submitAction: 'member',
        fields: []
      });
      setLoading(false);
    } else {
      try {
        const fetchedForm = await formsService.fetch(id!);
        setForm(fetchedForm);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch form. Please try again.');
        setLoading(false);
      }
    }
  }, [id, isNewForm]);

  useEffect(() => {
    fetchForm();
  }, [fetchForm]);



  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setForm((prevForm) => prevForm ? { ...prevForm, [name]: value } : null);
  };

  const handleSelect = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setForm((prevForm) => prevForm ? { ...prevForm, [name]: value } : null);
  };

  const handleAddField = () => {
    const newField: IFormField = {
      name: '',
      label: '',
      type: "text",
      required: false,
    };
    setForm((prevForm) => prevForm ? { ...prevForm, fields: [...prevForm.fields, newField] } : null);
  };

  const handleFieldChange = (index: number, updatedField: IFormField) => {
    setForm((prevForm) => {
      if (!prevForm) return null;
      const newFields = [...prevForm.fields];
      newFields[index] = updatedField;
      return { ...prevForm, fields: newFields };
    });
  };

  const handleFieldDelete = (index: number) => {
    setForm((prevForm) => {
      if (!prevForm) return null;
      const newFields = prevForm.fields.filter((_, i) => i !== index);
      return { ...prevForm, fields: newFields };
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!form || !form.name || form.fields.length === 0) return;
    try {
      setLoading(true);
      if (isNewForm) {
        const createdForm = await formsService.create(form);
        setSnackbar({ open: true, message: 'Form created successfully', severity: 'success' });
        navigate(`/forms/${createdForm.id}`); // Redirect to the edit page of the newly created form
      } else {
        await formsService.update(id!, form);
        setSnackbar({ open: true, message: 'Form updated successfully', severity: 'success' });
        navigate('/forms');
      }
    } catch (err) {
      setError(`Failed to ${isNewForm ? 'create' : 'update'} form. Please try again.`);
      setSnackbar({ open: true, message: `Failed to ${isNewForm ? 'create' : 'update'} form`, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const renderAdditionalFields = () => {
    switch (form.submitAction) {
      case 'rsvp':
        return (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel htmlFor="event-select">Select Event</FormLabel>
              <EventSelect
                value={form.event || ''}
                onChange={(eventId) => setForm((prevForm) => prevForm ? { ...prevForm, eventId } : null)}
              />
            </FormControl>
          </Grid>
        );
      case 'newsletter':
        return (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel htmlFor="newsletter-select">Select Newsletter</FormLabel>
              <NewsletterSelect
                value={form.newsletter || ''}
                onChange={(newsletterId) => setForm((prevForm) => prevForm ? { ...prevForm, newsletterId } : null)}
              />
            </FormControl>
          </Grid>
        );
      case 'member':
        return (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel htmlFor="community-multi-select">Select Communities</FormLabel>
              <CommunityMultiSelect
                selectedCommunities={form.communities || []}
                setSelectedCommunities={(communities) => setForm((prevForm) => prevForm ? { ...prevForm, communities } : null)}
              />
            </FormControl>
          </Grid>
        );
      case 'invitation':
        return (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel htmlFor="invitation-select">Select Invitation</FormLabel>
              <InvitationSelect
                value={form.invitation || ''}
                onChange={(invitationId) => setForm((prevForm) => prevForm ? { ...prevForm, invitationId } : null)}
              />
            </FormControl>
          </Grid>
        );
      default:
        return null;
    }
  };

  if (loading) return <LoadingOverlay loading={loading} />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!form) return <Alert severity="warning">Form not found.</Alert>;

  return (
    <BasicLayout>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} display="flex" alignItems="center">
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">{isNewForm ? 'Create Form' : 'Edit Form'}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid container item direction="row" xs={12} spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel required htmlFor="form-name">Form Name</FormLabel>
                  <TextField
                    id="form-name"
                    name="name"
                    value={form.name}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <AITextField
                    id="form-description"
                    name="description"
                    label="Description"
                    value={form.description}
                    onChange={(val: any) => setForm({ ...form, description: val })}
                    multiline
                    rows={3}
                    prompt="From the following details, create a description for an form. The description should be descriptive and engaging and be at least 3 sentences long."
                    promptMode="modal"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="form-submit-action">Submit Action</FormLabel>
                  <Select
                    id="form-submit-action"
                    name="submitAction"
                    value={form.submitAction}
                    onChange={handleSelect}
                  >
                    <MenuItem value="member">Add Member</MenuItem>
                    <MenuItem value="rsvp">Add to RSVP List</MenuItem>
                    <MenuItem value="invitation">Confirm Invitation</MenuItem>
                    <MenuItem value="newsletter">Join Newsletter</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {renderAdditionalFields()}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" align="center">Form Preview</Typography>
          <FormPreview form={form} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} display="flex" alignItems="center">
              <Typography variant="h5">Form Fields</Typography>
              <IconButton
                color="secondary"
                onClick={handleAddField}
                aria-label="Add Field"
              >
                <Add />
              </IconButton>
            </Grid>
            {form.fields.map((field, index) => (
              <Grid item xs={12} key={index}>
                <FormField
                  field={field}
                  onChange={(updatedField) => handleFieldChange(index, updatedField)}
                  onDelete={() => handleFieldDelete(index)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!form || !form.name || form.fields.length === 0}
          >
            {isNewForm ? 'Create Form' : 'Update Form'}
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.open}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </BasicLayout>
  );
};

export default FormEdit;
