import React, { useState } from 'react';
import { Box } from '@mui/material';
import FileGallery from 'components/FileGallery';
import { useNews } from './NewsContext';
import { IFile } from 'types/files.d';

const MediaTab: React.FC = () => {
  const { news, setNews } = useNews();

  const handleUpdateMedia = (files: IFile[]) => {
    // Assuming article.media is an array of IFile
    setNews({
      ...news,
      files: files
    });
  };

  const handleDeleteMedia = (file: IFile) => {
    // Assuming article.media is an array of IFile
    setNews({
      ...news,
      files: news.files?.filter(f => f.id !== file.id) || []
    });
  };

  const handleBulkDeleteMedia = (files: IFile[]) => {
    setNews({
      ...news,
      files: news.files?.filter(f => !files.includes(f)) || []
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <FileGallery
        files={news?.files || []}
        updateFiles={handleUpdateMedia}
        onDelete={handleDeleteMedia}
        onBulkDelete={handleBulkDeleteMedia}
      />
    </Box>
  );
};

export default MediaTab;
