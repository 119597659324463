import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import TicketForm from 'components/TicketForm/TicketForm';
import { ITicket } from 'types/tickets';

interface TicketFormModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (ticket: ITicket) => void;
  initialTicket?: Partial<ITicket>;
}

const TicketFormModal: React.FC<TicketFormModalProps> = ({
  open,
  onClose,
  onSave,
  initialTicket = {}
}) => {
  const [ticket, setTicket] = React.useState<Partial<ITicket>>(initialTicket);

  const handleSave = () => {
    onSave(ticket as ITicket);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {initialTicket?.id ? 'Edit Ticket' : 'Create Ticket'}
      </DialogTitle>
      <DialogContent>
        <TicketForm
          ticket={ticket}
          setTicket={setTicket}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TicketFormModal; 