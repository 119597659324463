import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import PaginatedDataGrid from "components/PaginatedDataGrid";
import newsService from "services/news";
import { IFilterOptions } from "types/paginatedDataGrid";
import BasicLayout from "components/Layouts/BasicLayout";
import { capitalizeFirstLetter } from "utils/commonFunctions";

const NewsList: React.FC = () => {
  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "",
      width: 70,
      renderCell: (params: any) =>
        params.value ? (
          <img src={params?.value} alt="News URL" style={{ width: "50px" }} />
        ) : (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{ fontSize: "50px !important", fontWeight: 100 }}
          >
            image
          </Icon>
        ),
      sortable: false,
    },
    { field: "title", headerName: "Title", flex: 1, minWidth: 200 },
    {
      field: "author",
      headerName: "Author",
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        return <>{params?.value?.name}</>;
      },
    },
    {
      field: "createdDate",
      headerName: "Date",
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => <>{new Date(params?.value).toLocaleString()}</>,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => <>{capitalizeFirstLetter(params?.value)}</>,
    },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">News</Typography>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/news/create">
            <Icon>add</Icon>
            Add News
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <PaginatedDataGrid
              collectionName="news"
              defaultSortField="createdDate"
              defaultSortDirection="desc"
              columns={columns}
              service={newsService}
              filterOptions={[
                {
                  label: "Title",
                  name: "title",
                  type: "text",
                  operator: "search",
                },
                {
                  label: "Date",
                  name: "dateRange",
                  type: "date",
                },
                {
                  label: "Status",
                  name: "status",
                  type: "statusCheckboxes",
                  operator: "in",
                },
              ]}
            />
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default NewsList;
