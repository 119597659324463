import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import { ChevronLeft, ContentCopy, Download } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";
import qrcodesService from "services/qrcodes";
import { IQRCode } from "types/qrcodes";
import QRCodeComponent from "react-qr-code";
import { toPng } from 'html-to-image';
import EventSelect from 'components/EventSelect';
import { IEvent } from 'types/events.d';
import { QRCodeType } from 'types/qrcodes.d';

const QRCode: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [qrcode, setQRCode] = useState<IQRCode | undefined>(undefined);
  const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();

  const qrCodeRef = useRef<HTMLDivElement>(null);

  const copyToClipboard = async () => {
    if (qrCodeRef.current) {
      try {
        const dataUrl = await toPng(qrCodeRef.current);
        const blob = await fetch(dataUrl).then(res => res.blob());
        await navigator.clipboard.write([
          new ClipboardItem({ 'image/png': blob })
        ]);
        // You might want to show a success message here
      } catch (err) {
        console.error('Failed to copy QR code:', err);
        // You might want to show an error message here
      }
    }
  };

  const downloadQRCode = async () => {
    if (qrCodeRef.current) {
      try {
        const dataUrl = await toPng(qrCodeRef.current);
        const link = document.createElement('a');
        link.download = `${qrcode?.name || 'qrcode'}.png`;
        link.href = dataUrl;
        link.click();
      } catch (err) {
        console.error('Failed to download QR code:', err);
        // You might want to show an error message here
      }
    }
  };

  useEffect(() => {
    const fetchQRCode = async () => {
      try {
        const fetchedQRCode = await qrcodesService.fetch(id);
        setQRCode(fetchedQRCode);
      } catch (e) {
        console.error("Error fetching QR code:", e);
      }
    };
    if (id && id !== "create") {
      fetchQRCode();
    }
  }, [id]);

  useEffect(() => {
    if (selectedEvent) {
      const newUrl = `${qrcode?.url || ''}?eventId=${selectedEvent.id}`;
      setQRCode((prev) => ({ ...prev, url: newUrl }));
    }
  }, [selectedEvent]);

  const handleEventChange = (event: IEvent | null) => {
    setSelectedEvent(event);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const newQRCode: IQRCode = {
        ...qrcode,
        updatedBy: user?.uid,
        updatedAt: new Date().toISOString(),
        type: QRCodeType.USER,  // Set the type to USER
      };

      if (!newQRCode.name || !newQRCode.code || !newQRCode.url) {
        throw new Error("Please fill in all required fields");
      }

      if (id === "create") {
        newQRCode.createdBy = user?.uid;
        newQRCode.createdAt = new Date().toISOString();
        await qrcodesService.create(newQRCode);
      } else {
        await qrcodesService.update(newQRCode.id, newQRCode);
      }
      navigate("/qrcodes");
    } catch (error: any) {
      console.error("Error saving QR code:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await qrcodesService.remove(id);
      navigate("/qrcodes");
    } catch (error) {
      console.error("Error deleting QR code:", error);
    }
  };
  console.log(qrcode);
  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">QR Code</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container direction={"column"} spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="name">Name</FormLabel>
                <TextField
                  id="name"
                  required={true}
                  value={qrcode?.name || ""}
                  onChange={(e) => setQRCode((prev) => ({ ...prev, name: e.target.value }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="label">Label</FormLabel>
                <TextField
                  id="label"
                  value={qrcode?.label || ""}
                  onChange={(e) => setQRCode((prev) => ({ ...prev, label: e.target.value }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="code">Code</FormLabel>
                <TextField
                  id="code"
                  required={true}
                  value={qrcode?.code || ""}
                  onChange={(e) => setQRCode((prev) => ({ ...prev, code: e.target.value }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="url">URL</FormLabel>
                <TextField
                  id="url"
                  required={true}
                  value={qrcode?.url || ""}
                  onChange={(e) => setQRCode((prev) => ({ ...prev, url: e.target.value }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="event">Event</FormLabel>
                <EventSelect
                  value={selectedEvent}
                  onChange={handleEventChange}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {qrcode && qrcode.url && <div ref={qrCodeRef}>
            <QRCodeComponent value={qrcode?.url || ""} />
          </div>}
          {qrcode && qrcode.url && <Grid container spacing={2} style={{ marginTop: '16px' }}>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<ContentCopy />}
                onClick={copyToClipboard}
              >
                Copy to Clipboard
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<Download />}
                onClick={downloadQRCode}
              >
                Download as PNG
              </Button>
            </Grid>
          </Grid>}
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2}>
            {id && id !== "create" && (
              <Grid item xs={12}>
                <Typography variant="h4" color="error">
                  Danger Zone
                </Typography>
                <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <LoadingOverlay loading={loading} />
      </Grid>
    </BasicLayout>
  );
};

export default QRCode;