import { API_HOST } from "../config";
import axios from "axios";
import TokenHandler from "./base";

const service = "auth";

async function me() {
  const response = await axios.get(`${API_HOST}/${service}/me`, {
    headers: TokenHandler.getHeaders(),
  });
  if (response.data.user.currentOrganization) {
    TokenHandler.setCurrentOrganization(response.data.user.currentOrganization);
  }
  return response.data;
}

async function register(props: any) {
  const response = await axios.post(`${API_HOST}/${service}/register`, props);
  TokenHandler.setToken(response.data.token);
  return response.data;
}

async function login(email: string, password: string) {
  const formData = new FormData();
  formData.append("email", email);
  formData.append("password", password);

  const response = await axios.post(
    `${API_HOST}/${service}/login`,
    {
      email,
      password,
    },
    {
      headers: {
        ContentType: "application/json",
      },
    }
  );
  TokenHandler.setToken(response.data.token);
  TokenHandler.setCurrentOrganization(response.data.user.currentOrganization);
  return response.data;
}

async function logout() {
  return TokenHandler.removeToken();
}

async function acceptInvitation(data: { invitationCode: string, password: string, confirmPassword: string }) {
  const response = await axios.post(`${API_HOST}/${service}/acceptInvitation`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function forgotPassword(email: string) {
  const response = await axios.post(`${API_HOST}/${service}/forgot-password`, {
    email,
  });
  return response.data;
}

async function resetPassword(token: string, password: string) {
  const response = await axios.post(`${API_HOST}/${service}/reset-password`, {
    token,
    password,
  });
  return response.data;
}

async function changePassword(newPassword: string) {
  const response = await axios.put(
    `${API_HOST}/auth/changePassword`,
    { newPassword },
    {
      headers: TokenHandler.getHeaders(),
    }
  );

  if (!response.status) {
    throw new Error(response.statusText);
  }
  return response;
}

const authService = {
  me,
  register,
  login,
  logout,
  acceptInvitation,
  forgotPassword,
  changePassword,
  resetPassword
};

export default authService;
