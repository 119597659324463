import React, { useState } from "react";
import {
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { useLocation } from "./LocationContext";
import Image from "components/Images/Image";
import TagMultiSelect from "components/TagMultiSelect/TagMultiSelect";
import { IFile } from "types/files.d";

import LoadingOverlay from "components/LoadingOverlay";
import { Icon } from "@mui/material";
import foldersService from "services/folders";
import filesService from "services/files";
import googleService from "services/google";
import { getAddressComponents } from "utils/commonFunctions";
import AITextField from "components/AITextField";
const getOrCreateFolder = async (folderName: string): Promise<string> => {
  const existingFolders = await foldersService.fetchAll();
  const existingFolder = existingFolders.data.find((folder: any) => folder.name === folderName);

  if (existingFolder) {
    return existingFolder.id;
  }

  const createdFolder = await foldersService.create({ name: folderName });
  return createdFolder.id;
};

const LocationDetails: React.FC = () => {

  const { location, setLocation, loading: contextLoading } = useLocation();
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = async () => {
    if (searchText.trim() !== "") {
      try {
        setSearchLoading(true);
        const places = await googleService.searchPlaces(searchText);
        const candidate = places[0];
        console.log('candidate', candidate);
        let thumbnail: IFile;
        if (candidate.defaultImage) {
          try {
            const folderId = await getOrCreateFolder("Locations Media");
            thumbnail = await filesService.createFromUrl({
              url: candidate.defaultImage,
              folderId,
            });
          } catch (error) {
            console.error("Error uploading thumbnail:", error);
          }
        }
        const { address1, address2, city, state, zip } = getAddressComponents(candidate.addressComponents)
        const googleLocation = {
          placeId: candidate.id,
          name: candidate.displayName.text,
          formattedAddress: candidate.formattedAddress,
          address1,
          address2,
          city,
          state,
          zip,
          phone: candidate.nationalPhoneNumber,
          defaultImage: thumbnail,
          link: candidate.websiteURI,
          types: candidate.types,
          facebook: candidate.facebookUrl,
          instagram: candidate.instagramUrl,
          website: candidate.websiteUri,
        };
        setLocation({
          ...location,
          ...googleLocation,
        });
      } catch (error) {
        console.error(error);
      } finally {
        setSearchLoading(false);
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4}>
        <Box sx={{ height: '400px', width: '100%' }}>
          <Image
            src={location?.defaultImage?.url}
            alt={`Location image for ${location?.name}`}
            onAccept={(image: IFile) => {
              setLocation({ ...location, defaultImage: image });
            }}
            bucketPath="cityview-cms/locations"
            folderPath="Locations Media"
            context={location?.description}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={8}>
        <Grid container spacing={2}>
          {!location?._id && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>Search Google for Location</FormLabel>
                <TextField
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <Button
                  disabled={searchLoading}
                  variant="contained"
                  color="primary"
                  onClick={handleSearch}
                  sx={{ mt: 1 }}
                >
                  Search
                </Button>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Name *</FormLabel>
              <TextField
                required
                value={location?.name || ""}
                onChange={(e) => setLocation({ ...location, name: e.target.value })}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <AITextField
                label="Description"
                prompt="From the following details, create a description for an location. The description should be descriptive and engaging and be at least 3 sentences long."
                promptMode="modal"
                value={location?.description || ""}
                onChange={(val: any) => setLocation({ ...location, description: val })}
                multiline
                rows={4}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Website Link</FormLabel>
              <TextField
                value={location?.link || ""}
                onChange={(e) => setLocation({ ...location, link: e.target.value })}
                InputProps={{
                  endAdornment: location?.link && (
                    <Icon onClick={() => window.open(location.link, '_blank')}>
                      open_in_new
                    </Icon>
                  ),
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Facebook</FormLabel>
              <TextField
                value={location?.facebook || ""}
                onChange={(e) => setLocation({ ...location, facebook: e.target.value })}
                InputProps={{
                  endAdornment: location?.facebook && (
                    <Icon onClick={() => window.open(location.facebook, '_blank')}>
                      open_in_new
                    </Icon>
                  ),
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Instagram</FormLabel>
              <TextField
                value={location?.instagram || ""}
                onChange={(e) => setLocation({ ...location, instagram: e.target.value })}
                InputProps={{
                  endAdornment: location?.instagram && (
                    <Icon onClick={() => window.open(location.instagram, '_blank')}>
                      open_in_new
                    </Icon>
                  ),
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Address 1 *</FormLabel>
              <TextField
                required
                value={location?.address1 || ""}
                onChange={(e) => setLocation({ ...location, address1: e.target.value })}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Address 2</FormLabel>
              <TextField
                value={location?.address2 || ""}
                onChange={(e) => setLocation({ ...location, address2: e.target.value })}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <FormLabel>City *</FormLabel>
              <TextField
                required
                value={location?.city || ""}
                onChange={(e) => setLocation({ ...location, city: e.target.value })}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <FormLabel>State *</FormLabel>
              <TextField
                required
                value={location?.state || ""}
                onChange={(e) => setLocation({ ...location, state: e.target.value })}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <FormLabel>ZIP *</FormLabel>
              <TextField
                required
                value={location?.zip || ""}
                onChange={(e) => setLocation({ ...location, zip: e.target.value })}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Phone</FormLabel>
              <TextField
                value={location?.phone || ""}
                onChange={(e) => setLocation({ ...location, phone: e.target.value })}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Tags</FormLabel>
              <TagMultiSelect
                selectedTags={location?.tags || []}
                setSelectedTags={(tags) => {
                  setLocation({
                    ...location,
                    tags
                  });
                }}
                type={["location"]}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <LoadingOverlay loading={contextLoading || searchLoading} />
    </Grid>
  );
};

export default LocationDetails; 