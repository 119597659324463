import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Modal } from '@mui/material';
import { useAuth } from 'context/Auth';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckoutForm from 'components/StripeCheckoutForm';
import stripeService from 'services/stripe';
import { CheckIcon } from '@heroicons/react/20/solid';
import { STRIPE_PUBLIC_KEY } from 'config';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY!);

const Plans = () => {
  const { user, refreshUser } = useAuth();
  const [plans, setPlans] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedPriceId, setSelectedPriceId] = React.useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const navigate = useNavigate();
  React.useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await stripeService.getPlans();
        setPlans(response);
      } catch (error) {
        console.error('Error fetching plans:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  const handleSubscribe = (priceId: string) => {
    setSelectedPriceId(priceId);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedPriceId(null);
  };

  const handleSuccess = async () => {
    await refreshUser();
    setIsModalOpen(false);
    setSelectedPriceId(null);
    navigate('/');
  };

  const formatPrice = (amount: number) => {
    return `$${(amount / 100).toFixed(2)}`;
  };

  const getIncludedFeatures = (plan: any) => {
    return [
      'Private forum access',
      'Member resources',
      'Entry to annual conference',
      'Official member t-shirt',
    ];
  };

  if (loading) {
    return <Box sx={{ p: 4 }}><Typography>Loading plans...</Typography></Box>;
  }

  if (plans.length === 0) {
    return <Box sx={{ p: 4 }}><Typography>No plans found</Typography></Box>;
  }

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl sm:text-center">
          <h2 className="text-pretty text-5xl font-semibold tracking-tight text-gray-900 sm:text-balance sm:text-6xl">
            Simple no-tricks pricing
          </h2>
          <p className="mx-auto mt-6 max-w-2xl text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
            Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et quasi iusto modi velit ut non voluptas in.
          </p>
        </div>

        {plans.map((plan) => (
          <div key={plan.product.id} className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3 className="text-3xl font-semibold tracking-tight text-gray-900">{plan.product.name}</h3>
              <p className="mt-6 text-base/7 text-gray-600">
                {plan.product.description}
              </p>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm/6 font-semibold text-indigo-600">What's included</h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>
              <ul className="mt-8 grid grid-cols-1 gap-4 text-sm/6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                {getIncludedFeatures(plan).map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-indigo-600" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div className="mx-auto max-w-xs px-8">
                  <p className="text-base font-semibold text-gray-600">Monthly subscription</p>
                  <p className="mt-6 flex items-baseline justify-center gap-x-2">
                    <span className="text-5xl font-semibold tracking-tight text-gray-900">{formatPrice(plan.prices[0].unit_amount)}</span>
                    <span className="text-sm/6 font-semibold tracking-wide text-gray-600">USD</span>
                  </p>
                  <button
                    onClick={() => handleSubscribe(plan.prices[0].id)}
                    className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Subscribe
                  </button>
                  <p className="mt-6 text-xs/5 text-gray-600">
                    Invoices and receipts available for easy company reimbursement
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="payment-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
          {selectedPriceId && (
            <Elements
              stripe={stripePromise}
              options={{
                mode: 'subscription',
                currency: 'usd',
                payment_method_types: ['card'],
                amount: plans.find((plan) => plan.prices[0].id === selectedPriceId)?.prices[0].unit_amount,
              }}
            >
              <StripeCheckoutForm
                priceId={selectedPriceId}
                userId={user.id}
                amount={plans.find((plan) => plan.prices[0].id === selectedPriceId)?.prices[0].unit_amount}
                currency="usd"
                onSuccess={handleSuccess}
              />
            </Elements>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default Plans;
