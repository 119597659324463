import React from 'react';
import { Grid } from '@mui/material';
import TwilioPhoneNumbers from '../TwilioPhoneNumbers/TwilioPhoneNumbers';

const PhoneNumbersTab: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TwilioPhoneNumbers />
      </Grid>
    </Grid>
  );
};

export default PhoneNumbersTab; 