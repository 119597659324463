import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Grid, FormGroup, FormLabel, FormControlLabel, RadioGroup, Radio, Typography, Checkbox } from '@mui/material';
import filesService from "services/files";
import { IFile } from "types/files.d";
import foldersService from 'services/folders';

interface ImageModalProps {
  open: boolean;  // Controls whether the modal is open or closed
  onClose: () => void;  // Function to handle closing the modal
  onAccept: (image: IFile) => void;  // Function to save the image URL
  setLoading?: (loading: boolean) => void;  // Function to set the loading state
  bucketPath?: string;
  folderPath?: string;
  context?: string;
}

const DEFAULT_TEXT = "";
const DEFAULT_GENERATED_MEDIA_FOLDER = "AI Generated Media";

const ImageModal: React.FC<ImageModalProps> = ({ open, onClose, onAccept, setLoading, bucketPath, folderPath, context = "" }) => {

  const [generatedImageUrl, setGeneratedImageUrl] = useState<string | null>(null);  // State for the generated image
  const [newPrompt, setNewPrompt] = React.useState<string | undefined>(DEFAULT_TEXT);
  const [promptStyle, setPromptStyle] = useState<any>("");
  const [size, setSize] = useState<any>("Square");
  const [useDefaultFolder, setUseDefaultFolder] = useState<boolean>(false);

  const generatePrompt = () => {
    // Combine description, style, and size into the prompt
    let generationPrompt = `Using the following article context: "${context}", generate an image that matches the description: "${newPrompt}" in the style of "${promptStyle}"`;
    return generationPrompt;
  };

  // Map selected size to the corresponding image dimensions
  const getImageSize = (size: string) => {
    switch (size) {
      case "Portrait":
        return "1024x1792";
      case "Landscape":
        return "1792x1024";
      default:
        return "1024x1024";  // Default to square
    }
  };

  // Handle the click event of the "Generate" button
  const handleGenerateClick = async () => {
    try {
      setLoading(true);
      if (!newPrompt || !promptStyle) {
        alert("Please fill in both the prompt and the art style.");
        return;
      }
      console.log(newPrompt)
      console.log(promptStyle)

      // Use image service to generate the image based on the prompt and selected size
      const response = await filesService.generate({
        prompt: generatePrompt(),
        path: bucketPath,
        size: getImageSize(size),
      });
      console.log(response)
      console.log(response.data.image)

      setGeneratedImageUrl(response.data.image);  // Set the generated image URL
      console.log(response)
    } catch (error: any) {
      console.error("Error generating image:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle the click event of the "Save" button
  const handleSaveClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    // Create a file object with the generated image URL
    if (generatedImageUrl) {
      const existingFolders = await foldersService.fetchAll();
      let folderId: string;

      if (useDefaultFolder) {
        const aiGeneratedFolderExists = existingFolders.data.some(
          (folder: any) => folder.name === DEFAULT_GENERATED_MEDIA_FOLDER
        );

        if (!aiGeneratedFolderExists) {
          const createdFolder = await foldersService.create({
            name: DEFAULT_GENERATED_MEDIA_FOLDER,
          });
          folderId = createdFolder.id;
        } else {
          const existingFolder = existingFolders.data.find(
            (folder: any) => folder.name === DEFAULT_GENERATED_MEDIA_FOLDER
          );
          folderId = existingFolder.id;
        }
      } else {
        const eventFolder = existingFolders.data.find(
          (folder: any) => folder.name === folderPath
        );

        if (eventFolder) {
          folderId = eventFolder.id;
        } else {
          const createdEventFolder = await foldersService.create({
            name: folderPath,
          });
          folderId = createdEventFolder.id;
        }
      }
      const generatedImage = await filesService.createFromUrl({
        url: generatedImageUrl,
        folderId: folderId,
      });
      console.log(generatedImage)
      onAccept(generatedImage);
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Generate an AI Image</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Left column: Display the generated image */}
          <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
            {generatedImageUrl ? (
              <img
                src={generatedImageUrl}
                alt="Generated"
                style={{ maxWidth: '100%', objectFit: 'contain' }}
              />
            ) : (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  border: '1px dashed #ccc',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p>No image generated yet</p>
              </div>
            )}
          </Grid>

          {/* Right column: Input for description and buttons */}
          <Grid item xs={12} md={6} display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={2}>
            {/* Prompt */}
            <TextField
              autoFocus
              margin="dense"
              id="first-prompt"
              label="Prompt"
              type="text"
              fullWidth
              value={newPrompt}
              onChange={(e) => setNewPrompt(e.target.value)}
              multiline
              rows={5}
            />

            {/* Prompt Style */}
            <TextField
              margin="dense"
              id="second-prompt"
              label="Art Style"
              type="text"
              fullWidth
              value={promptStyle}
              onChange={(e) => setPromptStyle(e.target.value)}
              rows={5}
            />

            {/* Size */}
            <Grid container spacing={2} justifyContent="left" alignItems="left" padding={2}>
              <FormGroup sx={{ paddingBottom: '10px' }}>
                <Typography variant="body2">Select Image Size</Typography>
                <RadioGroup
                  row
                  value={size}
                  onChange={(e) => setSize(e.target.value)}
                >
                  <FormControlLabel value="Square" control={<Radio />} label="Square (1024x1024)" />
                  <FormControlLabel value="Portrait" control={<Radio />} label="Portrait (1024x1792)" />
                  <FormControlLabel value="Landscape" control={<Radio />} label="Landscape (1792x1024)" />
                </RadioGroup>
              </FormGroup>
              <FormGroup>
                <Typography variant="body2">Save to {folderPath}?</Typography>
                <RadioGroup
                  row
                  value={useDefaultFolder ? "No" : "Yes"}
                  onChange={(e) => setUseDefaultFolder(e.target.value === "No")}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {/* Cancel button */}
        <Button onClick={onClose} color="secondary" variant="contained">
          Cancel
        </Button>

        {/* Generate/Regenerate button */}
        <Button onClick={handleGenerateClick} color="primary" variant="contained">
          {generatedImageUrl ? 'Regenerate Image' : 'Generate Image'}
        </Button>

        {/* Save button (only active when an image has been generated) */}
        {generatedImageUrl && (
          <Button onClick={handleSaveClick} color="success" variant="contained">
            Use Image
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ImageModal;