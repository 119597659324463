import React from 'react';
import {
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import { ITicket, TicketType, TicketStatus, PaymentStatus } from 'types/tickets';
import EventSelect from 'components/EventSelect';
import MemberMultiSelect from 'components/MemberMultiSelect/MemberMultiSelect';
import OrganizationSelect from 'components/OrganizationSelect/OrganizationSelect';

interface TicketFormProps {
  ticket: Partial<ITicket>;
  setTicket: (ticket: Partial<ITicket>) => void;
}

const TicketForm: React.FC<TicketFormProps> = ({ ticket, setTicket }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormLabel>Event</FormLabel>
        <EventSelect
          value={ticket.event || null}
          onChange={(event) => setTicket({ ...ticket, event: event })}
        />
      </Grid>

      <Grid item xs={12}>
        <FormLabel>Member</FormLabel>
        <MemberMultiSelect
          selectedMembers={ticket.member ? [ticket.member] : []}
          setSelectedMembers={(members) => setTicket({ ...ticket, member: members[0] })}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel>Ticket Type</FormLabel>
          <Select
            value={ticket.ticketType || ''}
            onChange={(e) => setTicket({ ...ticket, ticketType: e.target.value as TicketType })}
          >
            <MenuItem value="general">General</MenuItem>
            <MenuItem value="vip">VIP</MenuItem>
            <MenuItem value="early_bird">Early Bird</MenuItem>
            <MenuItem value="student">Student</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel>Status</FormLabel>
          <Select
            value={ticket.status || 'valid'}
            onChange={(e) => setTicket({ ...ticket, status: e.target.value as TicketStatus })}
          >
            <MenuItem value="valid">Valid</MenuItem>
            <MenuItem value="used">Used</MenuItem>
            <MenuItem value="cancelled">Cancelled</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel>Price</FormLabel>
          <TextField
            type="number"
            value={ticket.price || 0}
            onChange={(e) => setTicket({ ...ticket, price: Number(e.target.value) })}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel>Currency</FormLabel>
          <TextField
            value={ticket.currency || 'USD'}
            onChange={(e) => setTicket({ ...ticket, currency: e.target.value })}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel>Payment Status</FormLabel>
          <Select
            value={ticket.paymentStatus || 'free'}
            onChange={(e) => setTicket({ ...ticket, paymentStatus: e.target.value as PaymentStatus })}
          >
            <MenuItem value="free">Free</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="paid">Paid</MenuItem>
            <MenuItem value="refunded">Refunded</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel>Attendee Name</FormLabel>
          <TextField
            value={ticket.attendeeInfo?.name || ''}
            onChange={(e) => setTicket({
              ...ticket,
              attendeeInfo: { ...ticket.attendeeInfo, name: e.target.value }
            })}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel>Attendee Email</FormLabel>
          <TextField
            type="email"
            value={ticket.attendeeInfo?.email || ''}
            onChange={(e) => setTicket({
              ...ticket,
              attendeeInfo: { ...ticket.attendeeInfo, email: e.target.value }
            })}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel>Attendee Phone</FormLabel>
          <TextField
            value={ticket.attendeeInfo?.phone || ''}
            onChange={(e) => setTicket({
              ...ticket,
              attendeeInfo: { ...ticket.attendeeInfo, phone: e.target.value }
            })}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default TicketForm; 