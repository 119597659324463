import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "context/Auth";
import InactiveUser from "components/InactiveUser/InactiveUser";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { loading, isAuthenticated, hasSubscription, user } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Check if user is inactive
  if (user && user.isActive === false) {
    return <InactiveUser />;
  }

  if (user && isAuthenticated && !hasSubscription && window.location.pathname !== '/plans') {
    return <Navigate to="/plans" />;
  }

  return children;
};

export default ProtectedRoute;
