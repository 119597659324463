import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  Typography,
  Alert,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import ticketsService from "services/tickets";
import { ITicket } from "types/tickets";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";
import TicketForm from "components/TicketForm/TicketForm";

const Ticket: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [ticket, setTicket] = useState<Partial<ITicket>>({});

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchTicket = async () => {
      try {
        setLoading(true);
        const _ticket = await ticketsService.fetch(id);
        setTicket(_ticket);
      } catch (e) {
        console.error("Error fetching ticket:", e);
      } finally {
        setLoading(false);
      }
    };
    if (id && id !== "create") {
      fetchTicket();
    }
  }, [id]);

  const handleSave = async () => {
    try {
      setLoading(true);
      if (!ticket.organization || !ticket.event || !ticket.member || !ticket.ticketType) {
        throw new Error("Please fill in all required fields");
      }

      if (id === "create") {
        ticket.createdBy = user;
        ticket.createdDate = new Date().toISOString();
        await ticketsService.create(ticket);
      } else {
        ticket.updatedBy = user;
        ticket.updatedDate = new Date().toISOString();
        await ticketsService.update(ticket.id, ticket);
      }
      navigate("/tickets");
    } catch (error: any) {
      console.error("Error saving ticket:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await ticketsService.remove(id);
      navigate("/tickets");
    } catch (error) {
      console.error("Error deleting ticket:", error);
    }
  };

  return (
    <BasicLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">Ticket</Typography>
        </Grid>

        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <TicketForm ticket={ticket} setTicket={setTicket} />
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>

        {id && id !== "create" && (
          <Grid item xs={12}>
            <Typography variant="h4" color="error">
              Danger Zone
            </Typography>
            <Button variant="contained" color="error" onClick={handleDelete}>
              Delete
            </Button>
          </Grid>
        )}

        <LoadingOverlay loading={loading} />
      </Grid>
    </BasicLayout>
  );
};

export default Ticket; 