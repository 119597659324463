import { useLocation, Navigate, Routes, Route, Link, useNavigate } from "react-router-dom";
import { Box, Tabs, Tab, Typography, Grid, IconButton, Alert, Button } from '@mui/material';
import { ChevronLeft } from "@mui/icons-material";
import BasicLayout from "components/Layouts/BasicLayout";
import { StatusEnum } from 'types/events.d';
import DetailsTab from "./DetailsTab";
import OccurrenceTab from "./OccurrenceTab";
import MediaTab from "./MediaTab";
import PromotionsTab from "./PromotionsTab";
import RSVPsTab from "./RsvpTab";
import AdminTab from "./AdminTab";
import LoadingOverlay from "components/LoadingOverlay";
import { EventProvider, useEvent } from "./EventContext";

const EventContent: React.FC = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const { loading, error, saveEvent, event } = useEvent();
  const currentTab = location.pathname.split('/').pop() || 'details';

  const tabPaths = {
    details: 'details',
    occurrence: 'occurrence',
    media: 'media',
    promotions: 'promotions',
    rsvps: 'rsvps',
    admin: 'admin'
  };

  const handleSave = (data?: Record<string, any>) => async () => {
    const result = await saveEvent(data);
    if (result) {
      navigate("/events");
    }
  };

  return (
    <BasicLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Box display={"flex"} alignItems={"center"}>
            <IconButton onClick={() => navigate("/events")}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="h4">{event?.title ? "Event: " + event.title : "Event"}</Typography>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', gap: 2, ml: 4 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSave({ status: StatusEnum.Pending })}
                disabled={loading}
              >
                Save
              </Button>
              <Button
                variant="contained"
                onClick={handleSave({ status: StatusEnum.Published })}
                disabled={loading}
              >Publish
              </Button>
            </Box>
          </Box>
        </Grid>

        {error && <Alert severity="error">{error}</Alert>}

        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
            <Tabs value={tabPaths[currentTab] || 'details'}>
              <Tab label="Details" value="details" component={Link} to={tabPaths.details} />
              <Tab label="Occurrence" value="occurrence" component={Link} to={tabPaths.occurrence} />
              <Tab label="Media" value="media" component={Link} to={tabPaths.media} />
              <Tab label="Promotions" value="promotions" component={Link} to={tabPaths.promotions} />
              <Tab label="RSVPs" value="rsvps" component={Link} to={tabPaths.rsvps} />
              <Tab label="Admin" value="admin" component={Link} to={tabPaths.admin} />
            </Tabs>
          </Box>
          <Routes>
            <Route path="details" element={<DetailsTab />} />
            <Route path="occurrence" element={<OccurrenceTab />} />
            <Route path="media" element={<MediaTab />} />
            <Route path="promotions" element={<PromotionsTab />} />
            <Route path="rsvps" element={<RSVPsTab />} />
            <Route path="admin" element={<AdminTab />} />
            <Route path="/" element={<Navigate to="details" replace />} />
          </Routes>
        </Grid>
      </Grid>
      <LoadingOverlay loading={loading} />
    </BasicLayout>
  );
};

const Event: React.FC = () => {
  return (
    <EventProvider>
      <EventContent />
    </EventProvider>
  );
};

export default Event;