import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Grid,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  FormLabel,
  Tooltip
} from "@mui/material";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ContentCopy, Delete, Add } from "@mui/icons-material";
import apiKeysService from 'services/apiKeys';
import LoadingOverlay from "components/LoadingOverlay";
import { v4 as uuidv4 } from 'uuid';
interface ApiKey {
  id: string;
  name: string;
  token: string;
  createdAt: string;
}

const ApiKeysTab: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newKeyName, setNewKeyName] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const fetchApiKeys = useCallback(async () => {
    setLoading(true);
    try {
      const response = await apiKeysService.fetchAll(paginationModel);
      setApiKeys(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching API keys:', error);
    } finally {
      setLoading(false);
    }
  }, [paginationModel]);

  useEffect(() => {
    fetchApiKeys();
  }, [fetchApiKeys]);

  const handleCreateKey = async () => {
    if (!newKeyName.trim()) return;

    setLoading(true);
    try {
      const result = await apiKeysService.create({
        name: newKeyName,
        accessToken: uuidv4()
      });
      console.log('API Key creation result:', result);
      setNewKeyName('');
      setIsModalOpen(false);
      fetchApiKeys();
    } catch (error) {
      console.error('Error creating API key:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteKey = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this API key?')) return;

    setLoading(true);
    try {
      await apiKeysService.remove(id);
      fetchApiKeys();
    } catch (error) {
      console.error('Error deleting API key:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCopyToken = (token: string) => {
    navigator.clipboard.writeText(token);
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'accessToken',
      headerName: 'Access Token',
      flex: 2,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography sx={{
            fontFamily: 'monospace',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '12px'
          }}>
            {params.value}
          </Typography>
          <Tooltip title="Copy token">
            <IconButton
              size="small"
              onClick={() => handleCopyToken(params.value)}
            >
              <ContentCopy fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      )
    },
    {
      field: 'createdDate',
      headerName: 'Created',
      flex: 1,
      valueGetter: (params) => new Date(params.value).toLocaleDateString()
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <IconButton
          color="error"
          onClick={() => handleDeleteKey(params.row.id)}
        >
          <Delete />
        </IconButton>
      ),
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h5">API Keys</Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => setIsModalOpen(true)}
          >
            Create New Key
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <DataGrid
          rows={apiKeys}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 25, 50]}
          disableRowSelectionOnClick
          autoHeight
        />
      </Grid>

      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Create New API Key</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <FormLabel required>Key Name</FormLabel>
            <TextField
              value={newKeyName}
              onChange={(e) => setNewKeyName(e.target.value)}
              placeholder="Enter a name for this API key"
              fullWidth
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
          <Button
            onClick={handleCreateKey}
            variant="contained"
            disabled={!newKeyName.trim()}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <LoadingOverlay loading={loading} />
    </Grid>
  );
};

export default ApiKeysTab; 