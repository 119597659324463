import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";

import { ChevronLeft } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";
import promotionsService from "services/promotions";
import { IPromotion } from "types/promotions.d";
import AITextField from "components/AITextField";

const Promotion: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [promotion, setPromotion] = useState<IPromotion>(undefined);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchPromotion = async () => {
      try {
        const promotion = await promotionsService.fetch(id);
        setPromotion(promotion);
      } catch (e) {
        console.error("Error fetching event:", e);
      }
    };
    if (id && id !== "create") {
      fetchPromotion();
    }
  }, [id]);

  const handleSave = async () => {
    try {
      setLoading(true);
      const newPromotion: IPromotion = {
        ...promotion,
        updatedBy: user?.uid,
        updatedAt: new Date().toISOString(),
        type: "event",
      };

      if (!newPromotion.title) {
        throw new Error("Please fill in all fields");
      }
      if (id === "create") {
        // TODO Check to make sure it doesn't already exist
        // const q = query(collection(getFirestore(), firestoreCollection), where("placeId", "==", newLocation.placeId));
        // const locationsSnapshot = await getDocs(q);
        // if(locationsSnapshot.docs.length > 0) {
        //   throw new Error('Location already exists');
        // }
        newPromotion.createdBy = user;
        newPromotion.createdAt = new Date().toISOString();
        await promotionsService.create(newPromotion);
      } else {
        newPromotion.updatedBy = user;
        newPromotion.updatedAt = new Date().toISOString();
        await promotionsService.update(newPromotion.id, newPromotion);
      }
      navigate("/promotions");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await promotionsService.remove(id);
      navigate("/promotions");
    } catch (error) {
      console.error("Error deleting listing:", error);
    }
  };

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">Promotion</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Grid container direction={"column"} style={{ padding: "20px" }} spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="title">Title</FormLabel>
                    <TextField
                      id="title"
                      required={true}
                      value={promotion?.title || ""}
                      onChange={(e) => setPromotion((prev) => ({ ...prev, title: e.target.value }))}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="subtitle">Subtitle</FormLabel>
                    <TextField
                      id="subtitle"
                      value={promotion?.subtitle || ""}
                      onChange={(e) =>
                        setPromotion((prev) => ({ ...prev, subtitle: e.target.value }))
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <AITextField
                      id="description"
                      label="Description"
                      prompt="From the following details, create a description for an promotion. The description should be descriptive and engaging and be at least 3 sentences long."
                      promptMode="modal"
                      value={promotion?.description || ""}
                      onChange={(val: any) => setPromotion({ ...promotion, description: val })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="code">Promotion Code</FormLabel>
                    <TextField
                      id="code"
                      value={promotion?.code || ""}
                      onChange={(e) => setPromotion((prev) => ({ ...prev, code: e.target.value }))}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="qr-code">QR Code</FormLabel>
                    <TextField
                      id="qr-code"
                      value={promotion?.qrcode || ""}
                      onChange={(e) =>
                        setPromotion((prev) => ({ ...prev, qrcode: e.target.value }))
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2}>
            {id && id !== "create" && (
              <Grid item xs={12}>
                <Typography variant="h4" color="alert">
                  Danger Zone
                </Typography>
                <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <LoadingOverlay loading={loading} />
      </Grid>
    </BasicLayout>
  );
};

export default Promotion;
