import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Typography,
  TextField,
  Snackbar,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import { INewsletter } from "types/newsletters";
import LoadingOverlay from "components/LoadingOverlay";
import { useState } from "react";

interface NewsLetterFormProps {
  id: string;
  newsletter?: Partial<INewsletter>;
  setNewsletter: (newsletter: Partial<INewsletter>) => void;
  setError: (error: string) => void;
  loading: boolean;
  handleSave: () => void;
  handleDelete: () => void;
}

const NewsLetterForm: React.FC<NewsLetterFormProps> = ({ id, newsletter, setNewsletter, setError, loading, handleSave, handleDelete }) => {

  const [snackbar, setSnackbar] = useState<any>({ open: false, message: "", severity: "success" });

  return (
    <>
      <Grid container item direction={"row"} xs={12} spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="newsletter-subject">Subject</FormLabel>
            <TextField
              id="newsletter-subject"
              value={newsletter?.subject || ""}
              required
              onChange={(e) => setNewsletter({ ...newsletter, subject: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="newsletter-status">Status</FormLabel>
            <Select
              id="newsletter-status"
              value={newsletter?.status || "draft"}
              onChange={(e) => setNewsletter({ ...newsletter, status: e.target.value as any })}
            >
              <MenuItem value="draft">Draft</MenuItem>
              <MenuItem value="scheduled">Scheduled</MenuItem>
              <MenuItem value="sent">Sent</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="newsletter-scheduled-date">Scheduled Date</FormLabel>
            <TextField
              id="newsletter-scheduled-date"
              type="datetime-local"
              value={newsletter?.scheduledDate ? new Date(newsletter.scheduledDate).toISOString().slice(0, 16) : ""}
              disabled={newsletter?.status !== 'scheduled'}
              onChange={(e) => setNewsletter({ ...newsletter, scheduledDate: new Date(e.target.value) })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={newsletter?.status === 'sent' ? handleSave : handleSave}> {/* TODO: Add send functionality */}
            {newsletter?.status === 'sent' ? 'Send' : 'Save'}
          </Button>
        </Grid>
      </Grid>
      {id && id !== "create" && (
        <Grid item xs={12}>
          <Typography variant="h4" color="error">
            Danger Zone
          </Typography>
          <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
            Delete
          </Button>
        </Grid>
      )}
      <LoadingOverlay loading={loading} />
      <Snackbar
        open={snackbar.open}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </>
  )
};

export default NewsLetterForm;