import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  TextField,
} from "@mui/material";

import { IFolder } from "types/folders.d";
import { useAuth } from "context/Auth";
import foldersService from "services/folders";
import LoadingOverlay from "components/LoadingOverlay";
import { useMediaGallery } from "./MediaGalleryContext";

const AddFolderDialog = () => {
  const [loading, setLoading] = React.useState(false);
  const { user } = useAuth();
  const [addFolderModalOpen, setAddFolderModalOpen] = useState(false);
  const [folderName, setFolderName] = useState("");

  const { getFolders } = useMediaGallery();

  const handleSaveNewFolder = async () => {
    try {
      setLoading(true);

      const newFolder: IFolder = {
        updatedBy: user?.uid,
        updatedDate: new Date().toISOString(),
        name: folderName,
        images: [],
        createdBy: user?.uid,
        createdDate: new Date().toISOString(),
      };
      if (!newFolder.name) {
        throw new Error("Please fill in all fields");
      }
      await foldersService.create(newFolder);
    } catch (error: any) {
      console.error("Error saving event:", error);
    } finally {
      setLoading(false);
      setAddFolderModalOpen(false);
      getFolders();
    }
  };

  const handleAddFolderModalClose = () => {
    setAddFolderModalOpen(false);
    setFolderName("");
  };

  return (
    <>
      <Button
        disabled={loading}
        variant="contained"
        color="primary"
        onClick={() => setAddFolderModalOpen(true)}
        sx={{ mr: 1 }}
      >
        Add Folder
      </Button>

      <Dialog open={addFolderModalOpen} onClose={handleAddFolderModalClose} maxWidth="sm" fullWidth>
        <DialogTitle style={{ display: "flex", justifyContent: "space-between" }}>
          Add New Folder
          <IconButton onClick={handleAddFolderModalClose}>
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: "20px" }}>
          <TextField
            label="Folder Name"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveNewFolder}
            style={{ marginTop: "20px" }}
          >
            Save
          </Button>
        </DialogContent>
      </Dialog>
      <LoadingOverlay loading={loading} />
    </>
  );
};

export default AddFolderDialog;
