import { FormLabel, RadioGroup, FormControl, Grid, FormControlLabel, Radio } from "@mui/material";
import EventOccurrenceForm from "components/EventOccurrenceForm";
import EventRecurrenceForm from "components/EventRecurrenceForm";
import { IEvent } from "types/events.d";
import { useEvent } from "./EventContext";

const OccurrenceTab: React.FC = () => {

  const { event, setEvent } = useEvent();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel focused={false} htmlFor="event-type-group">
            Event Type
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="event-type-group"
            name="event-type-group"
            value={event.eventType}
            onChange={(e) =>
              setEvent({
                ...event,
                eventType: e.target.value as "single" | "multi" | "recurring",
              })
            }
          >
            <FormControlLabel
              value="single"
              control={<Radio />}
              label="Single Occurrence (ex. Jan 1, 2025)"
            />
            <FormControlLabel
              value="multi"
              control={<Radio />}
              label="Multi-Occurrence (ex. Fri-Sun)"
            />
            <FormControlLabel
              value="recurring"
              control={<Radio />}
              label="Recurring Event (ex. Every Monday)"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {event.eventType === "single" || event.eventType === "multi" ? (
          <EventOccurrenceForm
            event={event as IEvent}
            onChange={(updatedEventData) => setEvent({ ...event, ...updatedEventData })}
          />
        ) : (
          <EventRecurrenceForm
            event={event as IEvent}
            onChange={(updatedEventData) => setEvent({ ...event, ...updatedEventData })}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default OccurrenceTab;