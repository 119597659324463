import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context/Auth';

const InactiveUser: React.FC = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      padding={3}
    >
      <Typography variant="h4" gutterBottom>
        Account Inactive
      </Typography>
      <Typography variant="body1" textAlign="center" marginBottom={3}>
        Sorry, your account has been deactivated. Please contact your administrator for assistance.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleLogout}>
        Return to Login
      </Button>
    </Box>
  );
};

export default InactiveUser; 