import { Grid, FormLabel, FormControl, debounce, TextField, FormControlLabel, FormGroup, Checkbox } from "@mui/material";
import TipTap from "components/TipTap";
import { INewsletter } from "types/newsletters";

interface NewsLetterContentProps {
  newsletter: Partial<INewsletter>;
  setNewsletter: (newsletter: Partial<INewsletter>) => void;
  isSmallScreen: boolean;
}

const NewsLetterContent: React.FC<NewsLetterContentProps> = ({ newsletter, setNewsletter, isSmallScreen }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <FormLabel sx={{ marginRight: 2 }}>Type:</FormLabel>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={newsletter.type?.includes('email')}
                onChange={(e) => {
                  const newTypes = e.target.checked
                    ? [...(newsletter.type || []), 'email']
                    : (newsletter.type || []).filter(t => t !== 'email');
                  setNewsletter({ ...newsletter, type: newTypes as ('email' | 'sms' | 'push')[] });
                }}
              />
            }
            label="Email"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={newsletter.type?.includes('sms')}
                onChange={(e) => {
                  const newTypes = e.target.checked
                    ? [...(newsletter.type || []), 'sms']
                    : (newsletter.type || []).filter(t => t !== 'sms');
                  setNewsletter({ ...newsletter, type: newTypes as ('email' | 'sms' | 'push')[] });
                }}
              />
            }
            label="SMS"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={newsletter.type?.includes('push')}
                onChange={(e) => {
                  const newTypes = e.target.checked
                    ? [...(newsletter.type || []), 'push']
                    : (newsletter.type || []).filter(t => t !== 'push');
                  setNewsletter({ ...newsletter, type: newTypes as ('email' | 'sms' | 'push')[] });
                }}
              />
            }
            label="Push Notification"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={isSmallScreen ? 12 : 6}>
        <FormControl fullWidth>
          <FormLabel htmlFor="newsletter-email-content">Email HTML Content</FormLabel>
          <TipTap
            content={newsletter?.emailContent}
            onChange={debounce((value: JSON) => setNewsletter({ ...newsletter, emailContent: value }), 300)}
            height={450}
          />
        </FormControl>
      </Grid>
      <Grid item xs={isSmallScreen ? 12 : 6}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControl fullWidth>
              <FormLabel htmlFor="newsletter-html-content">SMS Content</FormLabel>
              <TextField
                id="newsletter-html-content"
                value={newsletter?.smsContent}
                multiline
                rows={10}
                onChange={(e) => setNewsletter({ ...newsletter, smsContent: e.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <FormLabel htmlFor="newsletter-html-content">Push Notification Content</FormLabel>
              <TextField
                id="newsletter-html-content"
                value={newsletter?.pushContent}
                multiline
                rows={10}
                onChange={(e) => setNewsletter({ ...newsletter, pushContent: e.target.value })}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NewsLetterContent;