import { FormControl, Select, MenuItem } from '@mui/material';
import { FilterComponentProps } from './types';

export const SelectFilter = ({ filterOption, value, onChange }: FilterComponentProps) => {
  return (
    <FormControl fullWidth>
      <Select
        value={value || ''}
        onChange={(e) => {
          onChange(e.target.value ? {
            field: filterOption.name,
            value: e.target.value,
            operator: filterOption.operator || 'eq'
          } : null);
        }}
        displayEmpty
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {filterOption.options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}; 