import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { FilterComponentProps } from './types';

export const DateFilter = ({ filterOption, value, onChange }: FilterComponentProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value ? dayjs(value) : null}
        onChange={(newValue) => {
          onChange(newValue ? {
            field: filterOption.name,
            value: newValue.toISOString(),
            operator: filterOption.operator || 'eq'
          } : null);
        }}
        slotProps={{
          textField: {
            fullWidth: true,
            placeholder: filterOption?.placeholder
          }
        }}
      />
    </LocalizationProvider>
  );
}; 