import React, { createContext, useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ILocationData } from "types/locations.d";
import locationsService from "services/locations";

interface LocationContextType {
  location: ILocationData | undefined;
  setLocation: (location: ILocationData | undefined) => void;
  loading: boolean;
  error: string;
  refetchLocation: () => Promise<void>;
}

const LocationContext = createContext<LocationContextType | undefined>(undefined);

export const LocationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [location, setLocation] = useState<ILocationData | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { id } = useParams<{ id: string }>();

  const fetchLocation = async () => {
    if (id && id !== "create") {
      try {
        setLoading(true);
        const data = await locationsService.fetch(id);
        setLocation(data);
      } catch (e) {
        console.error("Error fetching location:", e);
        setError("Error fetching location");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchLocation();
  }, [id]);

  return (
    <LocationContext.Provider
      value={{
        location,
        setLocation,
        loading,
        error,
        refetchLocation: fetchLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const useLocation = () => {
  const context = useContext(LocationContext);
  if (context === undefined) {
    throw new Error("useLocation must be used within a LocationProvider");
  }
  return context;
}; 