import { API_HOST } from "../config";
import axios from "axios";
import TokenHandler from "./base";
import { IPaginationModal } from "types/App";

// Account Management
async function createAccount(data: any) {
  const response = await axios.post(`${API_HOST}/stripe/accounts`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function getAccount(accountId: string) {
  const response = await axios.get(`${API_HOST}/stripe/accounts/${accountId}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function updateAccount(accountId: string, data: any) {
  const response = await axios.post(`${API_HOST}/stripe/accounts/${accountId}`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function deleteAccount(accountId: string) {
  const response = await axios.delete(`${API_HOST}/stripe/accounts/${accountId}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

// Bank Account Management
async function addBankAccount(accountId: string, data: any) {
  const response = await axios.post(`${API_HOST}/stripe/accounts/${accountId}/external_accounts`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function verifyBankAccount(accountId: string, bankAccountId: string, data: any) {
  const response = await axios.post(
    `${API_HOST}/stripe/accounts/${accountId}/external_accounts/${bankAccountId}/verify`,
    data,
    {
      headers: TokenHandler.getHeaders(),
    }
  );
  return response.data;
}

async function getBankAccount(accountId: string, bankAccountId: string) {
  const response = await axios.get(
    `${API_HOST}/stripe/accounts/${accountId}/external_accounts/${bankAccountId}`,
    {
      headers: TokenHandler.getHeaders(),
    }
  );
  return response.data;
}

async function updateOrRemoveBankAccount(accountId: string, bankAccountId: string, data: any) {
  const response = await axios.post(
    `${API_HOST}/stripe/accounts/${accountId}/external_accounts/${bankAccountId}`,
    data,
    {
      headers: TokenHandler.getHeaders(),
    }
  );
  return response.data;
}

// Payment Processing
async function createPaymentIntent(data: any) {
  const response = await axios.post(`${API_HOST}/stripe/payment_intents`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function confirmPaymentIntent(paymentIntentId: string) {
  const response = await axios.post(
    `${API_HOST}/stripe/payment_intents/${paymentIntentId}/confirm`,
    {},
    {
      headers: TokenHandler.getHeaders(),
    }
  );
  return response.data;
}

async function getPaymentIntent(paymentIntentId: string) {
  const response = await axios.get(`${API_HOST}/stripe/payment_intents/${paymentIntentId}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function cancelPaymentIntent(paymentIntentId: string) {
  const response = await axios.post(
    `${API_HOST}/stripe/payment_intents/${paymentIntentId}/cancel`,
    {},
    {
      headers: TokenHandler.getHeaders(),
    }
  );
  return response.data;
}

// Payouts
async function createPayout(data: any) {
  const response = await axios.post(`${API_HOST}/stripe/payouts`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function getPayout(payoutId: string) {
  const response = await axios.get(`${API_HOST}/stripe/payouts/${payoutId}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function listPayouts(paginationModel: IPaginationModal = {}) {
  const filteredPagination = { ...paginationModel };
  delete filteredPagination.query;
  delete filteredPagination.sort;

  const url = new URL(`${API_HOST}/stripe/payouts`);
  url.search = new URLSearchParams(filteredPagination).toString();

  const params = {
    ...(paginationModel?.query && { query: paginationModel.query }),
    ...(paginationModel?.sort && { sort: paginationModel.sort }),
  };

  const response = await axios.get(url.toString(), {
    params,
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}


async function getPlans() {
  const response = await axios.get(`${API_HOST}/stripe/subscriptions/plans`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

// Add these functions to stripeService

async function createCustomer(data: any) {
  const response = await axios.post(`${API_HOST}/stripe/customers`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function getCustomer(customerId: string) {
  const response = await axios.get(`${API_HOST}/stripe/customers/${customerId}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}
async function getCurrentSubscription() {
  const response = await axios.get(`${API_HOST}/stripe/subscriptions/current`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}
async function createSubscription(data: any) {
  const response = await axios.post(`${API_HOST}/stripe/subscriptions`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function getSubscription(id) {
  const response = await axios.get(`${API_HOST}/stripe/subscriptions/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function cancelSubscription(subscriptionId: string) {
  const response = await axios.post(
    `${API_HOST}/stripe/subscriptions/${subscriptionId}/cancel`,
    {},
    {
      headers: TokenHandler.getHeaders(),
    }
  );
  return response.data;
}

// Add this function to the service
async function updateSubscription(subscriptionId: string, data: any) {
  const response = await axios.put(
    `${API_HOST}/stripe/subscriptions/${subscriptionId}`,
    data,
    {
      headers: TokenHandler.getHeaders(),
    }
  );
  return response.data;
}

async function scheduleSubscriptionUpdate(subscriptionId: string, data: any) {
  const response = await axios.post(`${API_HOST}/stripe/subscriptions/${subscriptionId}/schedule_update`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}


async function fetchAllInvoices(paginationModel: IPaginationModal = {}) {

  const filteredPagination = { ...paginationModel };
  delete filteredPagination.query;
  delete filteredPagination.sort;

  const url = new URL(`${API_HOST}/stripe/invoices`);
  url.search = new URLSearchParams(filteredPagination).toString();

  const params = {
    ...(paginationModel?.query && { query: paginationModel.query }),
    ...(paginationModel?.sort && { sort: paginationModel.sort }),
  };
  const response = await axios.get(url.toString(), {
    params,
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function fetchInvoice(id: string) {
  const response = await axios.get(`${API_HOST}/stripe/invoices/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function createSetupIntent(data: any) {
  const response = await axios.post(`${API_HOST}/stripe/setup-intents`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

const stripeService = {
  // Invoices
  fetchAllInvoices,
  fetchInvoice,

  // Account Management
  createAccount,
  getAccount,
  updateAccount,
  deleteAccount,

  // Bank Account Management
  addBankAccount,
  verifyBankAccount,
  getBankAccount,
  updateOrRemoveBankAccount,

  // Payment Processing
  createPaymentIntent,
  confirmPaymentIntent,
  getPaymentIntent,
  cancelPaymentIntent,

  // Payouts
  createPayout,
  getPayout,
  listPayouts,

  // Plans
  getPlans,


  createCustomer,
  getCustomer,


  // Subscription Management
  getCurrentSubscription,
  getSubscription,
  createSubscription,
  cancelSubscription,
  updateSubscription,

  // Setup Intents
  createSetupIntent,
};

export default stripeService;

