import { IPaginationModal } from "types/App";
import axios from "axios";
import { API_HOST } from "../config";
import TokenHandler from "./base";
import { ICategory } from "types/categories";

async function fetchAll(paginationModel?: IPaginationModal) {
  const filteredPagination = { ...paginationModel };
  delete filteredPagination.query;
  delete filteredPagination.sort;

  const url = new URL(`${API_HOST}/categories`);
  url.search = new URLSearchParams(filteredPagination).toString();

  const params = {
    ...(paginationModel?.query && { query: paginationModel.query }),
    ...(paginationModel?.sort && { sort: paginationModel.sort }),
  };

  const response = await axios.get(url.toString(), {
    params,
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function fetch(id: string) {
  const response = await axios.get(`${API_HOST}/categories/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function create(data: ICategory) {
  const response = await axios.post(`${API_HOST}/categories`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function update(id: string, data: ICategory) {
  const response = await axios.put(`${API_HOST}/categories/${id}`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function remove(id: string) {
  const response = await axios.delete(`${API_HOST}/categories/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

const categoriesService = {
  fetchAll,
  fetch,
  create,
  update,
  remove,
};

export default categoriesService;
