import { Box } from "@mui/material";
import { useLocation } from "./LocationContext";
import { IFile } from "types/files.d";
import FileGallery from 'components/FileGallery';

const LocationMedia: React.FC = () => {
  const { location, setLocation } = useLocation();

  const handleUpdateMedia = (files: IFile[]) => {
    setLocation({
      ...location,
      files: files
    });
  };

  const handleDeleteMedia = (file: IFile) => {
    setLocation({
      ...location,
      files: location.files?.filter(f => f.id !== file.id) || []
    });
  };

  const handleBulkDeleteMedia = (files: IFile[]) => {
    setLocation({
      ...location,
      files: location.files?.filter(f => !files.includes(f)) || []
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <FileGallery
        files={location?.files || []}
        updateFiles={handleUpdateMedia}
        onDelete={handleDeleteMedia}
        onBulkDelete={handleBulkDeleteMedia}
      />
    </Box>
  );
};

export default LocationMedia; 