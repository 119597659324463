import { useAuth } from 'context/Auth';
import OrganizationSelect from 'components/OrganizationSelect';

const Header: React.FC = () => {
  const { user, currentOrganization, switchOrganization } = useAuth();

  // Return null if user has only one organization
  if (!user?.organizations || user.organizations.length <= 1) {
    return null;
  }

  return (
    <header className="">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-end items-center h-16">
          <OrganizationSelect
            value={currentOrganization}
            onChange={(org) => {
              if (org) {
                switchOrganization(org);
              }
            }}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;