import { API_HOST } from "../config";
import axios from "axios";
import TokenHandler from "./base";

async function fetchAll(paginationModel: any = {}) {
  const filteredPagination = { ...paginationModel };
  delete filteredPagination.query;
  delete filteredPagination.sort;

  const url = new URL(`${API_HOST}/ads`);
  url.search = new URLSearchParams(filteredPagination).toString();

  const response = await axios.get(url.toString(), {
    params: { query: paginationModel.query, sort: paginationModel.sort },
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function fetch(id: string) {
  const response = await axios.get(`${API_HOST}/ads/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function create(data: any) {
  const response = await axios.post(`${API_HOST}/ads`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function update(id: string, data: any) {
  const response = await axios.put(`${API_HOST}/ads/${id}`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function remove(id: string) {
  const response = await axios.delete(`${API_HOST}/ads/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

const adsService = {
  fetch,
  fetchAll,
  create,
  update,
  remove,
};

export default adsService;
