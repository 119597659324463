import { Button } from "@mui/material";
import { MediaGalleryProvider } from "components/MediaGallery/MediaGalleryContext";
import Gallery from "components/MediaGallery/Gallery";
import GalleryHeader from "components/MediaGallery/GalleryHeader";
import FolderList from "components/MediaGallery/FolderList";
import Pagination from "components/MediaGallery/Pagination";
import { IFile } from "types/files.d";

interface IImageSelectGalleryProps {
  setOpenMediaGallery: (open: boolean) => void;
  handleFileSelect: (file: IFile[]) => void;
  handleAddImage: () => void;
  selectedFile: IFile | null;
}

const ImageSelectGallery: React.FC<IImageSelectGalleryProps> = ({ setOpenMediaGallery, handleFileSelect, handleAddImage, selectedFile }) => {
  return (
    <div className="fixed inset-0 z-[1300] flex items-center justify-center">
      <div
        className="fixed inset-0 bg-black/50"
        onClick={() => setOpenMediaGallery(false)}
      />
      <div className="relative w-full max-w-[76vw] bg-white rounded-lg shadow-xl p-4">
        <div className="bg-gray-100 p-0 overflow-hidden rounded-lg p-4">
          <div className="h-[80vh] overflow-auto">
            <MediaGalleryProvider onChangeFileSelection={handleFileSelect} selectionMethod="single">
              <div className="flex flex-col md:flex-row h-full gap-4">
                <div className="w-full md:w-1/4 h-full">
                  <FolderList />
                </div>
                <div className="w-full md:w-3/4 flex flex-col gap-2">
                  <div className="w-full">
                    <GalleryHeader />
                  </div>
                  <div className="w-full flex-1 overflow-auto rounded-md shadow-md">
                    <Gallery />
                  </div>
                  <div className="w-full">
                    <Pagination />
                  </div>
                </div>
              </div>
            </MediaGalleryProvider>
          </div>
        </div>

        {/* Actions */}
        <div className="flex justify-end gap-2 p-4 bg-white border-t">
          <Button
            onClick={() => setOpenMediaGallery(false)}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddImage}
            variant="contained"
            color="primary"
            disabled={!selectedFile}
          >
            Use Image
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImageSelectGallery;