import { API_HOST } from "../config";
import axios from "axios";
import TokenHandler from "./base";
import { OrganizationInvitation } from "types/organizationInvitation";

interface IOrganizationInvitation {
  email: string;
  roleId: string;
  invitationCode?: string;
  password?: string;
}

async function fetchPending() {
  const response = await axios.get(`${API_HOST}/organizations/invitations/`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function invite(data: IOrganizationInvitation) {
  const response = await axios.post(`${API_HOST}/organizations/invitations/invite`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function accept(data: { invitationCode: string; password?: string }) {
  const response = await axios.post(`${API_HOST}/organizations/invitations/accept`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function cancel(invitationId: string) {
  const response = await axios.delete(`${API_HOST}/organizations/invitations/${invitationId}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function getDetails(invitationCode: string) {
  const response = await axios.get(`${API_HOST}/organizations/invitations/${invitationCode}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

const organizationInvitationsService: {
  fetchPending: () => Promise<OrganizationInvitation[]>;
  invite: (data: IOrganizationInvitation) => Promise<OrganizationInvitation>;
  accept: (data: { invitationCode: string; password?: string }) => Promise<OrganizationInvitation>;
  cancel: (invitationId: string) => Promise<OrganizationInvitation>;
  getDetails: (invitationCode: string) => Promise<OrganizationInvitation>;
} = {
  fetchPending,
  invite,
  accept,
  cancel,
  getDetails,
};

export default organizationInvitationsService;
