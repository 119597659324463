import React from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Icon, Typography } from "@mui/material";
import BasicLayout from "components/Layouts/BasicLayout";
import TicketList from "components/TicketList/TicketList";

const Tickets: React.FC = () => {
  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Tickets</Typography>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/tickets/create">
            <Icon>add</Icon>
            Create Ticket
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TicketList />
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default Tickets; 