import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Typography, Alert, IconButton, Tabs, Tab, useMediaQuery, Box } from "@mui/material";
import { IAudience, INewsletter } from "types/newsletters";
import newslettersService from "services/newsletters";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";
import { ChevronLeft } from "@mui/icons-material";
import NewsLetterForm from "components/NewsLetterComponents/NewsLetterForm";
import NewsLetterContent from "components/NewsLetterComponents/NewsLetterContent";
import theme from "assets/theme";
import AudienceForm from "components/Audience/AudienceForm";


const Newsletter: React.FC = () => {
  const [newsletter, setNewsletter] = useState<Partial<INewsletter>>({});
  const [audience, setAudience] = useState<IAudience>({ type: "all" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    const fetchNewsletter = async () => {
      try {
        setLoading(true);
        const _newsletter: INewsletter = await newslettersService.fetch(id);
        setNewsletter(_newsletter);
        // if (_newsletter.audienceId) {
        //   const _audience: IAudience = await audiencesService.fetch(_newsletter.audienceId);
        //   setAudience(_audience);
        // }
      } catch (e) {
        console.error("Error fetching newsletter:", e);
      } finally {
        setLoading(false);
      }
    };
    if (id && id !== "create") {
      fetchNewsletter();
    }
  }, [id]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleSave = async () => {
    try {
      console.log(newsletter); // TODO: remove and add functionality to save audience/send newsletter
      console.log(audience); // TODO: remove
      // setLoading(true);
      // const newNewsletter: Partial<INewsletter> = {
      //   ...newsletter,
      // };

      // if (!newNewsletter?.subject) {
      //   throw new Error("Please fill in all required fields");
      // }

      // if (id === "create") {
      //   newNewsletter.createdBy = user;
      //   newNewsletter.createdDate = new Date();
      //   await newslettersService.create(newNewsletter);
      // } else {
      //   newNewsletter.updatedBy = user;
      //   newNewsletter.updatedDate = new Date();
      //   await newslettersService.update(newNewsletter.id!, newNewsletter);
      // }
      // console.log(newsletter);
      // navigate("/newsletters");
    } catch (error: any) {
      console.log(newsletter);
      console.error("Error saving newsletter:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await newslettersService.remove(id!);
      navigate("/newsletters");
    } catch (error) {
      console.error("Error deleting newsletter:", error);
    }
  };

  return (
    <BasicLayout>
      <Grid container spacing={2}>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid item xs={12} display={"flex"} alignItems={"center"}>
            <IconButton onClick={() => navigate(-1)}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="h4">Newsletter</Typography>
          </Grid>
          {!id || id === "create" ? (
            <Typography variant="body1">Create a new newsletter.</Typography>
          ) : (
            <Typography variant="body1">Edit this newsletter.</Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Content" {...a11yProps(1)} />
            <Tab label="Audience" {...a11yProps(2)} />
          </Tabs>
          <Box sx={{ mt: 2 }}>
            {activeTab === 0 && (
              <NewsLetterForm id={id} newsletter={newsletter} setNewsletter={setNewsletter} setError={setError} loading={loading} handleSave={handleSave} handleDelete={handleDelete} />
            )}
            {activeTab === 1 && (
              <NewsLetterContent newsletter={newsletter} setNewsletter={setNewsletter} isSmallScreen={isSmallScreen} />
            )}
            {activeTab === 2 && (
              <AudienceForm audience={audience} setAudience={setAudience} newsletter={newsletter} setNewsletter={setNewsletter} setLoading={setLoading} />
            )}
          </Box>
        </Grid>
      </Grid>
      <LoadingOverlay loading={loading} />
    </BasicLayout>
  );
};

export default Newsletter;
