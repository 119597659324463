import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import BasicLayout from "components/Layouts/BasicLayout";
import communitiesService from "services/communities";

const Communities: React.FC = () => {
  const columns: GridColDef[] = [
    {
      field: "defaultImage",
      headerName: "",
      width: 70,
      renderCell: (params: any) =>
        params.value ? (
          <img src={params?.value?.url} alt="Community" style={{ width: "50px" }} />
        ) : (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{ fontSize: "50px !important", fontWeight: 100 }}
          >
            image
          </Icon>
        ),
      sortable: false,
    },
    { field: "name", headerName: "Name", minWidth: 200, flex: 1 },
    { field: "description", headerName: "Description", minWidth: 200, flex: 2 },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={5} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Communities</Typography>
        </Grid>
        <Grid item xs={7} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/communities/create">
            <Icon>add</Icon>
            Create Community
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <PaginatedDataGrid
              collectionName="communities"
              defaultSortField="name"
              defaultSortDirection="desc"
              columns={columns}
              service={communitiesService}
            />
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default Communities;
