import { useEffect, useMemo, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Accordion, AccordionDetails, AccordionSummary, Alert, CircularProgress, Dialog, DialogContent, FormControl, FormLabel, Grid, MenuItem, Select, TextField } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import authService from "services/auth";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function ResetPasswordModal(props: Props) {
  const { open, setOpen } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSave = async () => {
    try {
      setLoading(true);
      setError(undefined);

      // Verify that the new password and confirm password match
      if (newPassword !== confirmPassword) {
        throw new Error("New password and confirm password do not match");
      }

      // Verify the password is at least 8 characters long and alpha numeric
      if (newPassword.length < 8) {
        throw new Error("Password must be at least 8 characters long");
      }

      const response = await authService.changePassword(newPassword);
      if (response.status === 200) {
        setOpen(false);
      } else {
        throw new Error(response.data.message);
      }

    } catch (e: any) {
      console.error("Error creating prompt:", e);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const disabled = useMemo(() => !oldPassword || !newPassword || !confirmPassword, [oldPassword, newPassword, confirmPassword]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <DialogContent>
        {
          loading
            ? (
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </Grid>
              </Grid>
            )
            : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MDTypography variant="h4" color="dark">Update Password</MDTypography>
                </Grid>
                {error && (
                  <Grid item xs={12}>
                    <Alert severity="error">{error}</Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="prompt">Old Password</FormLabel>
                    <TextField
                      id="oldPassword"
                      variant="outlined"
                      fullWidth
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      type='password'
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="prompt">New Password</FormLabel>
                    <TextField
                      id="newPassword"
                      variant="outlined"
                      fullWidth
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      type='password'
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="prompt">Confirm Password</FormLabel>
                    <TextField
                      id="confirmPassword"
                      variant="outlined"
                      fullWidth
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      type='password'
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <MDButton
                    variant="contained"
                    color={disabled ? 'primary' : 'success'}
                    disabled={disabled}
                    onClick={handleSave}>Save</MDButton>
                </Grid>
              </Grid>
            )
        }
      </DialogContent>
    </Dialog>
  )
}

export default ResetPasswordModal;