import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useMediaGallery } from "./MediaGalleryContext";

const Pagination = () => {

  const { paginationModel, setPaginationModel, getPaginationString, indexPage, pageCount } = useMediaGallery();

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPaginationModel({ ...paginationModel, pageSize: Number(e.target.value) });
  };

  return (
    <div className="w-full flex flex-row justify-between max-h-[330px]">
      <div />
      <div className="flex flex-row justify-center h-10 gap-4 items-center text-base font-light">
        <p className="flex items-center">
          Items Per Page:{" "}
        </p>
        <select
          className="w-16 h-8 text-base bg-white border border-gray-300 rounded-md 
            px-2 cursor-pointer 
            hover:border-gray-400 
            focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500
            disabled:bg-gray-50 disabled:text-gray-500"
          defaultValue={paginationModel.pageSize.toString()}
          onChange={handlePageSizeChange}
        >
          {["2", "5", "10", "20", "50", "100"].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
        <p>{getPaginationString()}</p>
        <ChevronLeft
          className={`w-5 cursor-pointer ${paginationModel.page === 0 ? "text-gray-300" : "text-gray-600"
            }`}
          onClick={() => indexPage(-1)}
        />
        <ChevronRight
          className={`w-5 cursor-pointer ${pageCount === paginationModel.page + 1 ? "text-gray-300" : "text-gray-600"
            }`}
          onClick={() => indexPage(1)}
        />
      </div>
    </div>
  );
};

export default Pagination;