import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  createFilterOptions,
  TextField
} from "@mui/material";
import locationsService from "../../services/locations";
import { ILocationData } from "types/locations.d";

interface LocationSelectProps {
  value?: ILocationData | string;
  onChange: (location: ILocationData) => void;
}

const LocationSelect: React.FC<LocationSelectProps> = ({ onChange, value }) => {
  const [locations, setLocations] = useState<ILocationData[]>([]);

  useEffect(() => {
    const fetchLocations = async () => {
      const locations = await locationsService.fetchAll({ pageSize: 1000 });
      console.log("locations", locations)
      setLocations(locations.data);
    };
    fetchLocations();
  }, []);

  const handleSelectChange = (value: any) => {
    const selectedLocation = locations.find((location) => location._id === value);
    if (selectedLocation) {
      onChange(selectedLocation);
    }
  };

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 10,
  });

  const getCurrentValue = () => {
    if (!value) return "";
    if (typeof value === "string") return value;
    return value.id || value._id || "";
  };

  return (
    <Autocomplete
      value={getCurrentValue()}
      onChange={(e, value) => handleSelectChange(value)}
      options={locations?.map((location: any) => location._id)}
      filterOptions={filterOptions}
      getOptionLabel={(option: any) => {
        return locations.find((location: any) => location._id === option)?.name || option
      }}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default LocationSelect;
