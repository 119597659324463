import { Box, useMediaQuery } from "@mui/material";
import theme from "assets/theme";
import LoadingOverlay from "components/LoadingOverlay";
import { Grid, Typography, Button } from "@mui/material";
import SimpleSideNav from "components/Sidenav/SimpleSideNav";
import { useAuth } from "context/Auth";
import { ReactNode } from "react";
import Header from "components/Header";

interface Props {
  children?: ReactNode;
  loading?: boolean;
}

const BasicLayout: React.FC<Props> = ({ children, loading = false }: Props) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { hasSubscription } = useAuth();

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"row"}
        width={"100%"}
        height={"100vh"}
        overflow={"hidden"}
      >
        <SimpleSideNav />
        <LoadingOverlay loading={loading} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `100%` },
            overflowX: "hidden",
            overflowY: "auto",
            height: "100%",

            display: "flex",
            flexDirection: "column",
            margin: `${isSmallScreen ? 0 : "0px 20px 20px 20px"}`,
          }}
        >
          <Header />
          {!hasSubscription ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4">No subscription</Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" href="/plans">
                  Go to plans
                </Button>
              </Grid>
            </Grid>
          ) : (
            children
          )}
        </Box>
      </Box>
    </>
  );
};

export default BasicLayout;
