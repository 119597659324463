import React from 'react';
import { Routes, Route, Link, useLocation, Navigate } from 'react-router-dom';
import { Box, Tabs, Tab, Typography, Grid, IconButton, Alert, Button } from '@mui/material';
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import BasicLayout from "components/Layouts/BasicLayout";
import GenerateTab from './GenerateTab';
import ArticleTab from './ArticleTab';
import SettingsTab from './SettingsTab';
import MediaTab from './MediaTab';
import LoadingOverlay from "components/LoadingOverlay";
import { NewsProvider, useNews } from "./NewsContext";
import { StatusEnum } from 'types/events.d';

const NewsContent: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentTab = location.pathname.split('/').pop() || 'article';
  const { loading, error, saveNews } = useNews();

  const tabPaths = {
    article: 'article',
    generate: 'generate',
    settings: 'settings',
    media: 'media'
  };

  const handleSave = (data?: Record<string, any>) => async () => {
    await saveNews(data);
    navigate("/news");
  };

  return (
    <BasicLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Box display={"flex"} alignItems={"center"}>
            <IconButton onClick={() => navigate("/news")}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="h4">News</Typography>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', gap: 2, ml: 4 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSave()}
                disabled={loading}
              >
                Save
              </Button>
              <Button
                variant="contained"
                onClick={handleSave({ status: StatusEnum.Published })}
                disabled={loading}
              >
                Publish
              </Button>
            </Box>
          </Box>
        </Grid>

        {error && <Alert severity="error">{error}</Alert>}

        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
            <Tabs value={tabPaths[currentTab] || 'article'}>
              <Tab
                label="Article"
                value="article"
                component={Link}
                to={tabPaths.article}
              />
              <Tab
                label="Generate"
                value="generate"
                component={Link}
                to={tabPaths.generate}
              />
              <Tab
                label="Media"
                value="media"
                component={Link}
                to={tabPaths.media}
              />
              <Tab
                label="Settings"
                value="settings"
                component={Link}
                to={tabPaths.settings}
              />
            </Tabs>
          </Box>

          <Routes>
            <Route path="generate" element={<GenerateTab />} />
            <Route path="article" element={<ArticleTab />} />
            <Route path="media" element={<MediaTab />} />
            <Route path="settings" element={<SettingsTab />} />
            <Route path="/" element={<Navigate to="article" replace />} />
          </Routes>
        </Grid>
      </Grid>
      <LoadingOverlay loading={loading} />
    </BasicLayout>
  );
};

const News: React.FC = () => {
  return (
    <NewsProvider>
      <NewsContent />
    </NewsProvider>
  );
};

export default News;
