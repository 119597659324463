import { API_HOST } from "../config";
import axios from "axios";
import TokenHandler from "./base";
import { IService, IPaginationModal } from "types/App";
import { IOrganization } from "types/organizations";

async function fetchAll(paginationModel: IPaginationModal = {}) {
  const response = await axios.get(`${API_HOST}/organizations`, {
    headers: TokenHandler.getHeaders(),
    params: {
      ...paginationModel,
      memberOnly: true
    }
  });
  return response.data;
}

async function fetch(id: string) {
  const response = await axios.get(`${API_HOST}/organizations/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function create(data: Partial<IOrganization>) {
  const response = await axios.post(`${API_HOST}/organizations`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function update(id: string, data: Partial<IOrganization>) {
  const response = await axios.put(`${API_HOST}/organizations/${id}`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function remove(id: string) {
  const response = await axios.delete(`${API_HOST}/organizations/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function upload(file: File) {
  const formData = new FormData();
  formData.append('file', file);

  const response = await axios.post(`${API_HOST}/organizations/upload`, formData, {
    headers: {
      ...TokenHandler.getHeaders(),
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

const organizationsService: IService = {
  fetch,
  fetchAll,
  create,
  update,
  remove,
  upload,
};

export default organizationsService;
