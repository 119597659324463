import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Box, Typography } from '@mui/material';
import stripeService from '../../services/stripe';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context/Auth';
import Logo from "assets/images/logos/logo_blue.png";
interface Props {
  priceId: string;
  userId: string;
  amount: number;
  currency: string;
  onSuccess: () => void;
}

const StripeCheckoutForm = ({ priceId, userId, amount, currency, onSuccess }: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { refreshUser } = useAuth();
  const [error, setError] = React.useState<string | null>(null);
  const [processing, setProcessing] = React.useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setProcessing(true);

    try {
      const { error: pmError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)!,
      });

      if (pmError) {
        throw new Error(pmError.message);
      }

      // 2. Create subscription with payment method
      await stripeService.createSubscription({
        priceId,
        userId,
        paymentMethodId: paymentMethod.id,
      });

      onSuccess();
    } catch (e: any) {
      console.log('e', e);
      setError(e?.response?.data?.error || e.message || 'An unexpected error occurred');
    } finally {
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          <img
            key="title"
            src={Logo}
            width={200}
            alt="CityView CMS Logo"
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          <Typography variant="body2">Subscribe to CityView CMS</Typography>
        </Box>
        <CardElement
          options={{
            disableLink: true,
            style: {
              base: {
                fontSize: '16px',
              },
            },
          }}
        />
      </Box>
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      <Button
        type="submit"
        variant="contained"
        fullWidth
        disabled={!stripe || processing}
      >
        {processing ? 'Processing...' : 'Subscribe'}
      </Button>

      <Typography variant="caption" color="textSecondary" sx={{ mt: 2, display: 'block', textAlign: 'center' }}>
        By subscribing, you authorize us to charge your card for this payment and future payments in accordance with our terms. You can cancel your subscription at any time.
      </Typography>
    </form>
  );
};

export default StripeCheckoutForm;