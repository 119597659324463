import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Tabs, Tab } from "@mui/material";

const LocationNav: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  const getTabValue = () => {
    const path = location.pathname;
    if (path.endsWith("/events")) return 1;
    if (path.endsWith("/data-sources")) return 2;
    if (path.endsWith("/media")) return 3;
    if (path.endsWith("/admin")) return 4;
    return 0;
  };

  return (
    <Tabs value={getTabValue()}>
      <Tab component={Link} to={`/locations/${id}`} label="Details" />
      <Tab component={Link} to={`/locations/${id}/events`} label="Events" />
      <Tab component={Link} to={`/locations/${id}/data-sources`} label="Data Sources" />
      <Tab component={Link} to={`/locations/${id}/media`} label="Media" />
      <Tab component={Link} to={`/locations/${id}/admin`} label="Admin" />
    </Tabs>
  );
};

export default LocationNav; 