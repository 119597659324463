import React from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import { Grid, IconButton, Typography, Button, Box } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import BasicLayout from "components/Layouts/BasicLayout";
import LocationDetails from "./LocationDetails";
import LocationEvents from "./LocationEvents";
import LocationDataSources from "./LocationDataSources";
import LocationMedia from "./LocationMedia";
import LocationAdmin from "./LocationAdmin";
import LocationNav from "./LocationNav";
import { LocationProvider, useLocation } from "./LocationContext";
import locationsService from "services/locations";
import { useAuth } from "context/Auth";

// Create a new component for the router content
const LocationRouterContent: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { location, loading } = useLocation();
  const { user } = useAuth();

  const handleSave = async () => {
    try {
      const { events, ...restLocation } = location;
      const newLocation = {
        ...restLocation,
        updatedBy: user?.uid,
        updatedAt: new Date().toISOString(),
        status: "published",
        source: "admin",
        formattedAddress: `${location.address1}, ${location.city}, ${location.state} ${location.zip}`,
      };

      if (!newLocation.name || !newLocation.defaultImage || !newLocation.placeId) {
        throw new Error("Please fill in all required fields");
      }

      if (id === "create") {
        newLocation.createdBy = user;
        newLocation.createdAt = new Date().toISOString();
        await locationsService.create(newLocation);
      } else {
        newLocation.updatedBy = user;
        newLocation.updatedAt = new Date().toISOString();
        await locationsService.update(newLocation.id, newLocation);
      }
      navigate("/locations");
    } catch (error) {
      console.error("Error saving location:", error);
    }
  };

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => navigate("/locations")}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="h4">
              {location?.name ? `Location: ${location.name}` : "Location"}
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={loading}
            >
              Save
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <LocationNav />
        </Grid>
        <Grid item xs={12}>
          <Routes>
            <Route path="/" element={<LocationDetails />} />
            <Route path="events" element={<LocationEvents />} />
            <Route path="data-sources" element={<LocationDataSources />} />
            <Route path="media" element={<LocationMedia />} />
            <Route path="admin" element={<LocationAdmin />} />
          </Routes>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

// Main router component that provides the context
const LocationRouter: React.FC = () => {
  return (
    <LocationProvider>
      <LocationRouterContent />
    </LocationProvider>
  );
};

export default LocationRouter; 