import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate } from "react-router-dom";

// Context
import { useAuth } from "context/Auth";

// Components
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { IOrganization } from "types/organizations";
import Image from "components/Images/Image";
import LoadingOverlay from "components/LoadingOverlay";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Block as BlockIcon,
  CheckCircle as CheckCircleIcon
} from "@mui/icons-material";
import InviteUserModal from 'components/InviteUserModal/InviteUserModal';
import UserFormModal from 'components/UserForm/UserFormModal';

// Services
import organizationsService from "services/organizations";
import usersService from 'services/users';
import organizationInvitationsService from 'services/organizationInvitations';

// Types
import { IFile } from 'types/files.d';
import { IUser } from 'types/users';

const GeneralTab: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const { user } = useAuth();
  const [organization, setOrganization] = useState<IOrganization | undefined>(undefined);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isUserFormModalOpen, setIsUserFormModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [confirmationDialog, setConfirmationDialog] = useState<{
    open: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
  }>({
    open: false,
    title: '',
    message: '',
    onConfirm: () => { },
  });
  const [pendingInvitations, setPendingInvitations] = useState<any[]>([]);

  // Copy over the relevant functions from Organization.tsx
  const fetchOrganization = useCallback(async () => {
    setLoading(true);
    try {
      const organizationId = user?.currentOrganization?.id || user?.currentOrganization?._id || user?.currentOrganization?.organizationId;
      const response = await organizationsService.fetch(organizationId);
      console.log('response', response);
      setOrganization(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [user]);

  const handleSave = async () => {
    try {
      setLoading(true);
      if (!organization?.name) {
        throw new Error("Please fill in all required fields");
      }
      await organizationsService.update(organization.id, organization);
      navigate('/organization');
    } catch (error: any) {
      console.error("Error saving organization:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Copy other handler functions...
  const handleDeleteUser = async (userId: string) => {
    if (userId === user?.id) {
      alert("You cannot remove yourself from the organization");
      return;
    }

    setConfirmationDialog({
      open: true,
      title: 'Delete User',
      message: 'Are you sure you want to delete this user?',
      onConfirm: async () => {
        try {
          setLoading(true);
          await usersService.remove(userId);
          await fetchOrganization();
        } catch (error) {
          console.error("Error deleting user:", error);
        } finally {
          setLoading(false);
          setConfirmationDialog(prev => ({ ...prev, open: false }));
        }
      }
    });
  };

  const handleToggleUserStatus = async (userId: string, currentStatus: boolean) => {
    if (userId === user?.id) {
      alert("You cannot deactivate yourself");
      return;
    }

    setConfirmationDialog({
      open: true,
      title: currentStatus ? 'Deactivate User' : 'Activate User',
      message: `Are you sure you want to ${currentStatus ? 'deactivate' : 'activate'} this user?`,
      onConfirm: async () => {
        try {
          setLoading(true);
          await usersService.update(userId, { isActive: !currentStatus });
          await fetchOrganization();
        } catch (error) {
          console.error("Error updating user status:", error);
        } finally {
          setLoading(false);
          setConfirmationDialog(prev => ({ ...prev, open: false }));
        }
      }
    });
  };

  const handleOpenInviteModal = () => {
    setIsInviteModalOpen(true);
  };

  const handleCloseInviteModal = () => {
    setIsInviteModalOpen(false);
    fetchOrganization();
  };

  const handleOpenUserFormModal = (user: IUser) => {
    setSelectedUser(user);
    setIsUserFormModalOpen(true);
  };

  const handleCloseUserFormModal = () => {
    setSelectedUser(null);
    setIsUserFormModalOpen(false);
    fetchOrganization();
  };

  const fetchInvitations = useCallback(async () => {
    try {
      const invitations = await organizationInvitationsService.fetchPending();
      setPendingInvitations(invitations);
    } catch (error) {
      console.error("Error fetching invitations:", error);
    }
  }, []);

  useEffect(() => {
    if (user?.organization) {
      fetchOrganization();
      fetchInvitations();
    }
  }, [fetchOrganization, fetchInvitations, user]);

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: GridValueGetterParams) => (
        <Box>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleOpenUserFormModal(params.row)}
              size="medium"
              sx={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={params.row.isActive ? "Deactivate" : "Activate"}>
            <IconButton
              onClick={() => handleToggleUserStatus(params.row.id, params.row.isActive)}
              size="medium"
              sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', mx: 1 }}
            >
              {params.row.isActive ? <BlockIcon /> : <CheckCircleIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              onClick={() => handleDeleteUser(params.row.id)}
              size="medium"
              color="error"
              sx={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const users = useMemo(() => {
    const activeUsers = user?.currentOrganization?.users ?? [];
    const pendingUsers = pendingInvitations.map(invitation => ({
      id: invitation.id,
      name: 'Pending User',
      email: invitation.email,
      isActive: false,
      isPending: true
    }));

    console.log('activeUsers', activeUsers);
    console.log('pendingUsers', pendingUsers);

    // Merge lists, excluding pending invitations where the email already exists in active users
    const mergedUsers = [
      ...activeUsers,
      ...pendingUsers.filter(pending =>
        !activeUsers.some(active => active.email === pending.email)
      )
    ];

    return mergedUsers;
  }, [user?.organization?.users, pendingInvitations]);
  console.log('users', users);
  return (
    <Grid container spacing={3} >
      {error && <Alert severity="error">{error}</Alert>}
      <Grid item xs={12} md={12} marginBottom={4}>
        <Typography variant="h5" gutterBottom>Organization Details</Typography>
        <Grid container spacing={4} alignItems="flex-start">
          <Grid item xs={12} md={4}>
            <FormControl margin="normal" fullWidth style={{ height: '400px' }}>
              <Image
                onAccept={(image: IFile) => setOrganization({ ...organization, image })}
                src={organization?.image?.url}
                alt={organization?.name}
                bucketPath="cityview-cms/organizations"
                style={{ maxHeight: '100%', objectFit: 'contain' }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={8}>
            <FormControl fullWidth margin="normal">
              <FormLabel required htmlFor="org-name">Name</FormLabel>
              <TextField
                id="org-name"
                value={organization?.name || ""}
                onChange={(e) => setOrganization({ ...organization, name: e.target.value })}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="org-description">Description</FormLabel>
              <TextField
                id="org-description"
                multiline
                rows={4}
                value={organization?.description || ""}
                onChange={(e) => setOrganization({ ...organization, description: e.target.value })}
              />
            </FormControl>
            <FormControl fullWidth margin="normal" >
              <FormLabel htmlFor="org-api-key">API Key</FormLabel>
              <TextField
                id="apiKey"
                type="text"
                value={organization?.apiKey || ""}
                onChange={(e) => setOrganization({ ...organization, apiKey: e.target.value })}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>


      <Grid item xs={12} md={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h5">Users</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenInviteModal}
          >
            Invite User
          </Button>
        </Box>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={users || []}
            columns={columns}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </div>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={loading}
        >
          Save Organization
        </Button>
      </Grid>

      <InviteUserModal
        open={isInviteModalOpen}
        onClose={handleCloseInviteModal}
        organizationId={organization?.id || ''}
      />

      <Dialog open={isUserFormModalOpen} onClose={handleCloseUserFormModal}>
        <DialogTitle>{selectedUser ? 'Edit User' : 'Add User'}</DialogTitle>
        <UserFormModal
          open={isUserFormModalOpen}
          onClose={handleCloseUserFormModal}
          organizationId={organization?.id || ''}
          selectedUser={selectedUser}
        />
      </Dialog>

      <Dialog
        open={confirmationDialog.open}
        onClose={() => setConfirmationDialog(prev => ({ ...prev, open: false }))}
      >
        <DialogTitle>{confirmationDialog.title}</DialogTitle>
        <Box sx={{ p: 2 }}>
          <Typography>{confirmationDialog.message}</Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button onClick={() => setConfirmationDialog(prev => ({ ...prev, open: false }))}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={confirmationDialog.onConfirm}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Dialog>

      <LoadingOverlay loading={loading} />
    </Grid>
  );
};

export default GeneralTab;
