import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Icon,
  Paper,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import eventService from '../../services/events';
import ApproveSwipper from '../ApproveSwipper';
import { useAuth } from "context/Auth";

function DraftEventsWidget() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [events, setEvents] = useState([]);

  const { user } = useAuth();

  const fetchEvents = useCallback(async () => {
    try {
      setLoading(true)
      const events = await eventService.fetchAll({
        query: [
          { field: "status", operator: 'eq', value: "pending" },
        ],
        pageSize: 1000,
      });
      setEvents(events.data);
    } catch (error) {
      console.error("Error fetching events", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);


  const valid = (event: any) => {
    return !!event?.title && !!event?.location && !!event?.tags?.length;
  }

  const handleClose = () => {
    fetchEvents();
  }

  const handleApproveEvent = async (id: string) => {
    try {
      await eventService.update(id, {
        updatedBy: user,
        updatedDate: new Date(),
        status: "published"
      });
      fetchEvents();
    } catch (error) {
      console.error("Error approving event", error);
      setError(error);
    }
  }

  const handleDeletePendingEvent = async (id: string) => {
    try {
      await eventService.remove(id);
      fetchEvents();
    } catch (error) {
      console.error("Error deleting event", error);
      setError(error);
    }
  }

  return (
    <Paper style={{ padding: 20 }}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Draft Events</Typography>
        {!!events?.length && <ApproveSwipper
          events={events}
          onOpen={fetchEvents}
          onClose={handleClose}
        />}
      </Box>
      <DataGrid
        slots={{
          noRowsOverlay: () => <Box py={2}><Typography textAlign={"center"} variant="body1">No Draft Events</Typography></Box>,
        }}
        checkboxSelection={false}
        style={{ height: 400 }}
        rows={events || []}
        getRowId={(row) => row._id}
        columns={[
          // Add a status row if all the event fields are filled out show a green checkmark else show a warning icon
          { field: "status", headerName: "", width: 20, renderCell: (params) => valid(params.row) ? <Icon color="success">check_circle</Icon> : <Icon color="warning">warning</Icon> },
          { field: "defaultImage", headerName: "", width: 50, renderCell: (params) => <img src={params.value?.url} style={{ width: 50 }} alt={params?.value} /> },
          { field: "title", headerName: "Title", flex: 1 },
          { field: "startDate", headerName: "Start", flex: 1, renderCell: (params) => new Date(params.value).toLocaleDateString() },
          { field: "location", headerName: "Location", flex: 1, renderCell: (params) => params?.value?.name || "No Location" },
          { field: "tags", headerName: "Tags", flex: 1, renderCell: (params) => params?.value?.map((t: any) => t.title).join(", ") || "No Tags" },
          {
            field: "actions",
            headerName: "",
            width: 150,
            renderCell: (params) => (
              <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton disabled={!valid(params.row)} onClick={() => handleApproveEvent(params.id as string)}>
                  <Icon style={{ color: !valid(params.row) ? "#ddd" : "green" }}>checkmark</Icon>
                </IconButton>
                <IconButton color="secondary" component={Link} to={`/events/${params.id}`}>
                  <Icon style={{ color: 'navy' }}>edit</Icon>
                </IconButton>
                <IconButton color="secondary" onClick={() => handleDeletePendingEvent(params.id as string)}>
                  <Icon color="error">delete</Icon>
                </IconButton>
              </Box>
            ),
          },
        ]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        disableRowSelectionOnClick
      />
    </Paper>
  )
}

export default DraftEventsWidget