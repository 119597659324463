import { useMediaGallery } from "./MediaGalleryContext";
import AddFolderDialog from "./AddFolderDialog";
import FolderIcon from "@mui/icons-material/FolderOutlined";

const FolderList = () => {

  const { folders, viewFolder, setViewFolder, setSelectedFiles, totalFiles } = useMediaGallery();

  return (
    <div className="flex flex-col justify-between h-full w-[300px] bg-white p-5 gap-5 overflow-hidden rounded-md shadow">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row items-center gap-2">
          <FolderIcon fontSize="medium" />
          <h4 className="text-2xl font-medium">Folders</h4>
        </div>
        <div className="flex flex-col gap-2 pl-1">
          <span
            onClick={() => {
              setViewFolder(null);
              setSelectedFiles([]);
            }}
            className={`text-xl font-normal cursor-pointer ${!viewFolder ? 'text-[#0066CC]' : 'text-[#888]'
              }`}
          >
            All Media ({totalFiles || 0})
          </span>
          {folders?.sort((a, b) => a.name.localeCompare(b.name)).map((folder) => (
            <span
              onClick={() => {
                setViewFolder(folder);
                setSelectedFiles([]);
              }}
              className={`text-lg pl-2 font-normal cursor-pointer ${viewFolder?.id === folder?.id ? 'text-[#0066CC]' : 'text-[#888]'
                }`}
              key={folder.id}
            >
              {folder.name} ({folder.fileCount || 0})
            </span>
          ))}
        </div>
      </div>
      <AddFolderDialog />
    </div>
  );
};

export default FolderList;