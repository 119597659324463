import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/Auth";
import stripeService from 'services/stripe';
import LoadingOverlay from "components/LoadingOverlay";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface SubscriptionData {
  id: string;
  status: string;
  current_period_end: string;
  plan: {
    name: string;
    amount: number;
  };
  items: any;
}

interface PlanData {
  product: {
    id: string;
    name: string;
    description?: string;
  };
  prices: {
    id: string;
    unit_amount: number;
  }[];
}

const SubscriptionTab: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const { user, refreshUser } = useAuth();
  const [subscription, setSubscription] = useState<SubscriptionData | null>(null);
  const [plans, setPlans] = useState<PlanData[]>([]);
  const [showPlansModal, setShowPlansModal] = useState(false);

  // Copy subscription-related functions from Organization.tsx
  const fetchSubscription = useCallback(async () => {
    try {
      console.log('user', user);
      if (!user?.currentOrganization?.subscription?.id) {
        return;
      }
      const response = await stripeService.getCurrentSubscription();
      setSubscription(response);
    } catch (error) {
      console.error('Error fetching subscription:', error);
    }
  }, [user]);

  const fetchPlans = useCallback(async () => {
    try {
      const response = await stripeService.getPlans();
      setPlans(response);
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  }, []);

  useEffect(() => {
    if (user?.currentOrganization) {
      fetchSubscription();
      fetchPlans();
    }
  }, [fetchSubscription, fetchPlans, user]);

  // Copy other subscription-related handlers...
  const handleCancelSubscription = async () => {
    if (!subscription) return;

    if (window.confirm('Are you sure you want to cancel your subscription?')) {
      try {
        setLoading(true);
        await stripeService.cancelSubscription(subscription.id);
        await fetchSubscription();
        await refreshUser();
      } catch (error) {
        console.error('Error canceling subscription:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePlanChange = async (newPlan: PlanData) => {
    if (!subscription) return;

    try {
      setLoading(true);
      const currentItem = subscription.items.data[0];

      // Update the existing subscription item with the new price
      // We keep the same item ID but change its price, rather than creating a new item
      await stripeService.updateSubscription(subscription.id, {
        proration_behavior: 'always_invoice',
        items: [{
          id: currentItem.id,  // Reference to the existing subscription item
          price: newPlan.prices[0].id  // New price to switch to
        }]
      });

      await fetchSubscription();
      await refreshUser();
      setShowPlansModal(false);
    } catch (error) {
      console.error('Error updating subscription:', error);
      setError('Failed to update subscription');
    } finally {
      setLoading(false);
    }
  };
  const PlansModal = () => (
    <Dialog
      open={showPlansModal}
      onClose={() => setShowPlansModal(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Change Subscription Plan</DialogTitle>
      <DialogContent>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          {[...plans].sort((a, b) => a.prices[0].unit_amount - b.prices[0].unit_amount).map((plan) => (
            <Grid item xs={12} sm={6} key={plan.product.id}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flex: 1,
                  bgcolor: subscription?.plan?.name === plan.product.name ? 'primary.light' : 'background.paper'
                }}
              >
                <CardContent>
                  <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                    <Box>
                      <Typography variant="h6" component="div" gutterBottom>
                        {plan.product.name}
                      </Typography>
                      <Typography variant="h4" color="primary" gutterBottom>
                        ${(plan.prices[0].unit_amount / 100).toFixed(2)}/month
                      </Typography>
                      {plan.product.description && (
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                          {plan.product.description}
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Button
                        className="items-end"
                        variant="contained"
                        fullWidth
                        disabled={subscription?.plan?.name === plan.product.name}
                        onClick={() => handlePlanChange(plan)}
                      >
                        {subscription?.plan?.name === plan.product.name ? 'Current Plan' : 'Select Plan'}
                      </Button>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );

  const formatDate = (timestamp: string) => {
    return new Date(parseInt(timestamp) * 1000).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount / 100);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Subscription Details
        </Typography>
      </Grid>
      {subscription ? (
        <>
          <Grid item xs={12}>
            <Paper sx={{ p: 3, mb: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Subscription ID
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {subscription.id}
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    Status
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {subscription.status.charAt(0).toUpperCase() + subscription.status.slice(1)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Current Period Ends
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {formatDate(subscription.current_period_end)}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Plan</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscription.items.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>
                        <Typography variant="body1">
                          {item.plan.nickname || item.plan.id}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          Billed {item.plan.interval}ly
                        </Typography>
                      </TableCell>
                      <TableCell align="right">{item.quantity}</TableCell>
                      <TableCell align="right">
                        {formatCurrency(item.plan.amount)}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          size="small"
                          onClick={() => setShowPlansModal(true)}
                          title="Change Plan"
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={handleCancelSubscription}
                          title="Cancel Subscription"
                          color="error"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <Typography variant="subtitle1" sx={{ mr: 2 }}>
                Total Monthly:
              </Typography>
              <Typography variant="h6">
                {formatCurrency(
                  subscription.items.reduce(
                    (total, item) => total + item.plan.amount * item.quantity,
                    0
                  )
                )}
              </Typography>
            </Box>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography gutterBottom>
            No active subscription found.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/plans')}
          >
            View Plans
          </Button>
        </Grid>
      )}
      <PlansModal />
      <LoadingOverlay loading={loading} />
    </Grid>
  );
};

export default SubscriptionTab; 