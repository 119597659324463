import { API_HOST } from "../config";
import axios from "axios";
import TokenHandler from "./base";
import { IFile } from "types/files.d";
import { IUser as User } from "types/users.d";
import { CogIcon } from "@heroicons/react/20/solid";

async function fetchAll(paginationModel: any = {}) {
  const filteredPagination = { ...paginationModel };
  delete filteredPagination.query;
  delete filteredPagination.sort;

  const url = new URL(`${API_HOST}/files`);
  url.search = new URLSearchParams(filteredPagination).toString();

  const response = await axios.get(url.toString(), {
    params: { query: paginationModel.query, sort: paginationModel.sort },
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

export interface UploadFile extends File {
  title?: string;
  createdBy: User;
  createdDate?: string;
  updatedBy: User;
  updatedDate?: string;
  folders?: string[];
}

export const upload = async (file: File, folderId?: string): Promise<IFile> => {

  const formData = new FormData();
  formData.append("file", file);
  formData.append("bucket", "cityview-cms/files");
  console.log("folderId", folderId)
  if (folderId) {
    formData.append("folder", folderId);
  }
  const token = TokenHandler.getToken();
  try {
    const url = `${API_HOST}/files/upload`;
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data && response.data.url) {
      return response.data;
    } else {
      throw new Error("File upload failed");
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

async function fetch(id: string) {
  const response = await axios.get(`${API_HOST}/files/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function createFromUrl(data: any) {
  const response = await axios.post(`${API_HOST}/files/createFromUrl`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function create(data: any) {
  const response = await axios.post(`${API_HOST}/files/upload`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function update(id: string, data: any) {
  const response = await axios.put(`${API_HOST}/files/${id}`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function remove(id: string) {
  const response = await axios.delete(`${API_HOST}/files/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

export const generate = async ({ prompt, path = "/files/generate", size }: { prompt: string; path?: string; size?: string }) => {
  try {
    const response = await axios.post(
      `${API_HOST}/openai/generate`,
      { prompt, path, size },
      {
        headers: TokenHandler.getHeaders(),
      }
    );
    return response;
  } catch (err) {
    console.log("Error", err);
  }
};

const filesService = {
  fetch,
  fetchAll,
  create,
  update,
  remove,
  upload,
  generate,
  createFromUrl,
};

export default filesService;

