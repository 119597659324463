import React, { createContext, useState, useMemo, ReactNode } from "react";

export const GlobalContext = createContext<{
  menuIsOpen: boolean;
  menuSectionExpanded: string | null;
  setMenuSectionExpanded: (value: string | null) => void;
  setMenuIsOpen: (value: boolean) => void;
}>(null);

export const GlobalContextProvider = ({ children }: { children: ReactNode }) => {

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(true);
  const [menuSectionExpanded, setMenuSectionExpanded] = useState<string | null>(null);

  //if any of the fetches are loading, this will be true
  const value = useMemo(() => {
    return {
      menuIsOpen,
      menuSectionExpanded,
      setMenuIsOpen,
      setMenuSectionExpanded,
    };
  }, [
    menuIsOpen,
    menuSectionExpanded,
  ]);

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export const useGlobalContext = () => React.useContext(GlobalContext);
