import { useCallback, useEffect, useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components

import Grid from "@mui/material/Grid";

import BasicLayout from "components/Layouts/BasicLayout";
import {
  Autocomplete,
  Button,
  createFilterOptions,
  FormControl,
  FormLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import LoadingOverlay from "components/LoadingOverlay";
import { ChevronLeft } from "@mui/icons-material";
import usersService from "services/users";
import { useAuth } from "context/Auth";
import { IUser } from "types/users";
import { useDropzone } from "react-dropzone";
import { upload } from "services/files";
import { IRole } from "types/roles";
import rolesService from "services/roles";
import ResetPasswordModal from "components/ResetPasswordModal";
import Image from "components/Images/Image";
import { IFile } from "types/files.d";

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const [userProfile, setUserProfile] = useState<IUser | undefined>(undefined);
  const [roles, setRoles] = useState<IRole[]>(undefined);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const _roles: { data: IRole[] } = await rolesService.fetchAll();
        setRoles(_roles.data);
      } catch (e) {
        console.error("Error fetching event:", e);
      }
    };

    if (!roles) {
      fetchRoles();
    }
  }, [roles]);

  const filterOptions = createFilterOptions({
    ignoreCase: true,
  });

  const renderRolesOptionLabel = (option: any) => {
    const role = roles?.find((role: any) => role.id === option);
    if (role) {
      return role?.title;
    }
    return option || "";
  };

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      if (acceptedFiles.length === 0) return;
      for (let file of acceptedFiles) {
        const image = await upload(file);
        setUserProfile({
          ...userProfile,
          imageUrl: image.url,
        });
      }
    },
    [userProfile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const fetchUser = useCallback(async () => {
    setLoading(true);
    try {
      const response = await usersService.fetch(user?.id);
      setUserProfile(response);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [user?.id]);

  const handleSave = async () => {
    try {
      setLoading(true);

      if (!userProfile?.name || !userProfile?.email) {
        throw new Error("Please fill in all fields");
      }
      await usersService.update(userProfile.id, userProfile);
      navigate('/');
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userProfile?.id) {
      fetchUser();
    }
  }, [fetchUser, userProfile]);

  return (
    <BasicLayout>
      <Grid container spacing={10}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">Profile</Typography>
        </Grid>
        <Grid container item direction={"row"} xs={12} spacing={2}>
          <Grid container xs={4} direction={"column"} gap={"10px"}>
            <Image
              onAccept={(image: IFile) => setUserProfile({ ...userProfile, imageUrl: image.url })}
              src={userProfile?.imageUrl}
              alt={userProfile?.name}
              bucketPath="cityview-cms/users"
              folderPath="Users Media"
            />
          </Grid>
          <Grid
            container
            item
            direction={"column"}
            xs={8}
            gap={"10px"}
            paddingTop={"0px !important"}
          >
            <Grid item>
              <FormControl fullWidth>
                <FormLabel required htmlFor="email">
                  Email
                </FormLabel>
                <TextField id="email" value={userProfile?.email || ""} disabled />
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl fullWidth>
                <FormLabel required htmlFor="name">
                  Name
                </FormLabel>
                <TextField
                  id="name"
                  value={userProfile?.name || ""}
                  onChange={(e) => setUserProfile({ ...userProfile, name: e.target.value })}
                />
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl fullWidth>
                <FormLabel required htmlFor="phone-number">
                  Phone Number
                </FormLabel>
                <TextField
                  id="phone-number"
                  value={userProfile?.phoneNumber || ""}
                  onChange={(e) => setUserProfile({ ...userProfile, phoneNumber: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <FormLabel htmlFor="tags">Roles</FormLabel>
                <Autocomplete
                  multiple
                  value={userProfile?.roles || []}
                  onChange={(_e, value) => {
                    setUserProfile({
                      ...userProfile,
                      roles: value,
                      // tags: tags.filter((tag: any) => value.includes(tag.id)),
                    });
                  }}
                  filterOptions={filterOptions}
                  options={roles ? roles?.map((role: any) => role.id) : []}
                  getOptionLabel={renderRolesOptionLabel}
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>
        {/*<Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2}>
            {id && id !== "create" && (
              <Grid item xs={12}>
                <Typography variant="h4" color="alert">
                  Danger Zone
                </Typography>
                <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid> */}
        <Grid item xs={12}>
          <Button disabled={loading} variant="contained" color="error" onClick={() => setOpen(!open)}>
            Reset Password
          </Button>
        </Grid>
        <ResetPasswordModal open={open} setOpen={setOpen} />
        <LoadingOverlay loading={loading} />
      </Grid>
    </BasicLayout>
  );
};

export default Profile;
