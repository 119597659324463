import { API_HOST, API_VERSION } from "../config";
import axios from "axios";
import TokenHandler from "./base";
import { IPaginationModal } from "types/App";

const service = 'users';

async function fetchAll(paginationModel: IPaginationModal = {}) {
  const filteredPagination = { ...paginationModel };
  delete filteredPagination.query;
  delete filteredPagination.sort;

  const url = new URL(`${API_HOST}/users`);
  url.search = new URLSearchParams(filteredPagination).toString();

  const params = {
    ...(paginationModel?.query && { query: paginationModel.query }),
    ...(paginationModel?.sort && { sort: paginationModel.sort }),
    includeDrafts: true,
  };
  const response = await axios.get(url.toString(), {
    params,
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function fetch(id: string) {
  const response = await axios.get(`${API_HOST}/${service}/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function create(data: any) {
  const response = await axios.post(`${API_HOST}/${service}`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function update(id: string, data: any) {
  const response = await axios.put(`${API_HOST}/${service}/${id}`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function remove(id: string) {
  const response = await axios.delete(`${API_HOST}/${service}/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function generate(data: { prompt: string }) {
  const response = await axios.post(`${API_HOST}/${service}/generate`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function switchOrganization(organizationId: string) {
  const response = await axios.post(`${API_HOST}/${service}/switch-organization`, {
    organizationId,
  }, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

const usersService = {
  fetch,
  fetchAll,
  create,
  update,
  remove,
  generate,
  switchOrganization,
};

export default usersService;
