import { FormControlLabel, Checkbox } from '@mui/material';
import { FilterComponentProps } from './types';

export const CheckboxFilter = ({ filterOption, value, onChange }: FilterComponentProps) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value || false}
          onChange={(e) => {
            onChange(e.target.checked ? {
              field: filterOption.name,
              value: true,
              operator: filterOption.operator || 'eq'
            } : null);
          }}
        />
      }
      label={filterOption.label}
    />
  );
}; 