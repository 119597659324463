import React, { useEffect, useState, useRef } from "react";
import QRCodeComponent from "react-qr-code";
import { toPng } from 'html-to-image';
import { useNavigate, useParams } from "react-router-dom";
import debounce from "lodash/debounce";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import { ChevronLeft, ContentCopy, Download } from "@mui/icons-material";

import { useAuth } from "context/Auth";

import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";


import invitationsService from "services/invitations";
import qrcodesService from "services/qrcodes";
import membersService from "services/members";
import eventsService from "services/events";


import { IInvitation } from "types/invitations";
import { IMember } from "types/members.d";
import { IEvent } from "types/events.d";
import { IQRCode, QRCodeType } from "types/qrcodes.d";


const Invitation: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [invitation, setInvitation] = useState<IInvitation | undefined>(undefined);
  const [memberOptions, setMemberOptions] = useState<IMember[]>([]);
  const [eventOptions, setEventOptions] = useState<IEvent[]>([]);
  const [qrCode, setQRCode] = useState<IQRCode | null>(null);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();

  const qrCodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchInvitation = async () => {
      try {
        const invitation = await invitationsService.fetch(id);
        setInvitation(invitation);
        if (invitation.qrCode) {
          setQRCode(invitation.qrCode);
        }
      } catch (e) {
        console.error("Error fetching invitation:", e);
      }
    };
    if (id && id !== "create") {
      fetchInvitation();
    }
    try {
      const fetchEvents = async () => {
        const { data } = await eventsService.fetchAll();
        setEventOptions(data);
      }
      const fetchMembers = async () => {
        const { data } = await membersService.fetchAll();
        setMemberOptions(data);
      }
      fetchEvents();
      fetchMembers();
    } catch (error) {
      console.error("Error fetching invitations:", error);
    }
  }, [id]);

  const fetchMembers = debounce(async (search: string) => {
    try {
      const { data } = await membersService.fetchAll({
        query: [
          {
            field: "name",
            value: search,
            operator: "search",
          },
        ],
      });
      setMemberOptions(data);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  }, 300);

  const fetchEvents = debounce(async (search: string) => {
    try {
      const { data } = await eventsService.fetchAll({
        query: [
          {
            field: "title",
            value: search,
            operator: "search",
          },
        ],
      });
      setEventOptions(data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  }, 300);

  const handleSave = async () => {
    try {
      setLoading(true);
      const newInvitation: IInvitation = {
        ...invitation,
        updatedBy: user?.uid,
        updatedDate: new Date(),
      };

      if (!newInvitation.member || !newInvitation.event) {
        throw new Error("Please fill in all required fields");
      }

      if (id === "create") {
        newInvitation.createdBy = user?.uid;
        newInvitation.createdDate = new Date();
        // Create QR code
        const qrCodeData: Omit<IQRCode, "id"> = {
          name: `Invitation for ${newInvitation.member.name} to ${newInvitation.event.title}`,
          label: `Invitation for ${newInvitation.member.name} to ${newInvitation.event.title}`,
          code: `INV-${Date.now()}`,
          url: `${window.location.origin}/rsvp/${newInvitation.id}`,
          type: QRCodeType.SYSTEM,  // Set the type to SYSTEM
          createdBy: user?.uid,
          createdAt: new Date().toISOString(),
        };
        const createdQRCode = await qrcodesService.create(qrCodeData);
        newInvitation.qrCode = createdQRCode;
        setQRCode(createdQRCode);

        await invitationsService.create(newInvitation);
      } else {
        await invitationsService.update(newInvitation.id, newInvitation);
      }
      navigate("/invitations");
    } catch (error: any) {
      console.error("Error saving invitation:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await invitationsService.remove(id);
      navigate("/invitations");
    } catch (error) {
      console.error("Error deleting invitation:", error);
    }
  };

  const copyToClipboard = async () => {
    if (qrCodeRef.current) {
      try {
        const dataUrl = await toPng(qrCodeRef.current);
        const blob = await fetch(dataUrl).then(res => res.blob());
        await navigator.clipboard.write([
          new ClipboardItem({ 'image/png': blob })
        ]);
        // You might want to show a success message here
      } catch (err) {
        console.error('Failed to copy QR code:', err);
        // You might want to show an error message here
      }
    }
  };

  const downloadQRCode = async () => {
    if (qrCodeRef.current) {
      try {
        const dataUrl = await toPng(qrCodeRef.current);
        const link = document.createElement('a');
        link.download = `invitation_qr_${invitation?.id || 'new'}.png`;
        link.href = dataUrl;
        link.click();
      } catch (err) {
        console.error('Failed to download QR code:', err);
        // You might want to show an error message here
      }
    }
  };

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">Invitation</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="member">Member</FormLabel>
                <Autocomplete
                  id="member"
                  options={memberOptions}
                  getOptionLabel={(option) => option.name + " - " + option.email}
                  value={invitation?.member || null}
                  onChange={(_, newValue) => setInvitation({ ...invitation, member: newValue })}
                  onInputChange={(_, newInputValue) => fetchMembers(newInputValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="event">Event</FormLabel>
                <Autocomplete
                  id="event"
                  options={eventOptions}
                  getOptionLabel={(option) => option.title}
                  value={invitation?.event || null}
                  onChange={(_, newValue) => setInvitation({ ...invitation, event: newValue })}
                  onInputChange={(_, newInputValue) => fetchEvents(newInputValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {qrCode && (
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="h6">Invitation QR Code</Typography>
            <div ref={qrCodeRef}>
              <QRCodeComponent value={qrCode.url} />
            </div>
            <Grid container spacing={2} style={{ marginTop: '16px' }}>
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<ContentCopy />}
                  onClick={copyToClipboard}
                >
                  Copy to Clipboard
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<Download />}
                  onClick={downloadQRCode}
                >
                  Download as PNG
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>
        {id && id !== "create" && (
          <Grid item xs={12}>
            <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
              Delete
            </Button>
          </Grid>
        )}
        <LoadingOverlay loading={loading} />
      </Grid>
    </BasicLayout>
  );
};

export default Invitation;