import { FileRejection, useDropzone } from "react-dropzone";
import { IFile } from "types/files.d";
import { useMediaGallery } from "./MediaGalleryContext";

import pdfIcon from "../../assets/images/icons/file-pdf.png";
import docIcon from "../../assets/images/icons/file-doc.png";
import xlsIcon from "../../assets/images/icons/file-xls.png";
import pptIcon from "../../assets/images/icons/file-ppt.png";
import txtIcon from "../../assets/images/icons/file-txt.png";
import htmlIcon from "../../assets/images/icons/file-html.png";

import filesService from "services/files";
import LoadingOverlay from "components/LoadingOverlay";

const Gallery = () => {

  const {
    loading,
    getFiles,
    gridData,
    selectedFiles,
    setSelectedFiles,
    setError,
    setLoading,
    selectionMethod,
    viewFolder,
  } = useMediaGallery();

  const renderFileIcon = (file: IFile) => {
    switch (file.type) {
      case "image/jpeg":
      case "image/png":
      case "image/gif":
      case "image/bmp":
      case "image/svg+xml":
        return <img src={file.url} alt="grid item" style={{ objectFit: "cover", width: "100%", height: "100%", maxWidth: "100%", maxHeight: "200px" }} />;
      case "video/mp4":
      case "video/avi":
      case "video/mov":
      case "video/wmv":
      case "video/flv":
        return <video src={file.url} style={{ objectFit: "cover", width: "100%", height: "100%", maxWidth: "100%", maxHeight: "100%" }} />;
      case "application/pdf":
        return <img style={{ width: "70%" }} src={pdfIcon} alt="pdf click" />;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/msword":
        return <img style={{ width: "70%" }} src={docIcon} alt="doc click" />;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "application/vnd.ms-excel":
        return <img style={{ width: "70%" }} src={xlsIcon} alt="xls click" />;
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      case "application/vnd.ms-powerpoint":
        return <img style={{ width: "70%" }} src={pptIcon} alt="ppt click" />;
      case "text/plain":
        return <img style={{ width: "70%" }} src={txtIcon} alt="txt click" />;
      case "text/html":
        return <img style={{ width: "70%" }} src={htmlIcon} alt="html click" />;
      default:
        return null;
    }
  };

  const onDrop = async (acceptedFiles: File[]) => {
    console.log(acceptedFiles);
    try {
      setLoading(true);
      if (acceptedFiles.length === 0) return;
      for (const file of acceptedFiles) {
        await filesService.upload(file, viewFolder?.id);
      }
      getFiles();
    } catch (error) {
      setError(error);
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };

  const maxFileSize = 500000000; //500MB
  const fileSizeValidator = (file: File) => {
    if (file.size > maxFileSize) {
      setError(`File size is larger than ${maxFileSize / 1000000} MB`);
      return {
        code: "filesize-too-large",
        message: `File size is larger than ${maxFileSize / 1000000} MB`,
      };
    }

    return null;
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    fileRejections.map((rejection) =>
      rejection.errors.map((error) => console.error(error.message))
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "application/msword": [".doc"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "application/vnd.ms-powerpoint": [".ppt"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
      "text/plain": [".txt"],
      "application/pdf": [".pdf"],
      "text/html": [".html"],
      "image/*": [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".svg"],
      "video/*": [".mp4", ".avi", ".mov", ".wmv", ".flv", ".mkv", ".webm"],
    },
    validator: fileSizeValidator,
  });

  const handleFileClick = (file: any) => {
    if (selectedFiles.some(f => f._id === file._id)) {
      setSelectedFiles(selectedFiles.filter(f => f._id !== file._id));
    } else if (selectionMethod === 'single') {
      setSelectedFiles([file]);
    } else {
      setSelectedFiles([...selectedFiles, file]);
    }
  };

  return (
    <div className="p-5 bg-white h-full w-full">
      <div
        className="flex flex-row flex-wrap gap-5 overflow-auto h-full"
        {...getRootProps({
          onClick: (event) => event.stopPropagation(),
        })}
      >
        <div
          className={`absolute inset-0 flex items-center justify-center 
            ${isDragActive ? 'bg-white/90 z-10' : 'transparent -z-10'}
            transition-all duration-200 ease-in-out`}
        >
          <input {...getInputProps()} />
          <p className="text-center">
            {isDragActive
              ? "Drop the files here..."
              : ""}
          </p>
          {loading && <LoadingOverlay loading={loading} />}
        </div>

        {gridData?.map((file) => (
          <div
            key={file.id}
            className="cursor-pointer"
            onClick={() => handleFileClick(file)}
          >
            <div className={`w-[200px] h-full text-xs p-5 pt-5 flex flex-col items-center justify-center rounded max-h-[330px] gap-5 overflow-hidden text-ellipsis
            ${selectedFiles.some(f => f._id === file._id) ? 'border-2 border-blue-500' : 'border border-gray-200'}`}>
              {renderFileIcon(file)}
              {file.type !== "image" && file.type !== "video" && (
                <span className="text-center">{file.name || "Filename not found.."}</span>
              )}
            </div>
          </div>
        ))
        }
      </div>
    </div>
  );
};

export default Gallery;
