import { IPaginationModal } from "types/App";
import { API_HOST } from "../config";
import axios from "axios";
import TokenHandler from "./base";

async function fetchAll(paginationModel?: IPaginationModal) {
  const filteredPagination = { ...paginationModel };
  delete filteredPagination.query;
  delete filteredPagination.sort;

  const url = new URL(`${API_HOST}/artists`);
  url.search = new URLSearchParams(filteredPagination).toString();

  const params = {
    ...(paginationModel?.query && { query: paginationModel.query }),
    ...(paginationModel?.sort && { sort: paginationModel.sort }),
  };
  const response = await axios.get(url.toString(), {
    params,
    headers: TokenHandler.getHeaders(),
  });

  return response.data;
}

async function fetch(id: string) {
  const response = await axios.get(`${API_HOST}/artists/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

const artistsService = {
  fetch,
  fetchAll,
};

export default artistsService; 