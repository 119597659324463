import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { IOrganization } from 'types/organizations';
import { useAuth } from 'context/Auth';

interface OrganizationSelectProps {
  value: IOrganization | null;
  onChange: (organization: IOrganization | null) => void;
}

const OrganizationSelect: React.FC<OrganizationSelectProps> = ({ value, onChange }) => {
  const { user } = useAuth();
  const organizations = user?.organizations || [];

  return (
    <FormControl sx={{ minWidth: 400 }}>
      <InputLabel id="organization-select-label">Select Organization</InputLabel>
      <Select
        labelId="organization-select-label"
        id="organization-select"
        value={value?.id || ''}
        label="Select Organization"
        onChange={(event) => {
          const selectedOrg = organizations.find(org => org.id === event.target.value) || null;
          onChange(selectedOrg);
        }}
      >
        {organizations.map((org) => (
          <MenuItem key={org.id} value={org.id}>
            {org.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OrganizationSelect;
