import React, { useState, useCallback } from "react";
import { IconButton, Box, Tooltip } from '@mui/material';
import { useDropzone } from "react-dropzone";
import { upload } from "services/files";
import { Edit } from "@mui/icons-material";
import LoadingOverlay from "components/LoadingOverlay";
import ImageModal from "./ImageModal";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import UploadIcon from '@mui/icons-material/CloudUpload';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { IFile } from "types/files.d";
import foldersService from "services/folders";
import ImageSelectGallery from "./ImageSelectGallery";

interface ImageProps {
  style?: React.CSSProperties;
  onAccept: (image: IFile) => void;
  context?: string;
  bucketPath?: string;
  folderPath?: string;
  src?: any;
  alt?: any;
}

const Image: React.FC<ImageProps> = ({
  src,
  alt = "image",
  style = {},
  bucketPath = "cityview-cms/news",
  folderPath = "Misc.",
  onAccept,
  context,
}) => {

  const [openImageModal, setOpenImageModal] = useState(false);
  const [openMediaGallery, setOpenMediaGallery] = useState(false);
  const [selectedFile, setSelectedFile] = useState<IFile | null>(null);
  const [loading, setLoading] = useState(false);

  // Handle drag and drop
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) return;
      const file = acceptedFiles[0];  // Assuming a single file drop
      try {
        setLoading(true);
        const existingFolders = await foldersService.fetchAll();
        let folderId: string;
        const folderExists = existingFolders.data.some((folder: any) => folder.name === folderPath);
        if (!folderExists) {
          const createdFolder = await foldersService.create({
            name: folderPath,
          });
          folderId = createdFolder.id;
        } else {
          const existingFolder = existingFolders.data.find((folder: any) => folder.name === folderPath);
          folderId = existingFolder.id;
        }
        const image = await upload(file, folderId);
        onAccept(image);  // Set the new image URL
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setLoading(false);
      }
    },
    [onAccept]
  );

  // Handle drag and drop
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  //Opens the modal to generate an image
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpenImageModal(true);  // Open the modal
  };

  // Closes the modal
  const handleClose = () => {
    setOpenImageModal(false);
  };

  // Opens an image editor?
  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    alert("Edit feature coming soon!");
  };

  const handleFileSelect = (files: IFile[]) => {
    setSelectedFile(files[0]);
    console.log(selectedFile);
  };

  const handleAddImage = () => {
    if (selectedFile) {
      onAccept(selectedFile);
      setOpenMediaGallery(false);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
    >
      {/* Image box */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          border: src ? 'none' : '1px solid #ccc',  // Apply border if no image
          borderRadius: "10px",
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          src ? (
            <img
              src={src}
              alt={alt}
              style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '10px', padding: '10px' }}
            />
          ) : (
            <p style={{ textAlign: "center" }}>Drag 'n' drop image here, or click icon to select image</p>
          )
        )}

        {loading && <LoadingOverlay loading={loading} />}
      </Box>

      {/* Button Section */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",  // Aligns the buttons to the bottom-right
        }}
      >
        {/* AI Generate Image Button */}
        <Tooltip title="Generate an AI Image">
          <IconButton onClick={handleClick}>
            <SmartToyIcon fontSize="large" color="info" />
          </IconButton>
        </Tooltip>

        {/* Upload Icon */}
        <Tooltip title="Upload an Image">
          <IconButton {...getRootProps()}>
            <UploadIcon fontSize="large" color="info" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Select from Media">
          <IconButton onClick={(e) => setOpenMediaGallery(true)}>
            <PhotoLibraryIcon fontSize="large" color="info" />
          </IconButton>
        </Tooltip>

        {/* Edit Icon */}
        {/* <IconButton onClick={(e) => handleEditClick(e)}>
          <Edit fontSize="large" color="info" />
        </IconButton> */}
      </Box>

      {/* ImageModal for AI image generation */}
      {openImageModal && (
        <ImageModal
          open={openImageModal}
          onClose={handleClose}
          onAccept={onAccept}
          setLoading={setLoading}
          bucketPath={bucketPath}
          context={context}
          folderPath={folderPath}
        />
      )}

      {/* Image Gallery */}
      {openMediaGallery && (
        <ImageSelectGallery
          setOpenMediaGallery={setOpenMediaGallery}
          handleFileSelect={handleFileSelect}
          handleAddImage={handleAddImage}
          selectedFile={selectedFile}
        />
      )}
    </Box>
  );
};

export default Image;