import { Box } from "@mui/material";
import { useEvent } from "./EventContext";
import { IFile } from "types/files.d";
import FileGallery from 'components/FileGallery';

const MediaTab: React.FC = () => {

  const { event, setEvent } = useEvent();

  const handleUpdateMedia = (files: IFile[]) => {
    setEvent({
      ...event,
      files: files
    });
  };

  const handleDeleteMedia = (file: IFile) => {
    setEvent({
      ...event,
      files: event.files?.filter(f => f.id !== file.id) || []
    });
  };

  const handleBulkDeleteMedia = (files: IFile[]) => {
    setEvent({
      ...event,
      files: event.files?.filter(f => !files.includes(f)) || []
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <FileGallery
        files={event?.files || []}
        updateFiles={handleUpdateMedia}
        onDelete={handleDeleteMedia}
        onBulkDelete={handleBulkDeleteMedia}
      />
    </Box>
  );
};

export default MediaTab;
