import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import MDTypography from "components/MDTypography";
import ComplexStatisticsCard from "components/Cards/StatisticsCards/ComplexStatisticsCard";
import DraftEventsWidget from "components/DraftEventsWidget";

import { useAuth } from "context/Auth";
import { Paper, Typography, Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import BasicLayout from "components/Layouts/BasicLayout";

import statsService from "services/stats";
import eventsService from "services/events";
import newsService from "services/news";
import { Bar, Doughnut } from "react-chartjs-2";
import {
  DateRanges,
  getEventCountBreakdown,
  getAdminActivitySummary,
  getAllAdmins,
} from "./dashboardFunctions";
import dayjs from "dayjs";
import { IEvent } from "types/events.d";

export const ALL_ADMINS = "All";
function Dashboard(): JSX.Element {

  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState(null);
  const [selectedAdmin, setSelectedAdmin] = useState<string>(ALL_ADMINS);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRanges>(DateRanges.Total);
  const [events, setEvents] = useState<IEvent[]>([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const events = await eventsService.fetchAll();
      setEvents(events.data);
    };
    fetchEvents();
  }, []);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setLoading(true);
        const stats = await statsService.fetch(user.id);
        setStats({
          ...stats,
          pendingEvents: stats.pendingEvents.sort(
            (a: any, b: any) => new Date(a.start).getTime() - new Date(b.start).getTime()
          ),
        });
      } catch (error) {
        console.error("Error fetching stats", error);
      }
      setLoading(false);
    };
    fetchStats();
  }, []);

  const { user } = useAuth();

  const handleDeleteDuplicateEvent = async (id: string) => {
    try {
      await eventsService.remove(id);
      setStats((prevStats: any) => ({
        ...prevStats,
        duplicateEvents: prevStats.duplicateEvents.filter((event: any) => event.id !== id),
      }));
    } catch (error: any) {
      console.error("Error deleting:", error);
    }
  };

  // const handleDeletePendingEvent = async (id: string) => {
  //   try {
  //     await eventsService.remove(id);
  //     setStats((prevStats: any) => ({
  //       ...prevStats,
  //       pendingEvents: prevStats.pendingEvents.filter((event: any) => event.id !== id),
  //     }));
  //   } catch (error: any) {
  //     console.error("Error deleting:", error);
  //   }
  // };

  const handleDeleteNews = async (id: string) => {
    try {
      await newsService.remove(id);
      setStats((prevStats: any) => ({
        ...prevStats,
        pendingNews: prevStats.pendingNews.filter((event: any) => event.id !== id),
      }));
    } catch (error: any) {
      console.error("Error deleting:", error);
    }
  };
  const handleBuildNews = async () => {
    try {
      setLoading(true);
      await newsService.build();
      await new Promise((resolve) => setTimeout(resolve, 3000));
    } catch (error: any) {
      console.error("Error building news:", error);
    } finally {
      setLoading(false);
    }
  };

  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  // const handleApproveEvent = useCallback(async (id: string) => {
  //   if (!id) return;
  //   await eventsService.update(id, { status: "published" });
  //   setStats((prevStats: any) => ({
  //     ...prevStats,
  //     pendingEvents: prevStats.pendingEvents.filter((event: any) => event.id !== id),
  //   }));
  // }, []);

  const handlePublishNews = useCallback(async (id: string) => {
    if (!id) return;
    await newsService.update(id, { status: "published" });
    setStats((prevStats: any) => ({
      ...prevStats,
      pendingNews: prevStats.pendingNews.filter((news: any) => news.id !== id),
    }));
  }, []);

  // const valid = (event: any) => {
  //   try {
  //     return event.title && event.start && event.location && event.thumbnail && event.tags.length;
  //   } catch (error) {
  //     return false;
  //   }
  // };

  const newsValid = (news: any) => {
    try {
      return news.title && news.createdDate && news.author && news.image && news.tags.length;
    } catch (error) {
      return false;
    }
  };

  return (
    <BasicLayout loading={loading}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" style={{ marginBottom: 20 }}>
            Welcome {user.name}!
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <ComplexStatisticsCard
            title="Total Events"
            count={stats?.events}
            color="primary"
            icon="event"
            actionButtons={actionButtons}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <ComplexStatisticsCard
            title="Total Locations"
            count={stats?.locations}
            color="info"
            actionButtons={actionButtons}
            icon="location_on"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <ComplexStatisticsCard
            title="Total Videos"
            count={stats?.videos}
            color="success"
            actionButtons={actionButtons}
            icon="video_library"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <ComplexStatisticsCard
            title="Total Ads"
            count={stats?.ads}
            color="warning"
            actionButtons={actionButtons}
            icon="local_offer"
          />
        </Grid>
        <Grid item xs={12}>
          <DraftEventsWidget />
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 20 }}>
            <Typography variant="h5">Draft News</Typography>
            <DataGrid
              slots={{
                noRowsOverlay: () => (
                  <Box py={2}>
                    <Typography textAlign={"center"} variant="body1">
                      No Draft News Articles
                    </Typography>
                  </Box>
                ),
              }}
              style={{ height: 400 }}
              rows={stats?.pendingNews || []}
              getRowId={(row) => row._id}
              columns={[
                {
                  field: "image",
                  headerName: "",
                  flex: 1,
                  renderCell: (params) => (
                    <img src={params.value} style={{ width: 100 }} alt="draft-article-thumbnail" />
                  ),
                },
                { field: "title", headerName: "Title", flex: 2 },
                {
                  field: "createdDate",
                  headerName: "Date",
                  flex: 1,
                  renderCell: (params) =>
                    params.value ? new Date(params.value).toLocaleString() : "",
                },
                {
                  field: "actions",
                  headerName: "",
                  flex: 1,
                  renderCell: (params) => (
                    <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                      <IconButton
                        disabled={!newsValid(params.row)}
                        onClick={() => handlePublishNews(params.id as string)}
                      >
                        <Icon style={{ color: !newsValid(params.row) ? "#ddd" : "green" }}>
                          checkmark
                        </Icon>
                      </IconButton>
                      <IconButton color="secondary" component={Link} to={`/news/${params.id}`}>
                        <Icon style={{ color: "navy" }}>edit</Icon>
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDeleteNews(params.id as string)}
                      >
                        <Icon color="error">delete</Icon>
                      </IconButton>
                    </Box>
                  ),
                },
              ]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              disableRowSelectionOnClick
            />
            <Button onClick={handleBuildNews} variant="contained" color="primary">
              Build News
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 20 }}>
            <Typography variant="h5">Admin Activity Summary</Typography>
            <Grid container spacing={3} direction={"row"} position={"relative"}>
              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  top: "10px",
                  right: "10px",
                }}
              >
                <FormControl style={{ width: 200 }}>
                  <InputLabel focused={true} id="range-select-label">
                    Select an date range
                  </InputLabel>
                  <Select
                    labelId="range-select-label"
                    id="range"
                    value={selectedDateRange || ""}
                    label="Recurrs on this day each week"
                    onChange={(e) => setSelectedDateRange(e.target.value as DateRanges)}
                  >
                    <MenuItem value={DateRanges.Total}>{DateRanges.Total}</MenuItem>
                    <MenuItem value={DateRanges.ThisWeek}>{DateRanges.ThisWeek}</MenuItem>
                    <MenuItem value={DateRanges.LastWeek}>{DateRanges.LastWeek}</MenuItem>
                    <MenuItem value={DateRanges.ThisMonth}>{DateRanges.ThisMonth}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl style={{ width: 200 }}>
                  <InputLabel focused={true} id="admin-select-label">
                    Select an admin
                  </InputLabel>
                  <Select
                    labelId="admin-select-label"
                    id="admin"
                    value={selectedAdmin || ""}
                    label="Recurrs on this day each week"
                    onChange={(e) => setSelectedAdmin(e.target.value as string)}
                  >
                    <MenuItem value={ALL_ADMINS}>{ALL_ADMINS}</MenuItem>
                    {getAllAdmins({ events }).map((admin, idx) => (
                      <MenuItem key={admin.id + idx} value={admin.name}>
                        {admin.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <Grid item xs={12} sm={9} height={"100%"} display={"flex"}>
                <Bar
                  data={{
                    labels: getAdminActivitySummary({
                      events,
                      dateRange: selectedDateRange,
                      admin: selectedAdmin,
                    }).created.map((date) => dayjs(date.date).format("MM/DD/YYYY")),
                    datasets: [
                      {
                        label: "Created",
                        data: getAdminActivitySummary({
                          events,
                          dateRange: selectedDateRange,
                          admin: selectedAdmin,
                        }).created.map((data) => data.count),
                        backgroundColor: ["rgba(54, 162, 235, 0.7)"],
                      },
                      {
                        label: "Updated",
                        data: getAdminActivitySummary({
                          events,
                          dateRange: selectedDateRange,
                          admin: selectedAdmin,
                        }).updated.map((data) => data.count),
                        backgroundColor: ["rgba(255, 206, 86, 0.7)"],
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        display: true,
                      },

                      title: {
                        text: "Created and Updated Events by Admin",
                        display: true,
                        align: "center",
                        padding: { top: 10, bottom: 10 },
                        font: { size: 20, weight: "bold" },
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 20 }}>
            <Typography variant="h5">Events Summary</Typography>
            <Grid container spacing={3} direction={"row"} justifyContent={"space-around"}>
              <Grid
                item
                xs={12}
                sm={3}
                position={"relative"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Doughnut
                  width={200}
                  data={{
                    labels: ["User Created", "Bot Created"],
                    datasets: [
                      {
                        label: "",
                        data: [
                          getEventCountBreakdown({ events }).userCreatedEvents,
                          getEventCountBreakdown({ events }).botCreatedEvents,
                        ],
                        backgroundColor: [
                          "rgba(54, 162, 235, 0.7)",
                          "rgba(255, 206, 86, 0.7)",
                          "rgba(75, 192, 192, 0.7)",
                          "rgba(153, 102, 255, 0.7)",
                          "rgba(255, 159, 64, 0.7)",
                        ],
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        display: true,
                        position: "bottom",
                      },
                      title: {
                        text: "All Events by Creator Type",
                        display: true,
                        align: "center",
                        padding: { top: 10, bottom: 10 },
                        font: { size: 20, weight: "bold" },
                      },
                    },
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "50%",
                    transform: `translate(50%, -50%)`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    lineHeight: "normal",
                    padding: "20px 0 0 20px",
                  }}
                >
                  <span> {getEventCountBreakdown({ events }).totalEvents}</span>
                  <span>Events</span>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 20 }}>
            <Typography variant="h5">Locations without events</Typography>
            <DataGrid
              rows={stats?.locationsWithoutEvents || []}
              getRowId={(row) => row._id}
              columns={[
                {
                  field: "thumbnail",
                  headerName: "",
                  flex: 1,
                  renderCell: (params) => (
                    <img src={params.value} style={{ width: 100 }} alt="event thumbnail" />
                  ),
                },
                { field: "name", headerName: "Name", flex: 2 },
                {
                  field: "link",
                  headerName: "Link",
                  flex: 2,
                  renderCell: (params) => (
                    <a href={params.value} target="_blank" rel="noreferrer">
                      {params.value}
                    </a>
                  ),
                },
                {
                  field: "instagram",
                  headerName: "Instagram",
                  flex: 2,
                  renderCell: (params) => (
                    <a
                      href={"https://instagram.com/" + params.value}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {params.value}
                    </a>
                  ),
                },
                {
                  field: "actions",
                  headerName: "",
                  flex: 1,
                  renderCell: (params) => (
                    <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                      <IconButton color="secondary" component={Link} to={`/locations/${params.id}`}>
                        <Icon style={{ color: "navy" }}>edit</Icon>
                      </IconButton>
                    </Box>
                  ),
                },
              ]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 20 }}>
            <Typography variant="h5">Duplicate Events</Typography>
            <DataGrid
              rows={stats?.duplicateEvents || []}
              getRowId={(row) => row._id}
              columns={[
                {
                  field: "thumbnail",
                  headerName: "",
                  flex: 1,
                  renderCell: (params) => (
                    <img
                      src={params.value}
                      style={{ width: 100 }}
                      alt="duplicate event thumbnails"
                    />
                  ),
                },
                { field: "title", headerName: "Title", flex: 2 },
                {
                  field: "location",
                  headerName: "Location",
                  flex: 2,
                  renderCell: (params) => params.value.name,
                },
                { field: "status", headerName: "Status", flex: 1 },
                {
                  field: "start",
                  headerName: "Start",
                  flex: 1,
                  renderCell: (params) => new Date(params.value).toLocaleString(),
                },
                {
                  field: "actions",
                  headerName: "",
                  flex: 1,
                  renderCell: (params) => (
                    <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                      <IconButton color="secondary" component={Link} to={`/events/${params.id}`}>
                        <Icon style={{ color: "navy" }}>edit</Icon>
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDeleteDuplicateEvent(params.id as string)}
                      >
                        <Icon color="error">delete</Icon>
                      </IconButton>
                    </Box>
                  ),
                },
              ]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Paper>
        </Grid>
      </Grid>
    </BasicLayout>
  );
}

export default Dashboard;
