import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import videosService from "../../services/files";
import { IFile } from "types/files.d";

interface VideoSelectProps {
  value?: IFile;
  onChange: (video: IFile) => void;
}

const VideoSelect: React.FC<VideoSelectProps> = ({ onChange, value }) => {
  const [videos, setVideos] = useState<IFile[]>([]);

  useEffect(() => {
    const fetchVideos = async () => {
      const videos = await videosService.fetchAll({ pageSize: 1000 });
      setVideos(videos.data);
    };
    fetchVideos();
  }, []);

  const handleSelectChange = (value: any) => {
    const selectedVideo = videos.find((video) => video.id === value);
    if (selectedVideo) {
      onChange(selectedVideo);
    }
  };

  return (
    <Autocomplete
      value={value?.id || value?._id || ""}
      onChange={(e, value) => handleSelectChange(value)}
      options={videos?.map((video: any) => video.id)}
      getOptionLabel={(option) => videos.find((video: any) => video.id === option)?.title || option}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default VideoSelect;
