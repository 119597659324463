import { IPaginationModal } from "types/App";
import { API_HOST } from "../config";
import axios from "axios";
import TokenHandler from "./base";
import { ICommunity } from "types/communities";

async function fetchAll(paginationModel?: IPaginationModal) {
  const filteredPagination = { ...paginationModel };
  delete filteredPagination.query;
  delete filteredPagination.sort;

  const url = new URL(`${API_HOST}/communities`);
  url.search = new URLSearchParams(filteredPagination).toString();

  const params = {
    ...(paginationModel?.query && { query: paginationModel.query }),
    ...(paginationModel?.sort && { sort: paginationModel.sort }),
  };
  const response = await axios.get(url.toString(), {
    params,
    headers: TokenHandler.getHeaders(),
  });

  return response.data;
}

async function fetch(id: string) {
  const response = await axios.get(`${API_HOST}/communities/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function create(community: ICommunity) {
  const response = await axios.post(`${API_HOST}/communities`, community, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function update(id: string, community: ICommunity) {
  const response = await axios.put(`${API_HOST}/communities/${id}`, community, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function remove(id: string) {
  const response = await axios.delete(`${API_HOST}/communities/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

const communitiesService = {
  fetch,
  fetchAll,
  create,
  update,
  remove,
};

export default communitiesService; 