import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import BasicLayout from "components/Layouts/BasicLayout";
import dataSourcesService from "services/dataSources";
import { formatDate } from "utils/commonFunctions";

const DataSources: React.FC = () => {
  const columns: GridColDef[] = [
    { field: "url", headerName: "URL", flex: 1 },
    { field: "type", headerName: "Type", flex: 1 },
    { field: "dataType", headerName: "Data Type", flex: 1 },
    { field: "active", headerName: "Active", flex: 1, type: "boolean" },
    { field: "lastCompletedAt", headerName: "Last Completed", flex: 1, renderCell: (params) => params.value ? formatDate(params.value) : "" },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={5} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Data Sources</Typography>
        </Grid>
        <Grid item xs={7} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/data-sources/create">
            <Icon>add</Icon>
            Create Data Source
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <PaginatedDataGrid
              collectionName="data-sources"
              defaultSortField="createdDate"
              defaultSortDirection="desc"
              columns={columns}
              service={dataSourcesService}
            />
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default DataSources;
