import { IPaginationModal } from "types/App";
import { API_HOST, API_VERSION } from "../config";
import axios from "axios";
import TokenHandler from "./base";

async function fetchAll(paginationModel?: IPaginationModal) {
  const filteredPagination = { ...paginationModel };
  delete filteredPagination.query;
  delete filteredPagination.sort;

  const url = new URL(`${API_HOST}/tags`);

  url.search = new URLSearchParams(filteredPagination).toString();

  const params = {
    ...(paginationModel?.query && { query: paginationModel.query }),
    ...(paginationModel?.sort && { sort: paginationModel.sort }),
  };
  const response = await axios.get(url.toString(), {
    params,
    headers: TokenHandler.getHeaders(),
  });

  return response.data;
}

async function fetch(id: string) {
  const response = await axios.get(`${API_HOST}/tags/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function create(data: any) {
  const response = await axios.post(`${API_HOST}/tags`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function update(id: string, data: any) {
  const response = await axios.put(`${API_HOST}/tags/${id}`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function remove(id: string) {
  const response = await axios.delete(`${API_HOST}/tags/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

const tagsService = {
  fetch,
  fetchAll,
  create,
  update,
  remove,
};

export default tagsService;
