import { FormControlLabel, RadioGroup, Typography, FormGroup, FormLabel, Grid, Radio, Button } from "@mui/material";
import { StatusEnum } from "types/events.d";
import { useEvent } from "./EventContext";

const AdminTab: React.FC = () => {

  const { event, setEvent, id, loading, deleteEvent } = useEvent();

  const handleDelete = async () => {
    await deleteEvent();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormGroup>
          <FormLabel id="status-group-label">Publishing Status</FormLabel>
          <RadioGroup
            row
            aria-labelledby="status-group-label"
            name="row-radio-buttons-group"
            value={event.status}
            onChange={(e) => setEvent({ ...event, status: e.target.value as StatusEnum })}
          >
            <FormControlLabel
              value={StatusEnum.Pending}
              control={<Radio />}
              label="Pending"
            />
            <FormControlLabel
              value={StatusEnum.Published}
              control={<Radio />}
              label="Published"
            />
          </RadioGroup>
        </FormGroup>
      </Grid>
      {id && id !== "create" && (
        <Grid item xs={12}>
          <Typography variant="h4" color="error">
            Danger Zone
          </Typography>
          <Button
            disabled={loading}
            variant="contained"
            color="error"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default AdminTab;
