import React, { useCallback, useMemo, useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  FormControl,
  FormLabel,
  Grid,
  createFilterOptions,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { } from "lodash";
import { IGlobalFilter, useGlobalFilterContext } from "./GlobalFilterContext";
import { Dayjs } from 'dayjs';
import { filterComponents } from './filters/index';

interface PaginationFilterProps {
  filterOptions?: FilterOption[];
  collectionName: string;
}

export interface IFilterOptionOption {
  value: string;
  label: string;
}

export type Operators = "eq" | "gt" | "gte" | "in" | "lt" | "lte" | "ne" | "nin" | "regex" | "search";

export interface FilterOption {
  columnWidthMap?: { [field: string]: string | number };
  label: string;
  name: string;
  options?: IFilterOptionOption[];
  required?: boolean;
  placeholder?: string;
  operator?: Operators;
  service?: any;
  fieldName?: string;
  labelField?: string;
  type:
  | "text"
  | "date"
  | "datetime"
  | "checkbox"
  | "select"
  | "multiSelect"
  | "select"
  | "number"
  | "search"
  | "int"
  | "statusCheckboxes"
  | "autocomplete";
}

const defaultFilterOptions: FilterOption[] = [
  {
    columnWidthMap: { xs: 12 },
    label: "Price",
    name: "Price",
    required: true,
    type: "text",
  }
];

const tagFilterOptions = createFilterOptions({
  ignoreCase: true,
  limit: 10,
});

const PaginationFilter = (props: PaginationFilterProps) => {
  const { filterOptions = defaultFilterOptions, collectionName } = props;

  const { filterValues: contextFilterValues, setFilterValues } = useGlobalFilterContext();
  const [statusFilter, setStatusFilter] = useState({
    pending: false,
    published: false,
  });

  //TODO
  //@ts-ignore
  const filterValues: IFilterOptions[] = useMemo(() => {
    return contextFilterValues[collectionName as keyof typeof contextFilterValues] || [];
  }, [collectionName, contextFilterValues]);

  const [filterExpanded, setFilterExpanded] = useState(false);

  const handleStatusChange = (status: "pending" | "published", checked: boolean) => {
    setStatusFilter((prev) => ({
      ...prev,
      [status]: checked,
    }));

    const pendingSelected = status === "pending" ? checked : statusFilter.pending;
    const publishedSelected = status === "published" ? checked : statusFilter.published;

    if (!pendingSelected && !publishedSelected) {
      setFilterValues((prev: IGlobalFilter[]) => ({
        ...prev,
        [collectionName]: filterValues.filter((f) => f.field !== "status"), // Remove status filter
      }));
    } else {
      const newStatusFilter = [];

      if (pendingSelected) newStatusFilter.push("pending");
      if (publishedSelected) newStatusFilter.push("published");

      setFilterValues((prev: IGlobalFilter[]) => ({
        ...prev,
        [collectionName]: [
          ...filterValues.filter((f) => f.field !== "status"),
          {
            field: "status",
            value: newStatusFilter,
            operator: "in",
          },
        ],
      }));
    }
  };


  // NEEDS FIXED
  const handleDateChange = (date: Dayjs | null, field: string) => {
    setFilterValues((prev: IGlobalFilter[]) => ({
      ...prev,
      [collectionName]: date
        ? [
          ...filterValues.filter((f) => f.field !== field),
          {
            field,
            value: date.toISOString(), // Store ISO string for the selected date
            operator: "in", // You can adjust this operator based on your needs
          },
        ]
        : filterValues.filter((f) => f.field !== field), // Clear the date filter if no date selected
    }));
  };

  const renderFilterField = useCallback(
    (filterOption: FilterOption) => {
      const FilterComponent = filterComponents[filterOption.type];
      if (!FilterComponent) {
        console.warn(`No filter component found for type: ${filterOption.type}`);
        return null;
      }

      const value = filterValues.find(
        (filterValue) => filterValue.field === filterOption.name
      )?.value;

      const handleFilterChange = (newValue: any) => {
        setFilterValues((prev: IGlobalFilter[]) => ({
          ...prev,
          [collectionName]: [
            ...filterValues.filter(
              (filterValue) => filterValue.field !== filterOption.name
            ),
            ...(newValue ? [newValue] : []),
          ],
        }));
      };

      return (
        <FilterComponent
          filterOption={filterOption}
          value={value}
          onChange={handleFilterChange}
          collectionName={collectionName}
        />
      );
    },
    [collectionName, filterValues, setFilterValues]
  );

  return (
    <Accordion
      expanded={filterExpanded}
      onChange={(_e, expanded) => setFilterExpanded(expanded)}
      style={{ paddingRight: "32px", width: "100%" }}
    >
      <AccordionSummary
        id="panel-header"
        aria-controls="panel-content"
        expandIcon={<GridExpandMoreIcon />}
      >
        Filters
      </AccordionSummary>
      <Grid container spacing={2} padding={"10px"} margin={0}>
        {filterOptions.map((filterOption: FilterOption, index) => (
          <Grid
            key={index}
            item
            {...(filterOption.columnWidthMap ? filterOption.columnWidthMap : { xs: 12 })}
          >
            <FormControl fullWidth>
              {filterOption.type !== "checkbox" && (
                <FormLabel required={filterOption.required}>
                  {filterOption.label || filterOption.name}
                </FormLabel>
              )}
            </FormControl>
            {renderFilterField(filterOption)}
          </Grid>
        ))}
      </Grid>
    </Accordion>
  );
};

export default PaginationFilter;