import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import membersService from 'services/members';
import { IMember } from 'types/members';

interface MemberSelectProps {
  value: IMember | null;
  onChange: (member: IMember | null) => void;
}

const MemberSelect: React.FC<MemberSelectProps> = ({ value, onChange }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<IMember[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let active = true;

    if (!open) {
      return undefined;
    }

    setLoading(true);

    (async () => {
      try {
        const { data: members } = await membersService.fetchAll();
        if (active) {
          setOptions(members);
        }
      } catch (error) {
        console.error('Failed to fetch members:', error);
      } finally {
        if (active) {
          setLoading(false);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [open]);

  return (
    <Autocomplete
      id="member-select"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      value={value}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default MemberSelect; 