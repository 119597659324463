import { TextFilter } from './TextFilter';
import { DateFilter } from './DateFilter';
import { CheckboxFilter } from './CheckboxFilter';
import { SelectFilter } from './SelectFilter';
import { MultiSelectFilter } from './MultiSelectFilter';
import { StatusCheckboxesFilter } from './StatusCheckboxesFilter';
import { AutocompleteFilter } from './AutocompleteFilter';

export const filterComponents = {
  text: TextFilter,
  number: TextFilter,
  int: TextFilter,
  search: TextFilter,
  date: DateFilter,
  datetime: DateFilter,
  checkbox: CheckboxFilter,
  select: SelectFilter,
  multiSelect: MultiSelectFilter,
  statusCheckboxes: StatusCheckboxesFilter,
  autocomplete: AutocompleteFilter,
} as const; 