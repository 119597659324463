import { Grid, Box, Typography, FormControl, TextField, FormLabel, Icon } from "@mui/material";
import { useEvent } from "./EventContext";
import { ILocationData } from "types/locations.d";
import TagMultiSelect from "components/TagMultiSelect";
import AITextField from "components/AITextField";
import TipTap from "components/TipTap";
import Image from "components/Images/Image";
import { IFile } from "types/files.d";
import debounce from "lodash/debounce";
import { ICategory } from "types/categories";
import LocationSelect from "components/LocationSelect";
import CategorySelect from "components/CategorySelect";
import CommunitySelect from "components/CommunitySelect";
import CommunityMultiSelect from "components/CommunityMultiSelect";
import { ICommunity } from "types/communities";

const DetailsTab: React.FC = () => {

  const { event, setEvent } = useEvent();

  const handleImageChange = (image: IFile) => {
    setEvent((prevEvent) => (
      { ...prevEvent, defaultImage: image, files: [...prevEvent.files || [], image] }
    ));
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box sx={{ paddingTop: 1 }}>
            <Image
              onAccept={handleImageChange}
              src={event.defaultImage?.url}
              context={event.description || ""}
              alt={event.title}
              bucketPath="cityview-cms/events"
              folderPath="Events Media"
              style={{ height: "400px" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <AITextField
                id="title"
                label="Title"
                prompt="From the following details, create a title of an event. The title should be descriptive and engaging while being lesss than 6 words."
                value={event?.title || ""}
                onChange={(e: any) => setEvent({ ...event, title: e })}
              />
            </Grid>
            <Grid item xs={12}>
              <AITextField
                id="description"
                label="Description"
                prompt="From the following details, create a description of an event. The title should be descriptive and engaging and be at least 3 sentences long."
                promptMode="modal"
                value={event?.description || ""}
                multiline
                rows={4}
                onChange={(e: any) => setEvent({ ...event, description: e })}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Content</FormLabel>
              <TipTap
                content={event.content}
                onChange={debounce(
                  (value: JSON) => setEvent({ ...event, content: value }),
                  300
                )}
                bucketPath="cityview-cms/events"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="title">Price</FormLabel>
                <TextField
                  id="price"
                  value={event?.price || ""}
                  onChange={(e) => setEvent({ ...event, price: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="link">
                  Link
                  <a href={event?.link} target="_blank" rel="noreferrer">
                    <Icon>open_in_new</Icon>
                  </a>
                </FormLabel>
                <TextField
                  id="link"
                  value={event?.link || ""}
                  onChange={(e) => setEvent({ ...event, link: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="tags">Tags</FormLabel>
                <TagMultiSelect
                  selectedTags={event?.tags || []}
                  setSelectedTags={(tags) => {
                    setEvent({
                      ...event,
                      tags
                    });
                  }}
                  type={["event", "tag"]}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="category">Category</FormLabel>
                <CategorySelect
                  value={event?.category?.title || null}
                  onChange={(value: ICategory) => {
                    setEvent({ ...event, category: value });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="community">Community</FormLabel>
                <CommunitySelect
                  value={event?.community?.name || null}
                  onChange={(value: ICommunity) => {
                    setEvent({ ...event, community: value });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="location">Location</FormLabel>
                <LocationSelect
                  value={event?.location?.name || null}
                  onChange={(value: ILocationData) => {
                    setEvent({
                      ...event,
                      location: value,
                    });
                  }}
                />
              </FormControl>
            </Grid>
            {event?.location && (
              <Grid item xs={12}>
                <Box sx={{ display: "flex", mt: 2 }}>
                  <Box sx={{ flexBasis: "30%", mr: 2 }}>
                    {event.location.defaultImage ? (
                      <img
                        src={event.location.defaultImage?.url}
                        alt={event.location.name}
                        style={{ width: "100%", height: "auto" }}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: "100%",
                          height: "200px",
                          bgcolor: "grey.300",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography>No image available</Typography>
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">{event.location.name}</Typography>
                    <Typography>{event.location.address1}</Typography>
                    <Typography>
                      {event.location.city}, {event.location.state} {event.location.zip}
                    </Typography>
                    {event.location.link && (
                      <Typography>
                        Website:{" "}
                        <a
                          href={event.location.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {event.location.link}
                        </a>
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default DetailsTab;