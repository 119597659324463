import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Typography,
  TextField,
  Snackbar,
  Alert,
  IconButton,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { useAuth } from "context/Auth";
import rolesService from "services/roles";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";
import { ChevronLeft } from "@mui/icons-material";
import { IRole } from "types/roles";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

// Add these types near the top of the file
type Permission = string;
type PermissionGroup = {
  [key: string]: Permission | { [key: string]: Permission };
};
type Permissions = {
  [key: string]: PermissionGroup;
};

// Add this interface to your types
interface IFormInput {
  title: string;
  description: string;
  permissions: string[];
}

// Add the validation schema
const roleSchema = z.object({
  title: z.string().min(1, "Title is required"),
  description: z.string().optional(),
  permissions: z.array(z.string()).default([])
});

type RoleFormData = z.infer<typeof roleSchema>;

const PERMISSIONS = {
  EVENTS: {
    CREATE: 'events.create',
    READ: 'events.read',
    UPDATE: 'events.update',
    DELETE: 'events.delete',
    UPLOAD: 'events.upload',
    GENERATE: 'events.generate'
  },

  ACCOUNTS: {
    CREATE: 'accounts.create',
    READ: 'accounts.read',
    UPDATE: 'accounts.update',
    DELETE: 'accounts.delete'
  },

  ADS: {
    CREATE: 'ads.create',
    READ: 'ads.read',
    UPDATE: 'ads.update',
    DELETE: 'ads.delete',
    UPLOAD: 'ads.upload'
  },

  API_KEYS: {
    CREATE: 'apiKeys.create',
    READ: 'apiKeys.read',
    UPDATE: 'apiKeys.update',
    DELETE: 'apiKeys.delete',
    GENERATE: 'apiKeys.generate'
  },

  AUDIENCE: {
    CREATE: 'audience.create',
    READ: 'audience.read',
    UPDATE: 'audience.update',
    DELETE: 'audience.delete',
    UPLOAD: 'audience.upload'
  },

  AUTHORS: {
    CREATE: 'authors.create',
    READ: 'authors.read',
    UPDATE: 'authors.update',
    DELETE: 'authors.delete',
    UPLOAD: 'authors.upload'
  },

  CATEGORIES: {
    CREATE: 'categories.create',
    READ: 'categories.read',
    UPDATE: 'categories.update',
    DELETE: 'categories.delete',
    UPLOAD: 'categories.upload'
  },

  CITIES: {
    CREATE: 'cities.create',
    READ: 'cities.read',
    UPDATE: 'cities.update',
    DELETE: 'cities.delete',
    UPLOAD: 'cities.upload'
  },

  COMMUNITIES: {
    CREATE: 'communities.create',
    READ: 'communities.read',
    UPDATE: 'communities.update',
    DELETE: 'communities.delete'
  },

  DATA_SOURCES: {
    CREATE: 'dataSources.create',
    READ: 'dataSources.read',
    UPDATE: 'dataSources.update',
    DELETE: 'dataSources.delete',
    RUN: 'dataSources.run'
  },

  FILES: {
    UPLOAD: 'files.upload',
    CREATE: 'files.create',
    READ: 'files.read',
    UPDATE: 'files.update',
    DELETE: 'files.delete'
  },

  FOLDERS: {
    CREATE: 'folders.create',
    READ: 'folders.read',
    UPDATE: 'folders.update',
    DELETE: 'folders.delete'
  },

  FORMS: {
    CREATE: 'forms.create',
    READ: 'forms.read',
    UPDATE: 'forms.update',
    DELETE: 'forms.delete'
  },

  GROUPS: {
    CREATE: 'groups.create',
    READ: 'groups.read',
    UPDATE: 'groups.update',
    DELETE: 'groups.delete'
  },

  INVITATIONS: {
    CREATE: 'invitations.create',
    READ: 'invitations.read',
    UPDATE: 'invitations.update',
    DELETE: 'invitations.delete'
  },

  ITINERARIES: {
    CREATE: 'itineraries.create',
    READ: 'itineraries.read',
    UPDATE: 'itineraries.update',
    DELETE: 'itineraries.delete'
  },

  LOCATIONS: {
    CREATE: 'locations.create',
    READ: 'locations.read',
    UPDATE: 'locations.update',
    DELETE: 'locations.delete'
  },

  MEMBERS: {
    CREATE: 'members.create',
    READ: 'members.read',
    UPDATE: 'members.update',
    DELETE: 'members.delete'
  },

  NEWS: {
    CREATE: 'news.create',
    READ: 'news.read',
    UPDATE: 'news.update',
    DELETE: 'news.delete',
    UPLOAD: 'news.upload'
  },

  NEWSLETTERS: {
    CREATE: 'newsletters.create',
    READ: 'newsletters.read',
    UPDATE: 'newsletters.update',
    DELETE: 'newsletters.delete'
  },

  OPENAI: {
    READ: 'openai.read',
    COMPLETION: 'openai.completion',
    IMAGE: 'openai.image',
    ANALYZE: 'openai.analyze',
    TRANSCRIBE: 'openai.transcribe',
    GENERATE: 'openai.generate'
  },

  ORGANIZATIONS: {
    CREATE: 'organizations.create',
    READ: 'organizations.read',
    UPDATE: 'organizations.update',
    DELETE: 'organizations.delete'
  },

  PHONE_NUMBERS: {
    CREATE: 'phoneNumbers.create',
    READ: 'phoneNumbers.read',
    UPDATE: 'phoneNumbers.update',
    DELETE: 'phoneNumbers.delete'
  },

  PROMOTIONS: {
    CREATE: 'promotions.create',
    READ: 'promotions.read',
    UPDATE: 'promotions.update',
    DELETE: 'promotions.delete'
  },

  QRCODES: {
    CREATE: 'qrcodes.create',
    READ: 'qrcodes.read',
    UPDATE: 'qrcodes.update',
    DELETE: 'qrcodes.delete'
  },

  ROLES: {
    CREATE: 'roles.create',
    READ: 'roles.read',
    UPDATE: 'roles.update',
    DELETE: 'roles.delete'
  },

  RSVP: {
    CREATE: 'rsvp.create',
    READ: 'rsvp.read',
    UPDATE: 'rsvp.update',
    DELETE: 'rsvp.delete'
  },

  SENDGRID: {
    READ: 'sendgrid.read',
    SEND: 'sendgrid.send',
    SUBUSERS: {
      DOMAINS: 'sendgrid.subusers.domains',
      CREATE: 'sendgrid.subusers.create'
    },
    DOMAINS: {
      AUTHENTICATE: 'sendgrid.domains.authenticate',
      DELETE: 'sendgrid.domains.delete',
      VALIDATE: 'sendgrid.domains.validate'
    }
  },

  STATS: {
    READ: 'stats.read'
  },

  STRIPE: {
    ACCOUNTS: {
      CREATE: 'stripe.accounts.create',
      READ: 'stripe.accounts.read',
      UPDATE: 'stripe.accounts.update',
      DELETE: 'stripe.accounts.delete'
    },
    BANK_ACCOUNTS: {
      CREATE: 'stripe.bankAccounts.create',
      READ: 'stripe.bankAccounts.read',
      UPDATE: 'stripe.bankAccounts.update',
      DELETE: 'stripe.bankAccounts.delete'
    },
    DISPUTES: {
      CREATE: 'stripe.disputes.create',
      READ: 'stripe.disputes.read',
      UPDATE: 'stripe.disputes.update',
      DELETE: 'stripe.disputes.delete'
    },
    READ: 'stripe.read',
    CREATE: 'stripe.create',
    UPDATE: 'stripe.update',
    DELETE: 'stripe.delete',
    PAYMENTS: {
      CREATE: 'stripe.payments.create',
      READ: 'stripe.payments.read',
      UPDATE: 'stripe.payments.update',
      DELETE: 'stripe.payments.delete'
    },
    PAYOUTS: {
      CREATE: 'stripe.payouts.create',
      READ: 'stripe.payouts.read',
      UPDATE: 'stripe.payouts.update',
      DELETE: 'stripe.payouts.delete'
    },
    PLANS: {
      READ: 'stripe.plans.read'
    },
    SUBSCRIPTIONS: {
      CREATE: 'stripe.subscriptions.create',
      CANCEL: 'stripe.subscriptions.cancel',
      READ: 'stripe.subscriptions.read',
      UPDATE: 'stripe.subscriptions.update'
    },
    INVOICES: {
      READ: 'stripe.invoices.read'
    }
  },

  TAGS: {
    CREATE: 'tags.create',
    READ: 'tags.read',
    UPDATE: 'tags.update',
    DELETE: 'tags.delete',
    UPLOAD: 'tags.upload'
  },

  USERS: {
    CREATE: 'users.create',
    READ: 'users.read',
    UPDATE: 'users.update',
    DELETE: 'users.delete'
  },

  UTILS: {
    TRANSCRIBE_VIDEO: 'utils.transcribe-video',
    EVENT_OBJECTS_FROM_JSON: 'utils.event-objects-from-json',
    EVENT_OBJECTS_FROM_TEXT: 'utils.event-objects-from-text',
    EVENT_OBJECTS_FROM_IMAGE: 'utils.event-objects-from-image'
  }
};

const Role: React.FC = () => {
  const [role, setRole] = useState<IRole>({ title: "", description: "", permissions: [] });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<any>({ open: false, message: "", severity: "success" });
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();

  // Update form control to use zod resolver
  const { control, handleSubmit, setValue, formState: { errors } } = useForm<RoleFormData>({
    resolver: zodResolver(roleSchema),
    defaultValues: {
      title: "",
      description: "",
      permissions: [],
    },
  });

  const fetchRole = useCallback(async () => {
    try {
      setLoading(true);
      const _role: IRole = await rolesService.fetch(id);
      setRole(_role);
    } catch (e) {
      console.error("Error fetching role group:", e);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (id && id !== "create") {
      fetchRole().then(() => {
        setValue("title", role.title);
        setValue("description", role.description);
        setValue("permissions", role.permissions || []);
      });
    }
  }, [fetchRole, id, setValue]);

  // Function to delete a role
  const handleDelete = async () => {
    try {
      await rolesService.remove(id);
      navigate("/roles");
    } catch (error) {
      console.error("Error deleting role:", error);
    }
    fetchRole();
  };

  // Helper function to check if all permissions in a group are selected
  const isGroupSelected = (groupPermissions: string[]) => {
    return groupPermissions.every((permission) => role.permissions?.includes(permission));
  };

  // Helper function to handle group selection
  const handleGroupSelection = (groupPermissions: string[], checked: boolean) => {
    const updatedPermissions = checked
      ? [...new Set([...(role.permissions || []), ...groupPermissions])]
      : role.permissions?.filter((p) => !groupPermissions.includes(p)) || [];

    setRole({ ...role, permissions: updatedPermissions });
    setValue("permissions", updatedPermissions);
  };

  // Update handleSave to include permissions
  const handleSave = async (formData: RoleFormData) => {
    try {
      setLoading(true);
      const newRole: IRole = {
        ...role,
        title: formData.title,
        description: formData.description,
        permissions: formData.permissions,
      };

      if (!newRole?.title || !newRole?.description) {
        throw new Error("Please fill in all fields");
      }

      if (id === "create") {
        // TODO Check to make sure it doesn't already exist
        newRole.createdBy = user;
        newRole.createdDate = new Date().toISOString();
        await rolesService.create(newRole);
      } else {
        newRole.updatedBy = user;
        newRole.updatedAt = new Date().toISOString();
        await rolesService.update(newRole.id, newRole);
      }
      //on successful save, navigate back to roles
      navigate("/roles");
    } catch (error: any) {
      console.error("Error saving role:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Render permission groups
  const renderPermissionGroup = (groupName: string, permissions: PermissionGroup) => {
    const permissionValues = Object.values(permissions).filter((p): p is string => typeof p === "string");

    return (
      <div className="mb-4">
        <FormControlLabel
          control={
            <Checkbox
              checked={isGroupSelected(permissionValues)}
              onChange={(e) => handleGroupSelection(permissionValues, e.target.checked)}
            />
          }
          label={<span className="font-semibold">{groupName}</span>}
        />
        <div className="ml-8 grid grid-cols-2 gap-2">
          {Object.entries(permissions).map(([key, value]) => {
            if (typeof value === "string") {
              return (
                <FormControlLabel
                  key={value}
                  control={
                    <Controller
                      name="permissions"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          checked={role.permissions?.includes(value) || false}
                          onChange={(e) => {
                            const updatedPermissions = e.target.checked
                              ? [...(role.permissions || []), value]
                              : role.permissions?.filter((p) => p !== value) || [];
                            setRole({ ...role, permissions: updatedPermissions });
                            field.onChange(updatedPermissions);
                          }}
                        />
                      )}
                    />
                  }
                  label={key.toLowerCase()}
                />
              );
            }
            return null;
          })}
        </div>
      </div>
    );
  };

  return (
    <BasicLayout>
      <form onSubmit={handleSubmit(handleSave)}>
        <Grid container direction={"column"} spacing={2}>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error" onClose={() => setError("")}>
                {error}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} display={"flex"} alignItems={"center"}>
                <IconButton onClick={() => navigate(-1)}>
                  <ChevronLeft />
                </IconButton>
                <Typography variant="h4">Role</Typography>
              </Grid>
              <Grid item xs={12}>
                {!id ||
                  (id === "create" ? (
                    <Typography variant="body1">Create a User Role.</Typography>
                  ) : (
                    <Typography variant="body1">Edit this role.</Typography>
                  ))}
              </Grid>

              <Grid container item direction={"column"} xs={8} spacing={2}>
                <Grid item>
                  <FormControl fullWidth error={!!errors.title}>
                    <FormLabel required htmlFor="role-title">
                      Name
                    </FormLabel>
                    <Controller
                      name="title"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="title"
                          error={!!errors.title}
                          helperText={errors.title?.message}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="description">
                      Description
                    </FormLabel>
                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="description"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container item direction={"column"} xs={12} spacing={2}>
                <Grid item>
                  <Typography variant="h6">Permissions</Typography>
                  <div className="mt-4 p-4 border rounded-lg">
                    {Object.entries(PERMISSIONS as Permissions).map(([groupName, permissions]) => (
                      <div key={groupName}>
                        {renderPermissionGroup(groupName, permissions)}
                      </div>
                    ))}
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6}></Grid>
          </Grid>
          <Grid item xs={12} />
          {id && id !== "create" && (
            <Grid item xs={12}>
              <Typography variant="h4" color="alert">
                Danger Zone
              </Typography>
              <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
                Delete
              </Button>
            </Grid>
          )}
          <LoadingOverlay loading={loading} />
          <Snackbar
            open={snackbar.open}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
          </Snackbar>
        </Grid>
      </form>
    </BasicLayout>
  );
};

export default Role;
