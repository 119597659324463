import React, { useEffect, useRef } from "react";
import {
  Grid,
  Dialog,
  Button,
  DialogTitle,
  IconButton,
  Icon,
  DialogContent,
  Typography,
} from "@mui/material";
import { INews } from "types/news";
import styled from "@emotion/styled";
import { generateHTML } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Image from '@tiptap/extension-image';

const Article = styled.div`
  > div > p {
    margin-bottom: 20px;
  }
  font-size: 15px;
  color: rgb(18, 18, 19);
`;
const Title = styled.h2`
  font-size: 36px;
  font-weight: 400;
  line-height: 50.4px;
`;
const Subtitle = styled.p`
  font-size: 18px;
  line-height: 30px;
`;
const Author = styled.span`
  color: rgb(123, 123, 123);
  font-size: 14px;
  font-weight: 300;
`;

const PreviewArticleModal = ({ news }: { news: Partial<INews> }) => {
  const [open, setOpen] = React.useState(false);

  //This will allow the HTML string to render as HTML without using dangerouslySetInnerHTML
  const htmlRef = useRef(null);
  const [loadedListener, setLoadedListener] = React.useState(null);

  //will fire when the modal is re-rendered
  useEffect(() => {
    if (htmlRef.current && news.content) {
      // Include the Image extension when generating HTML
      htmlRef.current.innerHTML = generateHTML(news.content, [
        StarterKit,
        Image.configure({
          inline: true,
          allowBase64: true,
        }),
      ]);
    }
  }, [htmlRef, news.content, loadedListener]);

  return (
    <>
      <Button
        size="large"
        disabled={!news?.content}
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
        style={{ width: 210 }}
      >
        Preview Content
      </Button>

      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        sx={{ padding: "20px", display: "flex", alignItems: "center", flexDirection: "column" }}
      >
        <DialogTitle color="rgb(18, 18, 19)">Article Preview</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpen(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon>close</Icon>
        </IconButton>
        <DialogContent
          dividers
          sx={{
            padding: "30px",
            width: "1320px",
          }}
        >
          {/* This div will force a rerender so the updates to htmlRef can render */}
          <div ref={(ref) => setLoadedListener(ref)} />
          <Grid
            item
            xs={12}
            padding={"20px"}
            fontSize={15}
            display={"flex"}
            justifyContent={"space-between"}
            direction={"row"}
            gap={2}
          >
            <Grid
              display={"flex"}
              direction={"column"}
              sx={{ backgroundColor: "#f9f9f9", padding: "20px;" }}
            >
              <Grid color="rgb(18, 18, 19)">
                <Title>{news.title}</Title>
              </Grid>
              <Grid color="rgb(18, 18, 19)">
                <Subtitle>{news.subtitle}</Subtitle>
              </Grid>
              <Grid color="rgb(18, 18, 19)">
                <Typography variant="body2">{news.summary}</Typography>
              </Grid>
              <Grid
                display={"flex"}
                justifyContent={"flex-end"}
                marginTop={"20px"}
                alignItems={"center"}
                gap={"20px"}
              >
                {news?.author?.defaultImage?.url && (
                  <img
                    src={news?.author?.defaultImage?.url}
                    alt={news?.author?.name}
                    style={{ width: 50, height: 50, borderRadius: "50%" }}
                  />
                )}
                <Author>{news?.author?.name || "No Author Listed"}</Author>
              </Grid>
            </Grid>

            <Grid>
              <img src={news?.defaultImage?.url} alt={news?.title} style={{ width: 540, height: 540 }} />
            </Grid>
          </Grid>

          <Grid item xs={12} padding={"20px"} fontSize={15}>
            {/* Article content will render on this div */}
            <Article ref={htmlRef} />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PreviewArticleModal;
