import React from 'react';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import PaginatedDataGrid from 'components/PaginatedDataGrid';
import ticketsService from 'services/tickets';

interface TicketListProps {
  defaultQuery?: { field: string; operator: string; value: string }[];
  defaultSortField?: string;
  defaultSortDirection?: 'asc' | 'desc';
}

const TicketList: React.FC<TicketListProps> = ({
  defaultQuery = [],
  defaultSortField = 'createdDate',
  defaultSortDirection = 'desc'
}) => {
  const columns: GridColDef[] = [
    { field: "ticketNumber", headerName: "Ticket Number", flex: 1 },
    { field: "ticketType", headerName: "Type", flex: 1 },
    {
      field: "event",
      headerName: "Event",
      flex: 1,
      valueGetter: (params) => params.row.event?.title
    },
    {
      field: "member",
      headerName: "Member",
      flex: 1,
      valueGetter: (params) => params.row.member?.name
    },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "paymentStatus", headerName: "Payment", flex: 1 },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      valueGetter: (params) => `${params.row.currency} ${params.row.price}`
    }
  ];

  return (
    <Box>
      <PaginatedDataGrid
        collectionName="tickets"
        defaultSortField={defaultSortField}
        defaultSortDirection={defaultSortDirection}
        defaultQuery={defaultQuery}
        columns={columns}
        service={ticketsService}
      />
    </Box>
  );
};

export default TicketList; 