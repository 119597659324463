import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  TextField,
} from "@mui/material";
import { IFolder } from "types/folders.d";
import foldersService from "services/folders";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LoadingOverlay from "components/LoadingOverlay";
import DeleteFolderDialog from "./DeleteFolderDialog";
import { useMediaGallery } from "./MediaGalleryContext";

const EditFolderDialog = () => {
  const [loading, setLoading] = React.useState(false);

  const [editFolderModalOpen, setEditFolderModalOpen] = useState(false);
  const [editFolderName, setEditFolderName] = useState("");

  const { viewFolder, setViewFolder, getFolders } = useMediaGallery();
  const handleSaveEditFolder = async () => {
    const updatedFolder: IFolder = {
      ...viewFolder,
      name: editFolderName,
    };
    try {
      setLoading(true);
      if (!updatedFolder.name) {
        throw new Error("Please fill in all fields");
      }
      await foldersService.update(viewFolder.id, updatedFolder);
    } catch (error: any) {
      console.error("Error saving event:", error);
    } finally {
      setLoading(false);
      setEditFolderModalOpen(false);
      setViewFolder(updatedFolder);
      getFolders();
    }
  };

  const handleEditFolderModalClose = () => {
    setEditFolderModalOpen(false);
    setEditFolderName("");
  };

  const handleEditFolderModalOpen = () => {
    setEditFolderModalOpen(true);
    setEditFolderName(viewFolder?.name || "");
  };

  return (
    <>
      <Button variant="text" onClick={handleEditFolderModalOpen}>
        <EditOutlinedIcon
          onClick={handleEditFolderModalOpen}
          fontSize={"small"}
          style={{ cursor: "pointer" }}
        />
        Edit Folder
      </Button>
      <Dialog
        open={editFolderModalOpen}
        onClose={handleEditFolderModalClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle style={{ display: "flex", justifyContent: "space-between" }}>
          Edit Folder
          <IconButton onClick={handleEditFolderModalClose}>
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: "20px" }}>
          <TextField
            label="Folder Name"
            value={editFolderName}
            onChange={(e) => setEditFolderName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <DeleteFolderDialog
            getFolders={getFolders}
            viewFolder={viewFolder}
            setViewFolder={setViewFolder}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveEditFolder}
            style={{ marginTop: "20px" }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingOverlay loading={loading} />
    </>
  );
};

export default EditFolderDialog;
