import { Grid, IconButton, Typography } from "@mui/material";
import BasicLayout from "components/Layouts/BasicLayout";
import CategoryForm from "components/CategoryForm/CategoryForm";
import { useNavigate, useParams } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { ICategory } from "types/categories";
import categoriesService from "services/categories";

const Category: React.FC = () => {

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<ICategory>();

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        setLoading(true);
        const _category: ICategory = await categoriesService.fetch(id);
        setCategory(_category);
      } catch (e) {
        console.error("Error fetching category:", e);
      } finally {
        setLoading(false);
      }
    };
    if (id && id !== "create") {
      fetchCategory();
    }
  }, [id]);


  return (
    <BasicLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">Category</Typography>
        </Grid>
        <Grid item xs={12}>
          {!id ||
            (id === "create" ? (
              <Typography variant="body1">
                Create a Category to categorize events.
              </Typography>
            ) : (
              <Typography variant="body1">Edit this Category.</Typography>
            ))}
        </Grid>
        <CategoryForm id={id} category={category} setCategory={setCategory} />
      </Grid>
    </BasicLayout >
  );
}

export default Category;