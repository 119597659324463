import React from "react";
import { Grid, Typography } from "@mui/material";
import { IFile } from "types/files.d";

import Pagination from "./Pagination";
import GalleryHeader from "./GalleryHeader";
import FolderList from "./FolderList";
import Gallery from "./Gallery";
import { MediaGalleryProvider } from "./MediaGalleryContext";

interface IMediaGalleryProps {
  selectionMethod?: 'single' | 'multiple';
  onChangeFileSelection?: (files: IFile[]) => void;
}

const MediaGallery: React.FC<IMediaGalleryProps> = (props) => {

  const { selectionMethod = 'single', onChangeFileSelection } = props;

  return (
    <MediaGalleryProvider selectionMethod={selectionMethod} onChangeFileSelection={onChangeFileSelection}>
      <div className="flex flex-col md:flex-row h-full gap-4">
        <div className="w-full md:w-1/4 h-full">
          <FolderList />
        </div>
        <div className="w-full md:w-3/4 flex flex-col gap-2">
          <div className="w-full">
            <GalleryHeader />
          </div>
          <div className="w-full flex-1 overflow-auto rounded-md shadow-md">
            <Gallery />
          </div>
          <div className="w-full">
            <Pagination />
          </div>
        </div>
      </div>
    </MediaGalleryProvider>
  );
};

export default MediaGallery;