import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Typography,
  TextField,
} from "@mui/material";
import { useNews } from "./NewsContext";
import AuthorSelect from "components/AuthorSelect";
import { IGenerateNewsResponse } from "types/news";
import newsService from "services/news";
import filesService from "services/files";
import { generateJSON } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useNavigate } from "react-router-dom";

const GenerateTab: React.FC = () => {
  const { news, setNews, loading, setLoading, setError } = useNews();
  const navigate = useNavigate();

  const handleGenerateClick = async () => {
    try {
      setLoading(true);
      if (!news.prompt) {
        alert("Please fill in the prompt");
        throw new Error("Please fill in all fields");
      }

      const response: IGenerateNewsResponse = await newsService.generate({
        prompt: news.prompt,
        author: news?.author,
      });

      response.content = generateJSON(response.content as unknown as string, [StarterKit]) as JSON;
      const generatedImage = await filesService.createFromUrl({
        url: response.image,
      });

      setNews({
        ...news,
        ...response,
        defaultImage: generatedImage,
        files: [generatedImage],
      });
      // Redirect to the article tab
      navigate(`/news/${news?.id || 'create'}/article`);
    } catch (error: any) {
      console.error("Error generating news:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={5}>
        <Typography variant="h5">Tips & Tricks</Typography>
        <Typography variant="body2" marginTop={1}>
          Our AI Journalists generate articles based on your prompt, each bringing their own expertise.
          For example, a business AI might focus on the economic impact, while a social AI would highlight
          community events. Provide a detailed prompt for the best results, and select the journalist
          that fits your article's focus.
        </Typography>
      </Grid>

      <Grid item xs={12} sm={7}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControl fullWidth>
              <FormLabel htmlFor="name">Prompt</FormLabel>
              <TextField
                id="prompt"
                multiline
                rows={9}
                value={news?.prompt}
                onChange={(e) => setNews({ ...news, prompt: e.target.value })}
              />
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl fullWidth>
              <FormLabel htmlFor="image">Author</FormLabel>
              <AuthorSelect
                value={news?.author}
                onChange={(author) => setNews({ ...news, author })}
              />
            </FormControl>
          </Grid>

          <Grid item display="flex" justifyContent="center">
            <Button
              size="large"
              disabled={loading || !news?.author || !news.prompt}
              variant="contained"
              color="primary"
              onClick={handleGenerateClick}
            >
              Generate Article and Image
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GenerateTab; 