import { useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Box, FormControl, TextField, Typography, Alert } from "@mui/material";
import { Link as MUILink } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import LoginSignUp from "components/Layouts/LoginSignUp";
import LoadingOverlay from "components/LoadingOverlay";
import authService from "services/auth";
import Logo from "assets/images/logos/logo_blue.png";
import darrenFaulknerImg from "assets/images/darren-faulkner-3FzYpZjE5Ec-unsplash.jpg";

function ForgotPassword(): JSX.Element {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await authService.forgotPassword(email);
      setSuccess(true);
    } catch (err: any) {
      setError(err.response?.data?.message || "Failed to process request");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container height="100vh">
      <Grid item xs={12} md={4} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <LoginSignUp>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: 30 }}>
              {loading ? (
                <LoadingOverlay />
              ) : (
                <img
                  key="title"
                  src={Logo}
                  width={300}
                  alt="CityView CMS Logo"
                />
              )}
            </Box>
            <Grid item xs={12} marginTop={2}>
              <Typography variant="h4" sx={{ textAlign: "center" }}>
                Forgot Password
              </Typography>
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                Remember your password?{" "}
                <MUILink component={Link} to="/login" color="blue">
                  Sign In
                </MUILink>
              </Typography>
            </Grid>

            {success ? (
              <MDBox pt={4} pb={3} px={12}>
                <Alert severity="success">
                  If an account exists with this email, you will receive password reset instructions.
                </Alert>
              </MDBox>
            ) : (
              <form onSubmit={handleSubmit}>
                <MDBox pt={4} pb={3} px={12}>
                  <Grid container justifyContent="center">
                    {error && (
                      <Grid item xs={12}>
                        <Alert severity="error">{error}</Alert>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Typography variant="body2">Email</Typography>
                      <FormControl fullWidth>
                        <TextField
                          variant="outlined"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} marginTop={2}>
                      <MDButton
                        type="submit"
                        variant="contained"
                        fullWidth
                        color="info"
                        disabled={loading || !email}
                      >
                        Reset Password
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </form>
            )}
          </Grid>
        </LoginSignUp>
      </Grid>

      <Grid
        item
        md={8}
        sx={{
          backgroundImage: `url(${darrenFaulknerImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </Grid>
  );
}

export default ForgotPassword; 