import { INewsletter } from "types/newsletters";
import AudienceFilter from "./AudienceFilter";
import { Grid, Typography, Button, FormControl, FormLabel, Select, MenuItem, TextField, Autocomplete } from "@mui/material";
import { IAudience } from "types/newsletters";
import { useState, useEffect, useCallback } from "react";
import membersService from "services/members";
import { v4 as uuidv4 } from 'uuid';
import MemberMultiSelect from "components/MemberMultiSelect/MemberMultiSelect";

interface AudienceFormProps {
  audience: IAudience;
  setAudience: (audience: IAudience) => void;
  setLoading: (loading: boolean) => void;
  setNewsletter: (newsletter: Partial<INewsletter>) => void;
  newsletter: Partial<INewsletter>;
}

const AudienceForm = ({ audience, setAudience, setLoading, setNewsletter, newsletter }: AudienceFormProps) => {

  const [members, setMembers] = useState<any>([]);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        setLoading(true);
        const _members = await membersService.fetchAll();
        setMembers(_members);
      } catch (e) {
        console.error("Error fetching members:", e);
      } finally {
        setLoading(false);
      }
    };
    fetchMembers();
  }, []);

  const onSave = () => {
    // Check for title if audience type is specific or filtered
    if ((audience.type === "specific" || audience.type === "filtered") && !audience.title) {
      alert("Audience title is required for specific or filtered audiences");
      return;
    }
    // Clear specific members if type is not "specific"
    if (audience.type !== "specific") {
      audience.specificMembers = undefined;
    }
    // Clear filters if type is not "filtered" 
    if (audience.type !== "filtered") {
      audience.filters = undefined;
      audience.filterOperator = undefined;
    }
    setAudience({
      ...audience,
      userGenerated: true
    });
    console.log(audience)
    setNewsletter({
      ...newsletter,
      audienceId: audience.id
    });
    // TODO: FIX FUNCTIONALITY TO SAVE AUDIENCE ID TO NEWSLETTER
    // TODO: add functionality to save audience 
  }

  const addFilter = useCallback(() => {
    const newFilter = {
      id: uuidv4(),
      entity: "" as const,
      condition: "" as const,
      field: "",
      value: ""
    };
    setAudience({
      ...audience,
      filters: [...(audience.filters || []), newFilter]
    });
  }, [audience, setAudience]);

  const deleteFilter = (filterId: string) => {
    setAudience({
      ...audience,
      filters: audience.filters?.filter(f => f.id !== filterId)
    });
  };

  const updateFilter = (updatedFilter: any) => {
    setAudience({
      ...audience,
      filters: audience.filters?.map(filter =>
        filter.id === updatedFilter.id ? updatedFilter : filter
      )
    });
  };

  const audienceContent = () => {
    if (audience?.type === "filtered") {
      return (
        <>
          <Grid container spacing={2} sx={{ marginBottom: 2, marginLeft: 0 }}>
            <Grid item>
              <FormControl>
                <FormLabel required htmlFor="newsletter-audience-filter-operator">Filter Operator</FormLabel>
                <Select
                  id="newsletter-audience-filter-operator"
                  value={audience?.filterOperator || "AND"}
                  onChange={(e) => setAudience({
                    ...audience,
                    filterOperator: e.target.value as 'AND' | 'OR'
                  })}
                >
                  <MenuItem value="AND">AND</MenuItem>
                  <MenuItem value="OR">OR</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Button
                sx={{ marginTop: 3.5 }}
                variant="contained"
                color="primary"
                onClick={addFilter}
              >
                Add Filter
              </Button>
            </Grid>
          </Grid>
          {audience?.filters?.map((filter) => (
            <Grid item xs={12} key={filter.id}>
              <AudienceFilter
                filter={audience?.filters?.find((f) => f.id === filter.id)}
                onFilterChange={updateFilter}
                onDelete={() => deleteFilter(filter.id)}
              />
            </Grid>
          ))}
        </>
      );
    }
    else if (audience?.type === "specific") {
      return (
        <Grid item xs={6} sx={{ width: '50%' }}>
          <FormLabel>Select Members Below</FormLabel>
          <MemberMultiSelect
            selectedMembers={audience?.specificMembers || []}
            setSelectedMembers={(members) => {
              setAudience({
                ...audience,
                specificMembers: members
              });
            }}
          />
        </Grid>
      )
    }
    return (
      <Grid item sx={{ marginTop: 2 }}>
        <Typography variant="body1" color="error">This newsletter will be sent to all members within your organization.</Typography>
        <Typography variant="body1" color="error">Total members: {members.total}</Typography>
      </Grid>
    )
  }

  return (
    <>
      <Grid container direction={"column"} spacing={2} sx={{ width: '100%' }}>
        <Grid item xs={6}>
          <FormLabel>Saved Audience's</FormLabel>
          <Autocomplete
            sx={{ width: '50%' }}
            options={[]}
            renderInput={(params) => <TextField {...params} label="Select Audience" />} // T0 DO: Add functionality to select past audiences.
          />
        </Grid>
        <FormControl sx={{ marginTop: 2, marginLeft: 2 }}>
          <FormLabel>Audience Type <span>*</span></FormLabel>
          <Select
            sx={{ width: '50%' }}
            id="newsletter-audience-type"
            value={audience?.type || "all"}
            onChange={(e) => setAudience({
              ...audience,
              type: e.target.value as 'all' | 'specific' | 'filtered'
            })}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="specific">Specific Members</MenuItem>
            <MenuItem value="filtered">Filtered</MenuItem>
          </Select>
        </FormControl>
        {audience?.type !== 'all' && <Grid item sx={{ marginBottom: 2 }}>
          <FormControl fullWidth>
            <FormLabel>Audience Name</FormLabel>
            <TextField
              sx={{ width: '50%' }}
              id="newsletter-audience-name"
              value={audience?.title}
              onChange={(e) => setAudience({ ...audience, title: e.target.value })}
            />
          </FormControl>
        </Grid>}
        {audienceContent()}
      </Grid>
      <Grid item sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" onClick={onSave}>Save</Button>
      </Grid>
    </>
  );
};

export default AudienceForm;
