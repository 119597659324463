import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import categoriesService from "services/categories";
import BasicLayout from "components/Layouts/BasicLayout";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import { Grid, Icon, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { Box } from "@mui/material";

const Categories: React.FC = () => {

  const columns: GridColDef[] = [
    {
      field: "defaultImage",
      headerName: "",
      width: 70,
      renderCell: (params: any) =>
        params.value?.url ? (
          <img src={params?.value?.url} alt="Event" style={{ width: "50px" }} />
        ) : (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{ fontSize: "50px !important", fontWeight: 100 }}
          >
            image
          </Icon>
        ),
      sortable: false,
    },
    { field: "title", headerName: "Title", flex: 1, minWidth: 200 },
  ];


  return (
    <BasicLayout>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Categories</Typography>
        </Grid>
        <Grid item xs={2} sm={4}></Grid>
        <Grid item xs={6} sm={4} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/categories/create">
            <Icon>add</Icon>
            Create Category
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <PaginatedDataGrid
              collectionName="categories"
              defaultSortField="title"
              defaultSortDirection="asc"
              columns={columns}
              service={categoriesService}
            />
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  )
}

export default Categories;
