import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { FilterComponentProps } from './types';

export const StatusCheckboxesFilter = ({ filterOption, value, onChange }: FilterComponentProps) => {
  const selectedValues = value || [];

  const handleStatusChange = (statusValue: string, checked: boolean) => {
    const newValues = checked
      ? [...selectedValues, statusValue]
      : selectedValues.filter(v => v !== statusValue);

    onChange(newValues.length > 0 ? {
      field: filterOption.name,
      value: newValues,
      operator: filterOption.operator || 'in'
    } : null);
  };

  return (
    <FormGroup row>
      {filterOption.options?.map((option) => (
        <FormControlLabel
          key={option.value}
          control={
            <Checkbox
              checked={selectedValues.includes(option.value)}
              onChange={(e) => handleStatusChange(option.value, e.target.checked)}
            />
          }
          label={option.label}
        />
      ))}
    </FormGroup>
  );
}; 