import React from 'react';
import { Grid, FormControl, FormLabel } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { IEvent } from 'types/events.d';

dayjs.extend(utc);

interface EventOccurrenceFormProps {
  event: IEvent;
  onChange: (updatedEvent: Partial<IEvent>) => void;
}

const EventOccurrenceForm: React.FC<EventOccurrenceFormProps> = ({ event, onChange }) => {
  console.log('event', event.startDate);

  const handleDateChange = (field: 'startDate' | 'endDate') => (date: dayjs.Dayjs | null) => {
    if (date) {
      const utcDate = date.utc().startOf('day');
      console.log('utcDate', utcDate.toISOString());
      onChange({ [field]: utcDate.toISOString() });
    }
  };

  const handleTimeChange = (field: 'startTime' | 'endTime') => (time: dayjs.Dayjs | null) => {
    if (time) {
      onChange({ [field]: time.format('HH:mm') });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <FormLabel htmlFor="start-date">Start Date</FormLabel>
          <DatePicker
            value={dayjs(event.startDate).utc()}
            onChange={handleDateChange('startDate')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        {event.eventType === 'multi' && <FormControl fullWidth>
          <FormLabel htmlFor="end-date">End Date</FormLabel>
          <DatePicker
            value={dayjs(event.endDate).utc()}
            onChange={handleDateChange('endDate')}
          />
        </FormControl>}
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <FormLabel htmlFor="start-time">Start Time</FormLabel>
          <TimePicker
            value={dayjs(`2000-01-01T${event.startTime}`)}
            onChange={handleTimeChange('startTime')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <FormLabel htmlFor="end-time">End Time</FormLabel>
          <TimePicker
            value={dayjs(`2000-01-01T${event.endTime}`)}
            onChange={handleTimeChange('endTime')}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default EventOccurrenceForm;
