import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  Grid,
  TextField,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  DialogTitle,
} from "@mui/material";

import TagMultiSelect from "components/TagMultiSelect";
import VideoSelect from "components/VideoSelect";
import LocationSelect from "components/LocationSelect";

import Image from "components/Images/Image";
import { IEvent, StatusEnum } from "types/events.d";

interface EventEditDialogProps {
  open?: boolean;
  event?: IEvent;
  onClose?: () => void;
  onSave?: (event: Partial<IEvent>) => void;
  headerText?: string;
}

const BLANK_EVENT: Partial<IEvent> = {
  title: undefined,
  description: undefined,
  link: undefined,
  location: undefined,
  price: undefined,
  status: undefined,
  tagIds: undefined,
  tags: undefined,
  boosted: undefined,
  updatedDate: undefined,
  updatedBy: undefined,
  featured: undefined,
  defaultImage: undefined,
  defaultVideo: undefined,
};

const EventEditDialog = (props: EventEditDialogProps) => {
  const { event: defaultEvent, open, onClose, onSave, headerText } = props;
  const [event, setEvent] = useState(defaultEvent || BLANK_EVENT);

  useEffect(() => {
    setEvent(defaultEvent || BLANK_EVENT);
  }, [defaultEvent]);
  console.log("event", event);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{headerText || "Edit Event"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Image
              src={event?.defaultImage?.url}
              onAccept={(image) => {
                setEvent((prevEvent: any) => ({
                  ...prevEvent,
                  defaultImage: {
                    url: image.url,
                    createdDate: new Date().toISOString()
                  }
                }));
              }}
              folderPath="Events"
            />
            <FormControl fullWidth>
              <FormLabel>Video</FormLabel>
              <VideoSelect
                value={event?.defaultVideo}
                onChange={(video) => setEvent((pe: any) => ({ ...pe, defaultVideo: video }))}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>Tags</FormLabel>
              <TagMultiSelect
                selectedTags={event?.tags}
                setSelectedTags={(tags) => setEvent((pe: any) => ({ ...pe, tags }))}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>Location</FormLabel>
              <LocationSelect
                value={event?.location}
                onChange={(location) => setEvent((pe: any) => ({ ...pe, location }))}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <FormLabel>Title</FormLabel>
              <TextField
                value={event?.title}
                id="title"
                variant="outlined"
                onChange={(e) => setEvent((pe: any) => ({ ...pe, title: e.target.value }))}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>Description</FormLabel>
              <TextField
                value={event?.description}
                id="description"
                variant="outlined"
                onChange={(e) => setEvent((pe: any) => ({ ...pe, title: e.target.value }))}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>Price</FormLabel>
              <TextField
                value={event?.price}
                id="price"
                variant="outlined"
                onChange={(e) => setEvent((pe: any) => ({ ...pe, price: e.target.value }))}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>Link</FormLabel>
              <TextField
                value={event?.link}
                id="link"
                variant="outlined"
                onChange={(e) => setEvent((pe: any) => ({ ...pe, link: e.target.value }))}
              />
            </FormControl>
            <FormGroup>
              <FormLabel id="status-group-label">Publishing Status</FormLabel>
              <RadioGroup
                row
                aria-labelledby="status-group-label"
                name="row-radio-buttons-group"
                value={event.status}
                onChange={(e) => setEvent({ ...event, status: e.target.value as StatusEnum })}
              >
                <FormControlLabel value={StatusEnum.Pending} control={<Radio />} label="Pending" />
                <FormControlLabel
                  value={StatusEnum.Published}
                  control={<Radio />}
                  label="Published"
                />
                <FormControlLabel value={undefined} control={<Radio />} label="Clear selection" />
              </RadioGroup>
            </FormGroup>
            <FormGroup>
              <FormLabel id="status-group-label">Monitization Options</FormLabel>
              <FormControlLabel
                htmlFor="featured"
                label={"Featured"}
                control={
                  <Checkbox
                    checked={event?.featured || false}
                    onChange={(e) => setEvent({ ...event, featured: e.target.checked })}
                  />
                }
              />
              <FormControlLabel
                htmlFor="boosted"
                label={"Boosted"}
                control={
                  <Checkbox
                    checked={event?.boosted || false}
                    onChange={(e) => setEvent({ ...event, boosted: e.target.checked })}
                  />
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" size="large" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" size="large" onClick={() => onSave(event)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventEditDialog;
