import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import citiesService from "services/cities";
import BasicLayout from "components/Layouts/BasicLayout";

const Cities: React.FC = () => {
  const columns: GridColDef[] = [
    {
      field: "defaultImage",
      headerName: "",
      width: 70,
      renderCell: (params: any) =>
        params.value ? (
          <img src={params?.value.url} alt="thumbnail URL" style={{ width: "50px" }} />
        ) : (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{ fontSize: "50px !important", fontWeight: 100 }}
          >
            image
          </Icon>
        ),
      sortable: false,
    },
    { field: "title", headerName: "Title", flex: 1, minWidth: 200 },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Cities</Typography>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/cities/create">
            <Icon>add</Icon>
            Add City
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <PaginatedDataGrid
              columns={columns}
              collectionName="cities"
              defaultSortField="title"
              defaultSortDirection="desc"
              service={citiesService}
              filterOptions={[
                {
                  label: "Title",
                  name: "title",
                  type: "text",
                  operator: "search",
                },
              ]}
            />
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default Cities;
