import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Box, FormControl, TextField, Typography, Alert, Button } from "@mui/material";
import LoadingOverlay from "components/LoadingOverlay";
import organizationInvitationsService from "services/organizationInvitations";
import { useAuth } from "context/Auth";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import LoginSignUp from "components/Layouts/LoginSignUp";
import Logo from "assets/images/logos/logo_blue.png";
import darrenFaulknerImg from "assets/images/darren-faulkner-3FzYpZjE5Ec-unsplash.jpg";


function AcceptInvitation(): JSX.Element {
  const navigate = useNavigate();
  const { invitationCode } = useParams<{ invitationCode: string }>();
  const { user, login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [invitationDetails, setInvitationDetails] = useState<any>(null);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });

  // Fetch invitation details
  useEffect(() => {
    const checkInvitation = async () => {
      try {
        setLoading(true);
        const response = await organizationInvitationsService.getDetails(invitationCode);
        setInvitationDetails(response);
        setFormData(prev => ({ ...prev, email: response.email }));

        // If user is already logged in, try to accept the invitation directly
        if (user) {
          await handleAcceptInvitation();
        }
      } catch (err: any) {
        setError(err.response?.data?.message || 'Invalid invitation');
      } finally {
        setLoading(false);
      }
    };

    if (invitationCode) {
      checkInvitation();
    }
  }, [invitationCode, user]);

  const handleAcceptInvitation = async (password?: string) => {
    try {
      setLoading(true);
      const response: any = await organizationInvitationsService.accept({
        invitationCode,
        password
      });
      // Login user with the received token
      debugger;
      if (response.user) {
        await login(response.user.email, password);
      }
      navigate('/');
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to accept invitation');
      throw err; // Re-throw to handle in calling functions
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      await login(formData.email, formData.password);
      await handleAcceptInvitation();
    } catch (err: any) {
      setError(err.response?.data?.message || 'Login failed');
    } finally {
      setLoading(false);
    }
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      await handleAcceptInvitation(formData.password);
    } catch (err: any) {
      setError(err.response?.data?.message || 'Registration failed');
    }
  };

  if (loading) {
    return <LoadingOverlay loading={loading} />;
  }

  if (error) {
    return (
      <Box p={3}>
        <Alert severity="error">{error}</Alert>
        <Button href="/login" sx={{ mt: 2 }}>
          Return to Login
        </Button>
      </Box>
    );
  }

  if (!invitationDetails) {
    return null;
  }

  if (user) {
    return <LoadingOverlay loading={true} />;
  }

  return (
    <Grid container height="100vh">
      <Grid item xs={12} md={4} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <LoginSignUp>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: 30 }}>
              <img
                src={Logo}
                width={300}
                alt="CityView CMS Logo"
              />
            </Box>
            <Grid item xs={12} marginTop={2}>
              <Typography variant="h4" sx={{ textAlign: "center" }}>
                Accept Invitation
              </Typography>
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                Join {invitationDetails.organization.name}
              </Typography>
            </Grid>

            <form onSubmit={handleRegister}>
              <MDBox pt={4} pb={3} px={12}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography variant="body2">Email</Typography>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        value={formData.email}
                        disabled
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body2">Password</Typography>
                    <FormControl fullWidth>
                      <TextField
                        type="password"
                        variant="outlined"
                        value={formData.password}
                        onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                        required
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body2">Confirm Password</Typography>
                    <FormControl fullWidth>
                      <TextField
                        type="password"
                        variant="outlined"
                        value={formData.confirmPassword}
                        onChange={(e) => setFormData(prev => ({ ...prev, confirmPassword: e.target.value }))}
                        required
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} marginTop={2}>
                    <MDButton
                      type="submit"
                      variant="contained"
                      fullWidth
                      color="info"
                    >
                      Accept Invitation
                    </MDButton>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ textAlign: "center" }}>
                      Already have an account?{' '}
                      <Link to="/login" color="blue">
                        Sign in
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </MDBox>
            </form>
          </Grid>
        </LoginSignUp>
      </Grid>

      <Grid
        item
        md={8}
        sx={{
          backgroundImage: `url(${darrenFaulknerImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </Grid>
  );
}

export default AcceptInvitation; 