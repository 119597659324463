import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import artistsService from "../../services/artists";
import { IArtist } from "types/artists.d";

interface ArtistSelectProps {
  value?: IArtist | string | number;
  onChange: (artist: IArtist) => void;
}

const ArtistSelect: React.FC<ArtistSelectProps> = ({ onChange, value }) => {
  const [artists, setArtists] = useState<IArtist[]>([]);

  useEffect(() => {
    const fetchArtists = async () => {
      const artists = await artistsService.fetchAll({ pageSize: 1000 });
      setArtists(artists.data);
    };
    fetchArtists();
  }, []);

  const handleSelectChange = (value: any) => {
    const selectedArtist = artists.find((artist) => artist.id === value);
    if (selectedArtist) {
      onChange(selectedArtist);
    }
  };

  const getCurrentValue = () => {
    if (!value) return null;
    if (typeof value === 'object') return value.id;
    return value;
  };

  return (
    <Autocomplete
      value={getCurrentValue()}
      onChange={(e, value) => handleSelectChange(value)}
      options={artists?.map((artist: IArtist) => artist.id)}
      getOptionLabel={(option) =>
        artists.find((artist: IArtist) => artist.id === option)?.name || String(option)
      }
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default ArtistSelect; 