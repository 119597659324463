import { Checkbox, FormControlLabel, FormLabel, FormGroup, Grid } from "@mui/material";
import PromotionSelect from "components/PromotionSelect";
import { useEvent } from "./EventContext";

const PromotionsTab: React.FC = () => {

  const { event, setEvent } = useEvent();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormGroup>
          <FormLabel id="status-group-label">Monitization Options</FormLabel>
          <FormControlLabel
            htmlFor="featured"
            label={"Featured"}
            control={
              <Checkbox
                checked={event?.featured || false}
                onChange={(e) => setEvent({ ...event, featured: e.target.checked })}
              />
            }
          />
          <FormControlLabel
            htmlFor="boosted"
            label={"Boosted"}
            control={
              <Checkbox
                checked={event?.boosted || false}
                onChange={(e) => setEvent({ ...event, boosted: e.target.checked })}
              />
            }
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <FormLabel id="promotion">Current Promotion</FormLabel>
        <PromotionSelect
          value={event?.promotion}
          onChange={(promotion: any) => setEvent({ ...event, promotion })}
        />
      </Grid>
    </Grid>
  );
};

export default PromotionsTab;