import React, { useState } from 'react';
import { IFile } from "types/files.d";
import {
  Grid,
  Card,
  CardMedia,
  CardActions,
  Checkbox,
  IconButton,
  Button,
  Box,
  Dialog,
  DialogContent,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MediaGallery from "components/MediaGallery";
import { MediaGalleryProvider } from "components/MediaGallery/MediaGalleryContext";
import Pagination from "components/MediaGallery/Pagination";
import GalleryHeader from "components/MediaGallery/GalleryHeader";
import FolderList from "components/MediaGallery/FolderList";
import Gallery from "components/MediaGallery/Gallery";

interface ImageGalleryProps {
  files: IFile[];
  onDelete?: (file: IFile) => void;
  onBulkDelete?: (files: IFile[]) => void;
  updateFiles?: (files: IFile[]) => void;
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ files, onDelete, onBulkDelete, updateFiles }) => {
  const [selectedFiles, setSelectedFiles] = useState<IFile[]>([]);
  const [viewingImage, setViewingImage] = useState<IFile | null>(null);
  const [openMediaGallery, setOpenMediaGallery] = useState(false);

  const handleImageClick = (image: IFile) => {
    setViewingImage(image);
  };

  const handleCheckboxChange = (image: IFile) => {
    const isSelected = selectedFiles.some(img => img.id === image.id);
    if (isSelected) {
      setSelectedFiles(prev => prev.filter(img => img.id !== image.id));
    } else {
      setSelectedFiles(prev => [...prev, image]);
    }
  };

  const handleDelete = (image: IFile) => {
    onDelete && onDelete(image);
    setSelectedFiles(prev => prev.filter(img => img.id !== image.id));
  };

  const handleBulkDelete = () => {
    onBulkDelete && onBulkDelete(selectedFiles);
    setSelectedFiles([]);
  };

  const handleFileSelected = (files: IFile[]) => {
    setSelectedFiles(files);
  };

  const handleAddSelected = () => {
    console.log('handleAddSelected selectedFiles', selectedFiles);
    updateFiles && updateFiles([...files, ...selectedFiles]);
    setSelectedFiles([]);
    setOpenMediaGallery(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        {onBulkDelete && files && files.length > 0 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleBulkDelete}
            disabled={selectedFiles.length === 0}
            startIcon={<DeleteIcon />}
          >
            Delete Selected ({selectedFiles.length})
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={() => setOpenMediaGallery(true)}>Add Media</Button>
      </Box>
      <Grid container spacing={2}>
        {files.map(image => (
          <Grid item xs={12} sm={3} md={4} lg={2} key={image.id}>
            <div className="bg-white rounded-lg shadow overflow-hidden">
              <div className="relative aspect-square">
                <img
                  src={image.url}
                  alt={`${image.id}`}
                  onClick={() => handleImageClick(image)}
                  className="absolute inset-0 w-full h-full object-cover cursor-pointer"
                />
              </div>
              <div className="flex justify-between items-center px-2 py-1">
                {onBulkDelete && (
                  <Checkbox
                    checked={selectedFiles.some(img => img.id === image.id)}
                    onChange={() => handleCheckboxChange(image)}
                  />
                )}
                {onDelete && (
                  <IconButton onClick={() => handleDelete(image)} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={viewingImage !== null}
        onClose={() => setViewingImage(null)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          {viewingImage && (
            <img
              src={viewingImage.url}
              alt={`Viewing ${viewingImage.id}`}
              style={{ width: '100%', height: 'auto' }}
            />
          )}
        </DialogContent>
      </Dialog>

      {openMediaGallery && (
        <div className="fixed inset-0 z-[1300] flex items-center justify-center">
          {/* Backdrop */}
          <div
            className="fixed inset-0 bg-black/50"
            onClick={() => setOpenMediaGallery(false)}
          />

          {/* Dialog */}
          <div className="relative w-full max-w-[76vw] bg-white rounded-lg shadow-xl p-4">
            {/* Content */}
            <div className="bg-gray-100 p-0 overflow-hidden rounded-lg p-4">
              {/* <div className="h-[80vh] overflow-auto">
                <MediaGallery selectionMethod="multiple" onChangeFileSelection={handleFileSelected} />
              </div> */}

              <div className="h-[80vh] overflow-auto">
                <MediaGalleryProvider onChangeFileSelection={handleFileSelected} selectionMethod="multiple">
                  <div className="flex flex-col md:flex-row h-full gap-4">
                    <div className="w-full md:w-1/4 h-full">
                      <FolderList />
                    </div>
                    <div className="w-full md:w-3/4 flex flex-col gap-2">
                      <div className="w-full">
                        <GalleryHeader />
                      </div>
                      <div className="w-full flex-1 overflow-auto rounded-md shadow-md">
                        <Gallery />
                      </div>
                      <div className="w-full">
                        <Pagination />
                      </div>
                    </div>
                  </div>
                </MediaGalleryProvider>
              </div>
            </div>

            {/* Actions */}
            <div className="flex justify-end gap-2 p-4 bg-white border-t">
              <Button
                onClick={() => setOpenMediaGallery(false)}
                variant="contained"
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleAddSelected}
                variant="contained"
                color="primary"
              >
                Add Selected
              </Button>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

export default ImageGallery;
