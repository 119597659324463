import React, { useState, useCallback } from 'react';
import {
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import { debounce } from 'lodash';
import communitiesService from '../../services/communities';
import { ICommunity } from 'types/communities';

interface CommunityMultiSelectProps {
  selectedCommunities: ICommunity[];
  setSelectedCommunities: (communities: ICommunity[]) => void;
}

const CommunityMultiSelect: React.FC<CommunityMultiSelectProps> = ({ selectedCommunities, setSelectedCommunities }) => {
  const [options, setOptions] = useState<ICommunity[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchCommunities = async (query) => {
    setLoading(true);
    try {
      const response = await communitiesService.fetchAll({ query, limit: 10 });
      setOptions(response.data);
    } catch (error) {
      console.error('Error fetching communities:', error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchCommunities = debounce((query) => fetchCommunities(query), 300);

  const handleInputChange = (_event: React.SyntheticEvent, value: string) => {
    debouncedFetchCommunities(value);
  };

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option) => option.name}
      value={selectedCommunities}
      onChange={(_event, newValue) => setSelectedCommunities(newValue)}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Communities"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};

export default CommunityMultiSelect;