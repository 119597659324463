import axios from 'axios';
import { API_HOST } from "../config";

import TokenHandler from "./base";

const searchPlaces = async (query: string) => {
  try {
    const response = await axios.get(`${API_HOST}/google/places/search`, {
      params: { query },
      headers: TokenHandler.getHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Error searching places:', error);
    throw error;
  }
};

const getPlaceDetails = async (placeId: string) => {
  try {
    const response = await axios.get(`${API_HOST}/google/places/details`, {
      params: { placeId },
      headers: TokenHandler.getHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Error getting place details:', error);
    throw error;
  }
};

const googleService = {
  searchPlaces,
  getPlaceDetails,
};

export default googleService;