import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  Box,
  FormHelperText,
} from '@mui/material';
import { IDataSource } from 'types/dataSource.d';
import dataSourcesService from 'services/dataSources';
import AITextField from 'components/AITextField';
import LoadingOverlay from 'components/LoadingOverlay';
import { Controller, FieldErrors, useForm } from 'react-hook-form';
import LocationSelect from 'components/LocationSelect';
import ArtistSelect from 'components/ArtistSelect';
import CommunitySelect from 'components/CommunitySelect';
import AuthorSelect from 'components/AuthorSelect';
import { ILocationData } from 'types/locations.d';
import { IAuthor } from 'types/authors';

type PartialDataSource = Partial<IDataSource> & {
  type: 'website' | 'instagram' | 'facebook' | 'twitter' | 'rss' | 'json';
  dataType: 'events' | 'news' | 'both';
  url: string;
  prompt?: string;
  scrapingInterval: 'hourly' | 'every-240-minutes' | 'twice-daily' | 'daily' | 'weekly' | 'monthly';
  active: boolean;
  parentType?: 'location' | 'community' | 'artist' | 'author' | 'none' | 'group' | undefined | null;
  parentId?: string;
};

interface DataSourceFormProps {
  dataSourceId?: string;
  parentId?: string;
  parentType?: 'location' | 'community' | 'artist' | 'author' | 'none' | 'group' | undefined | null;
  onComplete: () => void;
  onCancel: () => void;
}

const DataSourceForm: React.FC<DataSourceFormProps> = ({
  dataSourceId,
  parentId,
  parentType,
  onComplete,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [running, setRunning] = useState(false);

  const { control, register, handleSubmit, formState: { errors }, reset, watch } = useForm<PartialDataSource>({
    defaultValues: {
      type: 'website',
      dataType: 'events',
      url: '',
      prompt: '',
      scrapingInterval: 'hourly',
      active: true,
      parentType: parentType || null,
      parentId: parentId || null,
    },
  });

  useEffect(() => {
    const fetchDataSource = async () => {
      try {
        const dataSource = await dataSourcesService.fetch(dataSourceId);
        console.log(dataSource);
        reset(dataSource);
      } catch (e) {
        console.error("Error fetching data source:", e);
      }
    };
    if (dataSourceId !== "create") {
      fetchDataSource();
    }
  }, []);

  const onSubmit = async (formData: PartialDataSource, e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
    try {
      const { parentType, parentId } = formData;

      const data = {
        type: formData.type,
        dataType: formData.dataType,
        url: formData.url,
        prompt: formData.prompt,
        scrapingInterval: formData.scrapingInterval,
        ...(parentType && { parentType }),
        ...(parentId && { parentId }),
      }
      if (dataSourceId && dataSourceId !== "create") {
        await dataSourcesService.update(dataSourceId, data);
      } else {
        await dataSourcesService.create(data);
      }
      onComplete();
    } catch (error) {
      console.error("Error saving data source:", error);
      setError('Failed to save data source');
    }
  };

  const onError = (errors: FieldErrors<PartialDataSource>) => {
    console.log(errors);
  };

  const watchedType = watch('type');
  const watchedParentType = watch('parentType');

  const urlLabel = useMemo(() => {
    switch (watchedType) {
      case 'website': return 'URL';
      case 'instagram': return 'Instagram Handle';
      case 'facebook': return 'Facebook Handle';
      case 'twitter': return 'Twitter Username';
      case 'rss': return 'RSS URL';
      case 'json': return 'JSON URL';
    }
  }, [watchedType]);


  const handleRun = async () => {
    if (dataSourceId) {
      try {
        setRunning(true);
        const response = await dataSourcesService.run(dataSourceId);
        alert(JSON.stringify(response, null, 2));
      } catch (error) {
        console.error("Error running data source:", error);
      } finally {
        setRunning(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}>
      <>
        {running && <LoadingOverlay loading={running} />}
        <FormControl fullWidth margin="dense" error={!!errors.type}>
          <FormLabel>Type</FormLabel>
          <Controller
            name="type"
            control={control}
            rules={{ required: 'Type is required' }}
            render={({ field }) => (
              <Select {...field}>
                <MenuItem value="website">Website</MenuItem>
                <MenuItem value="instagram">Instagram</MenuItem>
                <MenuItem value="facebook">Facebook</MenuItem>
                <MenuItem value="twitter">Twitter</MenuItem>
                <MenuItem value="rss">RSS</MenuItem>
                <MenuItem value="json">JSON</MenuItem>
              </Select>
            )}
          />
          {errors.type && (
            <FormHelperText>{errors.type.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth margin="dense" error={!!errors.dataType}>
          <FormLabel>Data Type</FormLabel>
          <Controller
            name="dataType"
            control={control}
            rules={{ required: 'Data type is required' }}
            render={({ field }) => (
              <Select {...field}>
                <MenuItem value="events">Events</MenuItem>
                <MenuItem value="news">News</MenuItem>
                <MenuItem value="both">Both</MenuItem>
              </Select>
            )}
          />
          {errors.dataType && (
            <FormHelperText>{errors.dataType.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth margin="dense" error={!!errors.url}>
          <FormLabel>{urlLabel}</FormLabel>
          <TextField
            {...register('url', {
              required: 'URL is required',
            })}
            margin="dense"
            type="url"
            fullWidth
          />
          {errors.url && (
            <FormHelperText>{errors.url.message}</FormHelperText>
          )}
        </FormControl>

        {dataSourceId && dataSourceId !== "create" && (
          <FormControl fullWidth margin="dense" error={!!errors.prompt}>
            <Controller
              name="prompt"
              control={control}
              render={({ field }) => (
                <AITextField
                  {...field}
                  label="Prompt"
                  prompt="Generate a prompt for extracting content from this source"
                  promptMode="modal"
                  multiline
                  rows={4}
                />
              )}
            />
            {errors.prompt && (
              <FormHelperText>{errors.prompt.message}</FormHelperText>
            )}
          </FormControl>
        )}

        <FormControl fullWidth margin="dense" error={!!errors.scrapingInterval}>
          <FormLabel>Scraping Interval</FormLabel>
          <Controller
            name="scrapingInterval"
            control={control}
            rules={{ required: 'Scraping interval is required' }}
            render={({ field }) => (
              <Select {...field}>
                <MenuItem value="hourly">Hourly</MenuItem>
                <MenuItem value="every-240-minutes">Every 4 Hours</MenuItem>
                <MenuItem value="twice-daily">Twice Daily</MenuItem>
                <MenuItem value="daily">Daily</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
              </Select>
            )}
          />
          {errors.scrapingInterval && (
            <FormHelperText>{errors.scrapingInterval.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth margin="dense" error={!!errors.parentType}>
          <FormLabel>Parent Type</FormLabel>
          <Controller
            name="parentType"
            control={control}
            render={({ field }) => (
              <Select {...field}>
                <MenuItem value="">None</MenuItem>
                <MenuItem value="location">Location</MenuItem>
                <MenuItem value="community">Community</MenuItem>
                <MenuItem value="artist">Artist</MenuItem>
                <MenuItem value="author">Author</MenuItem>
              </Select>
            )}
          />
        </FormControl>

        {watchedParentType && watchedParentType !== 'none' && (
          <FormControl fullWidth margin="dense" error={!!errors.parentId}>
            <FormLabel>Parent</FormLabel>
            <Controller
              name="parentId"
              control={control}
              render={({ field }) => {
                switch (watchedParentType) {
                  case 'location':
                    return <LocationSelect
                      value={field.value}
                      onChange={(location: ILocationData) => field.onChange(location.id)}
                    />;
                  case 'artist':
                    return <ArtistSelect {...field} />;
                  case 'community':
                    return <CommunitySelect {...field} />;
                  case 'author':
                    return <AuthorSelect
                      value={field.value as unknown as IAuthor}
                      onChange={(author: IAuthor) => field.onChange(author.id)}
                    />;
                  default:
                    return null;
                }
              }}
            />
          </FormControl>
        )}
        <Box display="flex" gap={2}>
          <Box width="50%" display="flex" justifyContent="flex-start">
            <Button onClick={handleRun}>Run</Button>
          </Box>
          <Box width="50%" display="flex" justifyContent="flex-end">
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="submit">Save</Button>
          </Box>
        </Box>
      </>
    </form>
  );
};

export default DataSourceForm;
