import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";
import Image from "components/Images/Image";

import communitiesService from "services/communities";
import { ICommunity } from "types/communities.d";
import dataSourcesService from "services/dataSources";
import { IDataSource } from "types/dataSource.d";
import DataSourceList from 'components/DataSourceList/DataSourceList';
import { IFile } from "types/files.d";
import AITextField from "components/AITextField";

const Community: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [community, setCommunity] = useState<ICommunity | undefined>(undefined);
  const [dataSources, setDataSources] = useState<IDataSource[]>([]);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchCommunity = async () => {
      try {
        const community = await communitiesService.fetch(id);
        setCommunity(community);
      } catch (e) {
        console.error("Error fetching event:", e);
      }
    };
    if (id && id !== "create") {
      fetchCommunity();
    }
  }, [id]);

  const handleSave = async () => {
    try {
      setLoading(true);
      const newCommunity: ICommunity = {
        ...community,
        updatedBy: user?.uid,
      };

      if (!newCommunity.name || !newCommunity.website) {
        throw new Error("Please fill in all fields");
      }
      if (id === "create") {
        newCommunity.createdBy = user;
        await communitiesService.create(newCommunity);
      } else {
        newCommunity.updatedBy = user;
        const updatedCommunity = await communitiesService.update(newCommunity.id, newCommunity);
        setCommunity(updatedCommunity);
      }
      navigate("/communities");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await communitiesService.remove(id);
      navigate("/communities");
    } catch (error) {
      console.error("Error deleting listing:", error);
    }
  };

  const handleDataSourceChange = async () => {
    try {
      const community = await communitiesService.fetch(id);
      setCommunity(community);
      const response = await dataSourcesService.fetchAll({
        parentId: community?.id,
        parentType: "community",
      });
      setDataSources(response.data);
    } catch (e) {
      console.error("Error fetching updated data:", e);
    }
  };

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">Community</Typography>
        </Grid>
        <Grid item xs={12} >
          <Grid container spacing={2}>
            <Grid item xs={12} md={5} marginTop={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '600px ' }}>
              <Image
                onAccept={(image: IFile) => setCommunity({ ...community, defaultImage: image })}
                src={community?.defaultImage?.url}
                alt={community?.name}
                bucketPath="cityview-cms/communities"
                folderPath="Communities Media"
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <AITextField
                      label="Name"
                      prompt="From the following details, create a name for an community. The name should be descriptive and engaging and be less than 6 words."
                      promptMode="modal"
                      value={community?.name || ""}
                      onChange={(val: any) => setCommunity({ ...community, name: val })}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <AITextField
                      label="Description"
                      prompt="From the following details, create a description for an community. The description should be descriptive and engaging and be at least 3 sentences long."
                      promptMode="modal"
                      value={community?.description || ""}
                      onChange={(val: any) => setCommunity({ ...community, description: val })}
                      required
                      multiline
                      rows={4}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="link">Website</FormLabel>
                    <TextField
                      id="link"
                      value={community?.website || ""}
                      onChange={(e) =>
                        setCommunity({ ...community, website: e.target.value })
                      }
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="instagram">Instagram</FormLabel>
                    <TextField
                      id="instagram"
                      value={community?.instagram || ""}
                      onChange={(e) =>
                        setCommunity({ ...community, instagram: e.target.value })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="facebook">Facebook</FormLabel>
                    <TextField
                      id="facebook"
                      value={community?.facebook || ""}
                      onChange={(e) =>
                        setCommunity({ ...community, facebook: e.target.value })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="twitter">Twitter</FormLabel>
                    <TextField
                      id="twitter"
                      value={community?.twitter || ""}
                      onChange={(e) =>
                        setCommunity({ ...community, twitter: e.target.value })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                  <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2}>
            {id && id !== "create" && (
              <Grid item xs={12}>
                <Typography variant="h4" color="alert">
                  Danger Zone
                </Typography>
                <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} marginBottom={5}>
          <Typography variant="h4">Data Sources</Typography>
          <DataSourceList
            dataSources={dataSources}
            parentId={community?.id || ''}
            parentType="community"
            onDataSourceChange={handleDataSourceChange}
          />
        </Grid>
        <LoadingOverlay loading={loading} />
      </Grid>
    </BasicLayout >
  );
};

export default Community;
