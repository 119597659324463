import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
} from "@mui/material";

import { TrashIcon } from "@heroicons/react/20/solid";

import { IFolder } from "types/folders.d";

import foldersService from "services/folders";

import LoadingOverlay from "components/LoadingOverlay";

interface Props {
  getFolders: () => void;
  viewFolder: IFolder | null;
  setViewFolder: (folder: IFolder | null) => void;
}
const DeleteFolderDialog: React.FC<Props> = ({ getFolders, setViewFolder, viewFolder }) => {
  const [loading, setLoading] = React.useState(false);
  const [deleteFolderConfirmModalOpen, setDeleteFolderConfirmModalOpen] = useState(false);

  const handleDeleteFolder = async () => {
    try {
      setLoading(true);
      await foldersService.remove(viewFolder.id);
    } catch (error: any) {
      console.error("Error deleting folder:", error);
    } finally {
      setLoading(false);
      setDeleteFolderConfirmModalOpen(false);
      setViewFolder(null);
      getFolders();
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="error"
        onClick={() => setDeleteFolderConfirmModalOpen(true)}
        style={{ marginTop: "20px", gap: "10px" }}
      >
        <TrashIcon width={"20px"} /> Delete This Folder
      </Button>

      <Dialog
        open={deleteFolderConfirmModalOpen}
        onClose={() => setDeleteFolderConfirmModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle style={{ display: "flex", justifyContent: "space-between" }}>
          Are you sure you want to delete this folder?
          <IconButton onClick={() => setDeleteFolderConfirmModalOpen(false)}>
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: "20px" }}>
          <p>This will not delete any of the folder's files. This action cannot be undone.</p>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button
            variant="contained"
            color="info"
            onClick={() => setDeleteFolderConfirmModalOpen(false)}
            style={{ marginTop: "20px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteFolder}
            style={{ marginTop: "20px", gap: "10px" }}
          >
            <TrashIcon width={"20px"} /> Yes, Delete This Folder
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingOverlay loading={loading} />
    </>
  );
};

export default DeleteFolderDialog;
