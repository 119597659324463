import React from "react";
import {
  FormLabel,
  FormControl,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useNews } from "./NewsContext";
import { StatusEnum } from "types/events.d";
import PreviewArticleModal from "./PreviewArticleModal";
import TipTap from "components/TipTap";
import debounce from "lodash/debounce";
import Image from "components/Images/Image";
import { IFile } from "types/files.d";
import TagMultiSelect from "components/TagMultiSelect/TagMultiSelect";
import AuthorSelect from "components/AuthorSelect/AuthorSelect";

const ArticleTab: React.FC = () => {
  const { news, setNews } = useNews();

  const handleOnAccept = (image: IFile) => {
    setNews((prevNews) => ({
      ...prevNews,
      defaultImage: image
    }));
  };
  console.log('news', news);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="NewsArticle__header">
        <Typography variant="h4">Article</Typography>
      </Grid>
      <Grid item xs={12} sm={5} padding={2} className="NewsArticle__image">
        <Image
          onAccept={handleOnAccept}
          src={news?.defaultImage?.url}
          alt={news?.title}
          bucketPath="cityview-cms/news"
          folderPath="News Media"
          context={news?.article || news?.prompt}
        />
      </Grid>
      <Grid item xs={12} sm={7} className="NewsArticle__image">
        <Grid container spacing={2}>
          <Grid item xs={12} className="NewsArticle__header">
            <FormControl fullWidth>
              <Typography variant="h6">Title</Typography>
              <TextField
                id="title"
                value={news?.title || ""}
                onChange={(e) => setNews((prev) => ({ ...prev, title: e.target.value }))}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} className="NewsArticle__header">
            <FormControl fullWidth>
              <Typography variant="h6">Subtitle</Typography>
              <TextField
                id="subtitle"
                value={news?.subtitle || ""}
                onChange={(e) => setNews((prev) => ({ ...prev, subtitle: e.target.value }))}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} className="NewsArticle__summary">
            <FormControl fullWidth>
              <Typography variant="h6">Summary</Typography>
              <TextField
                id="summary"
                multiline
                rows={4}
                value={news?.summary || ""}
                onChange={(e) => setNews((prev) => ({ ...prev, summary: e.target.value }))}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} className="NewsArticle__author">
            <Typography variant="h6">Author</Typography>
            <AuthorSelect
              value={news?.author}
              onChange={(author) => setNews((prev) => ({ ...prev, author }))}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel htmlFor="tags">Tags</FormLabel>
              <TagMultiSelect
                selectedTags={news?.tags || []}
                setSelectedTags={(tags) => {
                  setNews((prev) => ({
                    ...prev,
                    tags
                  }));
                }}
                type={["news", "tag"]}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={news?.featured || false}
                  onChange={(e) => setNews((prev) => ({ ...prev, featured: e.target.checked }))}
                />
              }
              label="Featured"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={news?.trending || false}
                  onChange={(e) => setNews((prev) => ({ ...prev, trending: e.target.checked }))}
                />
              }
              label="Trending"
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormLabel id="status-group-label">Publishing Status</FormLabel>
              <RadioGroup
                row
                aria-labelledby="status-group-label"
                name="row-radio-buttons-group"
                value={news?.status}
                onChange={(e) => setNews((prev) => ({ ...prev, status: e.target.value as StatusEnum }))}
              >
                <FormControlLabel value={StatusEnum.Pending} control={<Radio />} label="Pending" />
                <FormControlLabel
                  value={StatusEnum.Published}
                  control={<Radio />}
                  label="Published"
                />
              </RadioGroup>
            </FormGroup>
          </Grid>
          {news?.defaultVideo?.url && (
            <Grid item xs={12}>
              <Typography variant="h6">Video</Typography>
              <video controls width="600">
                <source src={news?.defaultVideo?.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12} className="NewsArticle__content">
          <FormControl fullWidth>
            <Grid
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              marginBottom={2}
              alignItems={"flex-end"}
            >
              <Typography variant="h6">Content (HTML)</Typography>
            </Grid>
            <Grid item xs={12}>
              <TipTap
                content={news.content}
                onChange={debounce((value: JSON) => setNews((prev) => ({ ...prev, content: value })), 300)}
                bucketPath="cityview-cms/news"
              />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", paddingTop: 2 }}>
              {news.content && <PreviewArticleModal news={news} />}
            </Grid>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ArticleTab; 