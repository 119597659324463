import { Button, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import stripeService from "services/stripe";
import { useEffect, useState } from "react";
import InvoiceModal from "components/InvoiceModal/InvoiceModal";
import { useAuth } from "context/Auth";

const Invoices: React.FC = () => {

  const [invoices, setInvoices] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<string | null>(null);

  const { user } = useAuth();

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const invoices = await stripeService.fetchAllInvoices();
        setInvoices(invoices);
        console.log("invoices", invoices);
      } catch (error) {
        console.error("Error fetching invoices:", error);
      }
    };

    fetchInvoices();
  }, []);

  const onViewClick = (params: any) => {
    console.log(params);
    setSelectedInvoice(params.id);
    setOpen(true);
    console.log(params);
  }

  const onDeleteClick = (params: any) => {
    console.log(params);
  }

  const columns = [
    { field: 'number', headerName: 'Invoice Number', flex: 1.5 },
    { field: 'amount_paid', headerName: 'Amount Paid', flex: 1.5, },
    { field: 'payment_date', headerName: 'Payment Date', flex: 2 },
    { field: 'period_start', headerName: 'Period Start', flex: 2 },
    { field: 'period_end', headerName: 'Period End', flex: 2 },
    { field: 'status', headerName: 'Status', flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 2,
      renderCell: (params: any) => (
        <>
          <Button
            style={{ marginRight: 10 }}
            variant="contained"
            color="primary"
            onClick={() => onViewClick(params)}
          >
            View
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onDeleteClick(params)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  const rows = invoices.map((invoice) => ({
    invoiceId: invoice.id,
    number: invoice.number,
    amount_paid: `$${(invoice.amount_paid / 100).toFixed(2)}`,
    payment_date: new Date(invoice.payment_date).toLocaleDateString('en-US'),
    period_start: new Date(invoice.period_start).toLocaleDateString('en-US'),
    period_end: new Date(invoice.period_end).toLocaleDateString('en-US'),
    status: invoice.status,
  }));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Invoices
        </Typography>
        <DataGrid
          rows={rows}
          columns={columns}
          autoHeight
          getRowId={(row) => row.invoiceId}
        />
      </Grid>
      <InvoiceModal id={selectedInvoice} open={open} onClose={() => { setOpen(false) }} organizationName={user?.currentOrganization?.name} />
    </Grid>
  );
};

export default Invoices; 