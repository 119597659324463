import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";
import dataSourcesService from "services/dataSources";
import { IDataSource } from "types/dataSource.d";
import DataSourceForm from "components/DataSourceForm/DataSourceForm";
import PaginatedDataGrid from "components/PaginatedDataGrid/PaginatedDataGrid";
import logsService from "services/logs";
import { ILog } from "types/logs.d";
import { GridCellParams } from "@mui/x-data-grid";

const DataSource: React.FC = () => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleDelete = async () => {
    try {
      setLoading(true);
      await dataSourcesService.remove(id);
      navigate("/data-sources");
      setLoading(false);
    } catch (error) {
      console.error("Error deleting data source:", error);
    }
  };

  // Add this constant for the label style
  const labelStyle = {
    color: 'rgba(0, 0, 0, 0.87)', // Or any color you prefer
    '&.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.87)', // Same color as non-focused state
    },
  };

  const onComplete = async () => {
    console.log("onComplete");
    navigate("/data-sources");
  };

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">Data Source</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <DataSourceForm dataSourceId={id} onComplete={onComplete} onCancel={() => navigate(-1)} />
        </Grid>
        <LoadingOverlay loading={loading} />
      </Grid>
      <Grid container spacing={4}>
        {id && id !== 'create' && <Grid item xs={12}>
          <PaginatedDataGrid
            collectionName="logs"
            service={logsService}
            columns={[
              {
                field: 'dataSource', headerName: 'Data Source', flex: 1, renderCell: (params: GridCellParams) => {
                  return <Link to={`/data-sources/${params?.row?.dataSource?._id}`}>{params.row.dataSource?.parentId || params.row.dataSource?.parentId?.title || params.row.dataSource?._id}</Link>
                }
              },
              { field: 'level', headerName: 'Level', flex: 1 },
              { field: 'message', headerName: 'Message', flex: 1 },
              { field: 'timestamp', headerName: 'Timestamp', flex: 1 },
              {
                field: 'metadata', headerName: 'Metadata', flex: 1, renderCell: (params: GridCellParams) => {
                  return <pre>{JSON.stringify(params.row.metadata, null, 2)}</pre>
                }
              },
            ]}
            defaultQuery={[
              {
                field: 'dataSource',
                operator: 'eq',
                value: id,
              }
            ]}
          />
        </Grid>}
        <Grid item xs={12}>
          {id && id !== "create" && (
            <Grid item xs={12}>
              <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
                Delete
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default DataSource;
