import React, { useEffect, JSXElementConstructor, Key, ReactElement } from "react";
import "./index.css";
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React TS routes
import routes from "routes";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from "context";
import ProtectedRoute from "./components/ProtectedRoute";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { GlobalContextProvider } from "context/GlobalContext";
import { GlobalFilterContextProvider } from "components/PaginatedDataGrid/GlobalFilterContext";
import { LocationProvider } from "pages/Location/LocationContext";
import LocationRouter from "pages/Location/LocationRouter";

export default function App() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const pathname = useLocation().pathname;

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const unprotectedRoutes = ["/login", "/sign-up", "/forgot-password", "/reset-password", "/accept-invitation/:invitationCode"];
  const getRoutes = (allRoutes: any[]): any =>
    allRoutes.map(
      (route: {
        collapse: any;
        route: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
        type: string;
      }) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          if (unprotectedRoutes.includes(route.route)) {
            return <Route path={route.route} element={route.component} key={route.key} />;
          }
          return (
            <Route
              path={route.route}
              key={route.key}
              element={<ProtectedRoute>{route.component}</ProtectedRoute>}
            />
          );
        }

        return null;
      }
    );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <GlobalContextProvider>
          <GlobalFilterContextProvider>
            <CssBaseline />
            <div className="antialiased">
              <Routes>
                {getRoutes(routes)}
                <Route path="/locations/:id/*" element={
                  <LocationProvider>
                    <LocationRouter />
                  </LocationProvider>
                } />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </div>
          </GlobalFilterContextProvider>
        </GlobalContextProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
