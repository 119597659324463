import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { IFilterOptionOption, FilterOption } from '../PaginationFilter';
import { FilterComponentProps } from './types';

export const AutocompleteFilter: React.FC<FilterComponentProps> = ({ filterOption, value, onChange }) => {
  const [options, setOptions] = useState<IFilterOptionOption[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchOptions = async (search: string = '') => {
    if (!filterOption.service) return;

    setLoading(true);
    try {
      const fieldName = filterOption.fieldName || 'title';
      const query = search ? [{ field: fieldName, operator: 'search', value: search }] : [];
      const { data } = await filterOption.service.fetchAll({
        query,
        page: 0,
        pageSize: 10 // Limit results for better performance
      });

      setOptions(data.map((item: any) => ({
        value: item.id || item._id,
        label: item[fieldName],
        originalItem: item // Store the full item for reference if needed
      })));
    } catch (error) {
      console.error('Error fetching options:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <Autocomplete
      id={filterOption.name}
      fullWidth
      options={options}
      loading={loading}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue.length > 2) {
          fetchOptions(newInputValue);
        } else if (newInputValue.length === 0) {
          fetchOptions();
        }
      }}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue ? {
          field: filterOption.name,
          value: newValue.value,
          operator: filterOption.operator || 'eq'
        } : null);
      }}
      getOptionLabel={(option) => option.label || ''}
      isOptionEqualToValue={(option, value) =>
        option.value === value?.value ||
        (value?.value && value.value === option.value)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={filterOption.placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};