import { Box, Grid, Typography } from "@mui/material";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import { useEvent } from "./EventContext";
import membersService from "services/members";
import rsvpsService from "services/rsvps";
import { GridColDef } from "@mui/x-data-grid";

const RSVPsTab: React.FC = () => {


  const { event } = useEvent();

  const columns: GridColDef[] = [
    {
      field: "member.name",
      headerName: "Member",
      flex: 1,
      valueGetter: (params) => params.row.member?.name,
    },
    {
      field: "member.email",
      headerName: "Email",
      flex: 1,
      valueGetter: (params) => params.row.member?.email,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      valueGetter: (params) => {
        const status = params.row.status;
        if (!status) return "Pending";

        // Format the status text
        switch (status) {
          case "attending":
            return "Attending";
          case "not_attending":
            return "Not Attending";
          case "maybe":
            return "Maybe";
          default:
            return status.charAt(0).toUpperCase() + status.slice(1); // Capitalize any other statuses
        }
      },
    },
    {
      field: "guests",
      headerName: "Attendees",
      flex: 1,
      valueGetter: (params) => params.row.guests,
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">RSVPs</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <PaginatedDataGrid
            collectionName="rsvps"
            defaultSortField="createdDate"
            defaultSortDirection="desc"
            columns={columns}
            service={rsvpsService}
            getRowId={(row) => row._id || row.id}
            defaultQuery={[
              {
                field: "event._id",
                operator: "eq",
                value: event._id,
              },
            ]}
            filterOptions={[
              {
                label: "Member",
                name: "member._id",
                type: "autocomplete",
                fieldName: "name",
                service: membersService,
              },
            ]}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default RSVPsTab;